import React, { useState } from "react";
import { makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import avatarImage from "../../assets/images/admin-avatar.svg";
import '../teacher/teacher.scss';
import Tooltip from '@material-ui/core/Tooltip';
import { Roles } from "../commonLayouts/header";
import TeacherLicenseModal from "../modals/teacher/teacherLicenseModal";

const useMenuStyles = makeStyles({
    paper: {
      border: "1px solid #d3d4d5"
    }
  });

const useMenuItemStyles = makeStyles(theme => ({
    root: {
        "&:focus": {
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
            color: theme.palette.common.white
          }
        }
      }
    }))

interface UserMenuProps {
    role: Roles,
    logoutAction: () => void
}

export default function UserMenu(props: UserMenuProps) {
  const menuClasses = useMenuStyles();
  const menuItemClasses = useMenuItemStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [licenseModalOpen, setLicenseModalOpen] = useState(false)

  const handleLicenseClose = () => {
    setLicenseModalOpen(false)
  }

  return (
      <>
        <div>
        <Tooltip title="Avatar" aria-label="User Icon">
        <Button onClick={(e) => {setAnchorEl(e.currentTarget)}} className="logout-button" aria-label="User Menu" aria-controls="customized-menu" aria-haspopup>
        <img src={avatarImage} alt={`${props.role} Icon Dropdown`} className="avatarImage" /> <ArrowDropDownIcon />
        </Button>
        </Tooltip>
        <Menu
            id="customized-menu"
            classes={menuClasses}
            anchorEl={anchorEl}
            keepMounted
            open={!!anchorEl}
            onClose={() => {setAnchorEl(null)}}
            disableScrollLock={ true }
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
            }}
            transformOrigin={{
            vertical: "top",
            horizontal: "center"
            }}
        >     
            <MenuItem onClick={props.logoutAction} classes={menuItemClasses}>
                <ExitToAppIcon fontSize="small" /> &nbsp;&nbsp;
                <ListItemText primary="Logout" />
            </MenuItem>
            {props.role === "Teacher" && (
            <MenuItem onClick={() => setLicenseModalOpen(true)} classes={menuItemClasses}>
                <MenuBookOutlinedIcon fontSize="small" /> &nbsp;&nbsp;
                <ListItemText primary="Licenses" />
            </MenuItem>
            )}
        </Menu>
        </div>
        <TeacherLicenseModal 
            modalOpen={licenseModalOpen}
            handleClose={handleLicenseClose}
        />
    </>
  );
}
