import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCustomerContextForAccount, resetPasswordByLogin, resetStudentPasswordByClassIdAndLogin } from "../../clientLibrary/account/account";
import { CustomerDTO } from "../../clientLibrary/account/accountInterfaces";
import { setBackdrop, updateSnackbarError, updateSnackbarSuccess } from "./utilitySlice";

export interface AccountState {
    customer: CustomerDTO | null
}

interface ResetStudentPasswordPayload {
    classId: number,
    login: string
}

const initialState: AccountState = {
    customer: null
}

export const getCustomerContext = createAsyncThunk(
    'account/getCustomerContext',
    async (_, thunkAPI: any) => {
        try {
            const customerContextResponseData = await getCustomerContextForAccount();
            return customerContextResponseData;
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to retrieve customer data.'));
            throw error;
        }
    }
);

export const resetPasswordDistrict = createAsyncThunk(
    'account/resetPasswordDistrict',
    async (login: string, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await resetPasswordByLogin(login);
            thunkAPI.dispatch(updateSnackbarSuccess(`Temporary password reset to "${login}". Be sure the user logs in to update their password.`));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to reset password.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const resetStudentPassword = createAsyncThunk(
    'account/resetStudentPassword',
    async (payload: ResetStudentPasswordPayload, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await resetStudentPasswordByClassIdAndLogin(payload.classId, payload.login);
            thunkAPI.dispatch(updateSnackbarSuccess(`Temporary password reset to "${payload.login}". Be sure the student logs in to update their password.`));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to reset password.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(getCustomerContext.fulfilled, (state, action) => {
            state.customer = action.payload;
        })
        .addCase(getCustomerContext.rejected, (state) => {
            state.customer = null;
        });
    }
});

export default accountSlice.reducer;

