import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Checkbox,
  FormControl,
  Grid,
  Icon,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  Paper
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import AddIcon from '@material-ui/icons/Add';
import SearchBox from '../../helperComponents/searchBox';
import PerPage from '../../helperComponents/perPageDropdown';
import DialogBox from '../../helperComponents/dialogBox';
import "../../district/district.scss";
import BulkAddClassModal, { csvArrayToBulkClassData } from '../../modals/district/bulkAddClassModal';
import CreateClassModal from '../../modals/shared/createClassModal';
import UpdateClassModal from '../../modals/shared/updateClassInfoModal';
import "../../district/district.scss";
import { useResponsiveTableStyles } from '../../../common/Styles';
import { getFullName, getLicenseNameOnlyFromLicenseTypeDescription, paginationAfterDelete } from '../../../common/GeneralFunction';
import {
  setClassPagination,
  createNewClass,
  getAllClasses,
  searchClasses,
  updateClassDistrict,
  deleteClass,
  addBulkClasses,
  deleteBulkClasses,
} from "../../../reduxModule/slices/classSlice";
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ClassesResponse, CreateClassPayload } from '../../../clientLibrary/class/classInterfaces';
import { ParseResult } from 'papaparse';
import { TableHeader } from '../../../clientLibrary/GeneralInterfaces';
import { UpdateClassInfoForComponent, updateClassInfoObj } from '../../teacher/classes';
import { BulkActions, ColumnType, UserType } from '../../../clientLibrary/Enums';
import { ELS_PRIMARY_BLUE_COLOR } from '../licenses/utilizationReport';
import EllipsisMenu, { MenuOptions } from "../../helperComponents/ellipsisMenu";

export const BUTTON_CONTAINER_HEIGHT = '60.5px';

const useStyles = makeStyles(theme => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    padding: '2px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    width: '100%'
  },
  buttonContainer: { 
    width: '500px',
    gap: '10px',
    flexWrap: 'nowrap'
  }
}));

const tableHead: TableHeader<ClassesResponse>[] = [
  { name: '', align: 'left', type: ColumnType.Checkbox, sort: '' },
  { name: 'Class', align: 'left', type: ColumnType.Text, sort: 'name' },
  { name: 'School', align: 'left', type: ColumnType.Text, sort: 'schoolName' },
  { name: 'Teacher', align: 'left', type: ColumnType.Text, sort: 'name' },
  { name: 'Pin', align: 'left', type: ColumnType.Text, sort: 'pin' },
  { name: 'Start Date', align: 'left', type: ColumnType.Text, sort: 'startDate' },
  { name: 'End Date', align: 'left', type: ColumnType.Text, sort: 'endDate' },
  { name: 'Active Students', align: 'center', type: ColumnType.Text, sort: '' },
  { name: 'Actions', align: 'center', type: ColumnType.Button, sort: '' }
];

interface SelectedClass {
  id: number,
  name: string
}

export default function DistrictClasses() {
  const dispatch = useAppDispatch();
  const responsiveClasses = useResponsiveTableStyles();
  const classes = useStyles();

  const schools = useAppSelector(state => state.school.schools);

  const searchedClasses = useAppSelector(state => state.class.searchedClasses);
  const searchedClassCount = useAppSelector(state => state.class.searchedClassCount);
  const classPagination = useAppSelector(state => state.class.pagination);
  const classPaginationPage = useAppSelector(state => state.class.pagination.page);
  const classPaginationPerPage = useAppSelector(state => state.class.pagination.per_page);
  const classPaginationSortReverse = useAppSelector(state => state.class.pagination.sort_reverse);
  const licenseConsumers = useAppSelector(state => state.license.licenseConsumers);

  const [bulkAction, setBulkAction] = useState(BulkActions.Empty);
  const [selectedClasses, setSelectedClasses] = useState<SelectedClass[]>([]);
  const [updateClassInfo, setUpdateClassInfo] = useState<UpdateClassInfoForComponent>(updateClassInfoObj);
  const [modalOpen, setModalOpen] = useState({
    createClass: false,
    updateClass: false
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmPageChange, setConfirmPageChange] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState(sessionStorage.getItem('searchClassDA') || '');
  const [singleClass, setSingleClass] = useState<SelectedClass | null>(null);
  const [checkAllToggle, setCheckAllToggle] = useState(false);
  const [dataProgress, setDataProgress] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchData = (async () => {
      setDataProgress(true);
      await dispatch(searchClasses(classPagination));
      setDataProgress(false);
    });
    fetchData();
  }, [dispatch, classPagination])

  const refreshClasses = async () => {
    await dispatch(getAllClasses());
    dispatch(searchClasses(classPagination));
  }

  // Search data in classes according to string
  const handleSearch = (item: string) => {
    setSearchTerm(item);

    if (item) {
      sessionStorage.setItem('searchClassDA', item);
    } else {
      sessionStorage.removeItem('searchClassDA');
    }

    dispatch(setClassPagination({ searchStr: item, page: 0 }));
  }

  const changePerPage = (item: number) => {
    setSelectedClasses([]); //reset selected rows in table
    setCheckAllToggle(false);
    dispatch(setClassPagination({ per_page: item, page: 0 }));
  }

  //Pagination for Classes
  const handlePaginate = (_event: any, value: number) => {
    setSelectedClasses([]); //reset selected rows in table
    setCheckAllToggle(false);
    dispatch(setClassPagination({ page: value - 1 }));
  }

  const handlePaginateWithBulkDeleteSelections = (_event: any, value: number) => {
    setPage(value - 1);
    setConfirmPageChange(true);
  }

  //Handle Single Record Select
  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>, classData: { id: number, name: string }) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedClasses(selectedClasses.concat({ ...classData, id: classData.id }));
    }
    else {
      setSelectedClasses(selectedClasses.filter((val) => classData.id !== val.id));
    }
  }

  //Handle All Records in dropdown list Select
  const handleAllSelect = (checked: boolean) => {
    if (checked) {
      const mappedSearchedClasses = searchedClasses.map(item => { return { id: item.id, name: item.name } });
      const classesSet = new Set(selectedClasses.concat(mappedSearchedClasses));
      const classesArray = Array.from(classesSet);
      setSelectedClasses([...classesArray]);
    }
    else {
      setSelectedClasses([]);
    }
    setCheckAllToggle(!checkAllToggle);
  }

  // Remove classes, schools, users search boxes data from session storage
  // Works on browser refresh
  window.onbeforeunload = () => {
    sessionStorage.removeItem('searchSchoolDA');
    sessionStorage.removeItem('searchClassDA');
    sessionStorage.removeItem('searchUserDA');
  }

  const handleSingleDelete = async () => {
    if (singleClass != null) {
      await dispatch(deleteClass(singleClass.id));
      const page = paginationAfterDelete(searchedClasses.length, 1, classPaginationPage);
      dispatch(setClassPagination({ page: page }));
    }
  }

  const handleBulkDelete = async () => {
    await dispatch(deleteBulkClasses(selectedClasses));
    const page = paginationAfterDelete(searchedClasses.length, selectedClasses.length, classPaginationPage);
    dispatch(setClassPagination({ page: page }));
    setSelectedClasses([]);
    setBulkAction(BulkActions.Empty);
    setCheckAllToggle(false);
  }

  const handleDeleteCallDecision = async (dialogStatus: boolean) => {
    setOpenDialog(false);
    if (dialogStatus) {
      if (singleClass) {
        await handleSingleDelete();
      } else if (selectedClasses.length > 0) {
        await handleBulkDelete();
      }
    }
    dispatch(getAllClasses());
    setSingleClass(null);
    setDialogMessage('');
  }

  const handleSingleDeleteDialog = (classInfo: SelectedClass) => {
    setDialogMessage(`Are you sure you want to delete the ${classInfo.name} class? This action cannot be undone.`);
    setSingleClass({ id: classInfo.id, name: classInfo.name });
    setOpenDialog(true);
  }

  const handleBulkDeleteDialog = () => {
    if (selectedClasses.length > 1) {
      setDialogMessage(`Are you sure you want to delete ${selectedClasses.length} classes? This action cannot be undone.`);
    } else if (selectedClasses.length === 1) {
      setDialogMessage(`Are you sure you want to delete the ${selectedClasses[0].name} class? This action cannot be undone.`);
    } else {
      setDialogMessage("Please select some classes.");
    }
    setOpenDialog(true);
  }

  // Handle Close upload classes modal
  const handleClose = () => {
    setBulkAction(BulkActions.Empty);
    setModalOpen({ ...modalOpen, createClass: false });
  }

  // Handle bulk add Classes data
  const handleBulkAddClasses = async (csvFileContent: ParseResult<string>[]) => {
    const bulkClassData = csvArrayToBulkClassData(csvFileContent);
    if (bulkClassData) {
      for (let i = 0; i < bulkClassData.length; i++) {
        for (let j = 0; j < licenseConsumers.length; j++) {
          let license = getLicenseNameOnlyFromLicenseTypeDescription((licenseConsumers[j].associatedGroupAllowance.licenseType.description).toLowerCase());
          if ((bulkClassData[i].curriculumTitleId).toLowerCase() === license) {
            bulkClassData[i].curriculumTitleId = String(licenseConsumers[j].id)
          }
        }
      }
      await dispatch(addBulkClasses({ bulkClassData, schools }));
    }
    dispatch(getAllClasses());
    dispatch(setClassPagination({ sort: '' }));
    setBulkAction(BulkActions.Empty);
  }

  // Handle create class data
  const handleCreateClass = async (data: CreateClassPayload) => {
    setModalOpen({ ...modalOpen, createClass: false });
    const payload = {
      data: data
    }
    await dispatch(createNewClass(payload.data));
    refreshClasses();
  }

  const paginationSection = (
    <Pagination
      count={Math.ceil(searchedClassCount / classPaginationPerPage)}
      siblingCount={0}
      color="primary"
      page={classPaginationPage + 1}
      variant="outlined"
      shape="rounded"
      onChange={selectedClasses.length > 0 ? handlePaginateWithBulkDeleteSelections : handlePaginate}
    />
  );

  const tableCellHead = tableHead.map((item, index) =>
    dataProgress ? <TableCell>&nbsp;</TableCell> :
    <TableCell key={index} align={item.align} style={{cursor: 'auto'}}>
      {
        item.type === ColumnType.Checkbox && bulkAction === BulkActions.Delete ? (
          <Checkbox
            color="primary"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            onChange={(e) => handleAllSelect(e.target.checked)}
            checked={checkAllToggle}
          />
        ) :
          item.type === ColumnType.Button ?
            (bulkAction === BulkActions.Empty || bulkAction === BulkActions.Add) && item.name :
            (item.type === ColumnType.Checkbox || item.sort === '' ? item.name :
            <>
              <span onClick={() => dispatch(setClassPagination({ sort: item.sort, sort_reverse: !classPaginationSortReverse }))} style={{cursor: 'pointer'}}>{item.name} <Icon className="fa fa-sort" style={{ fontSize: 9 }} /></span>
            </>
            )
      }
    </TableCell>
  );

  const handleUpdateClassClick = (objId: number) => {
    const foundClassObj = searchedClasses.find(val => val.id === objId);
    if (foundClassObj) {
      setUpdateClassInfo(foundClassObj);
      setModalOpen({ ...modalOpen, updateClass: true });
    }
  };

  //Update Class action
  const handleUpdateClass = async (item: ClassesResponse) => {
    setModalOpen({ ...modalOpen, updateClass: false });
    await dispatch(updateClassDistrict(item));
    refreshClasses();
  };

  const tableBreakPoint = useMediaQuery('(max-width: 768px)')

  const tableCellBody = searchedClasses?.length > 0 ? searchedClasses.map((item) => {
    const { id } = item;

    const editClassWrapper = () => {
      handleUpdateClassClick(item.id)
    };

    const deleteClassWrapper = () => {
      handleSingleDeleteDialog({id: item.id, name: item.name})
    };

    const EllipsisMenuProps: MenuOptions[] = [
      {
        color: "primary",
        handler: editClassWrapper,
        name: "Edit class"
      },
      {
        color: "secondary",
        handler: deleteClassWrapper,
        name: "Delete class"
      }
    ];

    return (
      <TableRow className={responsiveClasses.tableCellResponsive} key={item.id}>
        <TableCell align="left">
          {
            bulkAction === BulkActions.Delete && (
              <Checkbox
                checked={selectedClasses.some(value => value.id === id)}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={(e) => handleSelect(e, { id: id, name: item.name })}
                value={id}
              />
            )
          }
        </TableCell>
        <TableCell align="left">
          <span className="rwd-table-header">Class</span>
          {item.name}
        </TableCell>
        <TableCell align="left"><span className="rwd-table-header">School</span>{item.schoolName}</TableCell>
        <TableCell align="left">
          <span className="rwd-table-header">Teacher</span>
          {getFullName(item.teacher)}
        </TableCell>
        <TableCell align="left"><span className="rwd-table-header">Pin</span>{item.pin}</TableCell>
        <TableCell align="left">
          <span className="rwd-table-header">Start Date</span>
          {item.startDate}
        </TableCell>
        <TableCell align="left">
          <span className="rwd-table-header">End Date</span>
          {item.endDate}
        </TableCell>
        <TableCell align="center"><span className="rwd-table-header">Active Students</span>{item.studentCount}</TableCell>
        <TableCell align="center">
          <span className="rwd-table-header">Actions</span>
          {(bulkAction === BulkActions.Empty || bulkAction === BulkActions.Add) &&
            <Grid container spacing={2} justify={tableBreakPoint ? 'flex-end' : 'center'}>
              <Grid item>
                <EllipsisMenu menuOptions={EllipsisMenuProps}/>
              </Grid>
            </Grid>
          }
        </TableCell>
      </TableRow>
    )
  }
  ) : (
    <TableRow>
      <TableCell colSpan={10} align="center" className="inline-text">
        <Typography variant="overline" display="block">
          -Data is not available-
        </Typography>
      </TableCell>
    </TableRow>
  );

  const tableCellBodyNew = dataProgress ? (
    <TableRow>
      <TableCell colSpan={10} align="center" >
        <CircularProgress size={30} />
      </TableCell>
    </TableRow>
  ) : tableCellBody;

  const handleBulkDeleteCancel = () => {
    setBulkAction(BulkActions.Empty);
    setSelectedClasses([]);
    setCheckAllToggle(false);
  }

  const smallScreen = useMediaQuery('(max-width: 1023px)');
  const extraSmallScreen = useMediaQuery('(max-width: 599px)');

  return (
    <Fragment>
      <div>
        <Grid container style={{padding: '15px 15px 0px', flexWrap: 'nowrap'}}>
          <Grid 
            container 
            item xs={12} 
            alignItems='center' 
            className={classes.buttonContainer}
            style={smallScreen ? {paddingBottom: '5px'} : {height: BUTTON_CONTAINER_HEIGHT}}
          >
            <FormControl variant="outlined" className="bulkactionwidth bulkactionresponsive">
              <InputLabel className="bulktext" id="demo-simple-select-label">Bulk Action</InputLabel>
              <Paper>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-outlined"
                  value={bulkAction}
                  onChange={(e) => setBulkAction(e.target.value as BulkActions)}
                  label="bulkAction"
                  input={<InputBase className={classes.input}/>}
                >
                  <MenuItem value={BulkActions.Add}>Bulk Add</MenuItem>
                  <MenuItem value={BulkActions.Delete}>Bulk Delete</MenuItem>
                </Select>
              </Paper>
            </FormControl>
            { bulkAction !== BulkActions.Delete && (
              <Button
                className="iconbutton"
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => setModalOpen({ ...modalOpen, createClass: true })}
              >
                <AddIcon fontSize="small" /> New Class
              </Button>
            )}
            {
              bulkAction === BulkActions.Delete && (
                <Grid container item style={{gap: '10px'}}>
                  <Button size="small" onClick={handleBulkDeleteCancel} className="editbutton grey-button" variant="contained">
                    Cancel
                  </Button>
                  <Button size="small" onClick={handleBulkDeleteDialog} className="editbutton" variant="contained" color="secondary">
                    Delete
                  </Button>
                </Grid>
              )
            }
          </Grid>
        </Grid>
      </div>
      {!smallScreen && <div style={{borderBottom: `1px solid ${ELS_PRIMARY_BLUE_COLOR}`, marginBottom: '25px'}}></div>}
      <Grid container className="searchSectionMain" justify='space-between'>
        <Grid container item xl={4} lg={4} md={4} sm={5} xs={12}>
          <SearchBox
            searchVal={searchTerm}
            placeHolder="Search Classes"
            handleSearch={(data) => handleSearch(data)}
          />
        </Grid>
        <Grid item className="tabs-section-mobile" xl={8} lg={8} md={8} sm={7} xs={12} container justify={extraSmallScreen ? "flex-start" : "flex-end"}>
          <PerPage
            perPage={classPaginationPerPage}
            changePerPage={(pageVal) => changePerPage(pageVal)}
          /> &nbsp; &nbsp; {paginationSection}
        </Grid>
      </Grid>
      <div>
        <Grid container className="tableGrid">
          <Grid item xs={12}>
            <TableContainer>
              <Table className="homeTable rwd-table-districtclasses">
                <TableHead>
                  <TableRow>
                    {tableCellHead}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableCellBodyNew}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
      <DialogBox
        open={openDialog}
        message={dialogMessage}
        title={selectedClasses.length > 1 ? "Delete Classes?" : selectedClasses.length === 1 || singleClass ? "Delete Class?" : "Select Classes"}
        handleClose={(dialogStatus: boolean) => handleDeleteCallDecision(dialogStatus)}
      />
      <DialogBox
        open={confirmPageChange}
        message="You will lose your selected class(es) if you leave this page."
        title="Continue?"
        handleClose={() => setConfirmPageChange(false)}
        setSelectedRows={() => setSelectedClasses([])}
        setCheckAllToggle={() => setCheckAllToggle(false)}
        page={page}
        tab="classes"
      />
      <BulkAddClassModal
        modalOpen={bulkAction === BulkActions.Add}
        handleClose={handleClose}
        handleSubmit={(data) => handleBulkAddClasses(data)}
      />
      <CreateClassModal
        type={UserType.District}
        modalOpen={modalOpen.createClass}
        handleClose={() => setModalOpen({ ...modalOpen, createClass: false })}
        handleSubmit={(data) => handleCreateClass(data)}
      />
      <UpdateClassModal
        type={UserType.District}
        modalOpen={modalOpen.updateClass}
        handleClose={() => setModalOpen({ ...modalOpen, updateClass: false })}
        handleSubmit={(data) => handleUpdateClass(data)}
        classInfo={updateClassInfo}
        setClassInfo={setUpdateClassInfo}
      />
    </Fragment>
  )
}