import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createClass, deleteClassById, getAllClassInfo, getClassById, getSearchedClassInfo, updateClassFromDistrict, updateClassFromTeacher } from "../../clientLibrary/class/class";
import { CreateClassPayload, ClassesResponse, SearchClassesParams, UpdateClassDistrictPayload } from "../../clientLibrary/class/classInterfaces";
import { getSchoolIdFromName } from "../UtilityFunctions";
import { setBackdrop, updateSnackbarError, updateSnackbarSuccess } from "./utilitySlice";
import { format } from "date-fns";
import { timezoneOffset, updateSnackbarMessageUsingResults } from "../../common/GeneralFunction";
import { SchoolResponse } from "../../clientLibrary/school/schoolInterfaces";
import { DisplayCategory } from "../../clientLibrary/GeneralInterfaces";

export interface ClassState {
    pagination: ClassPagination,
    classes: Array<ClassesResponse>,
    classCount: number,
    searchedClasses: Array<ClassesResponse>,
    searchedClassCount: number
}

export interface ClassPagination {
    page: number,
    per_page: number,
    sort: string,
    sort_reverse: boolean,
    searchStr?: string,
    expired?: boolean
}

interface AddBulkClassesPayload {
    bulkClassData: Array<BulkClassData>,
    schools: Array<SchoolResponse>
}

interface DeleteBulkClassesData {
    id: number,
    name: string
}

export interface BulkClassData {
    class: string,
    curriculumTitleId: string,
    endDate: string,
    school: string,
    startDate: string,
    teacherLogin: string
}

const initialState: ClassState = {
    pagination: { page: 0, per_page: 25, sort: "", sort_reverse: false },
    classes: [],
    classCount: 0,
    searchedClasses: [],
    searchedClassCount: 0
}

export const createNewClass = createAsyncThunk(
    'class/createNewClass',
    async (payload: CreateClassPayload, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await createClass(payload);
            thunkAPI.dispatch(updateSnackbarSuccess('New class created successfully.'));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Create class failed.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }

    }
);

export const getAllClasses = createAsyncThunk(
    'class/getAllClasses',
    async (_, thunkAPI) => {
        try {
            const classResponse = await getAllClassInfo();
            return classResponse;
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to fetch class data.'));
            throw error;
        }
    }
);

export const searchClasses = createAsyncThunk(
    'class/searchClasses',
    async (params: SearchClassesParams, thunkAPI) => {
        try {
            const classResponse = await getSearchedClassInfo(params);
            return classResponse;
        } catch (error) {
            console.log(error);
            thunkAPI.dispatch(updateSnackbarError('Failed to fetch class data.'));
            throw error;
        }
    }
);


export const updateClassDistrict = createAsyncThunk(
    'class/updateClassDistrict',
    async (payload: ClassesResponse, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            const classResponseData = await getClassById(payload.id);
            const classData: UpdateClassDistrictPayload = {
                ...classResponseData,
                name: payload.name,
                startDate: payload.startDate,
                endDate: payload.endDate,
                teacher: payload.teacher
            };
            await updateClassFromDistrict(classData);
            thunkAPI.dispatch(updateSnackbarSuccess('Class updated successfully.'));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to edit class data.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
)

export const updateClassTeacher = createAsyncThunk(
    'class/updateClassTeacher',
    async (payload: ClassesResponse, thunkAPI: any) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await updateClassFromTeacher(payload);
            thunkAPI.dispatch(updateSnackbarSuccess('Class updated successfully.'));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to edit class data.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const deleteClass = createAsyncThunk(
    'class/deleteClass',
    async (classId: number, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await deleteClassById(classId);
            thunkAPI.dispatch(updateSnackbarSuccess(`The class was successfully deleted.`));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(setBackdrop(false));
        }

    }
);

export const addBulkClasses = createAsyncThunk(
    'class/addBulkClasses',
    async (payload: AddBulkClassesPayload, thunkAPI: any) => {
        thunkAPI.dispatch(setBackdrop(true));

        const results = await Promise.allSettled(payload.bulkClassData.map(classToAdd => {
            const schoolId = getSchoolIdFromName(payload.schools, classToAdd.school);
            const populatedClassInfo: CreateClassPayload = {
                name: classToAdd.class,
                description: classToAdd.class,
                schoolID: schoolId || 0,
                teacher: {
                    login: classToAdd.teacherLogin
                },
                startDate: format(timezoneOffset(classToAdd.startDate), 'yyyy-MM-dd'),
                endDate: format(timezoneOffset(classToAdd.endDate), 'yyyy-MM-dd'),
                licenseConsumerID: parseInt(classToAdd.curriculumTitleId)
            }

            return createClass(populatedClassInfo);
        }));

        const successfullyAddedClasses: string[] = [];
        const failedToAddClasses: string[] = [];

        payload.bulkClassData.forEach((classToAdd, index) => {
            if (results[index].status === 'fulfilled') {
                successfullyAddedClasses.push(classToAdd.class);
            } else {
                failedToAddClasses.push(classToAdd.class);
            }
        });

        thunkAPI.dispatch(setBackdrop(false));

        const displayCategoryArr: DisplayCategory[] = [
            {
                arrayType: 'success',
                entities: successfullyAddedClasses,
                message: ` class(es) successfully added. `
            },
            {
                arrayType: 'failure',
                entities: failedToAddClasses,
                message: ` class(es) failed to add.  Please check your file or add the class(es) individually using the "+ New Class" button `
            }
        ];

        thunkAPI.dispatch(updateSnackbarMessageUsingResults(displayCategoryArr));
    }
);

export const deleteBulkClasses = createAsyncThunk(
    'class/deleteBulkClasses',
    async (classPayload: DeleteBulkClassesData[], thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        const successfullyDeletedClasses: string[] = [];
        const failedToDeleteClasses: string[] = [];
        for (let classInfo of classPayload) {
            try {
                await deleteClassById(classInfo.id);
                successfullyDeletedClasses.push(classInfo.name);
            } catch (error) {
                console.log(error);
                failedToDeleteClasses.push(classInfo.name);
            }
        }

        thunkAPI.dispatch(setBackdrop(false));

        const displayCategoryArr: DisplayCategory[] = [
            {
                arrayType: 'success',
                entities: successfullyDeletedClasses,
                message: ` class(es) successfully deleted. `
            },
            {
                arrayType: 'failure',
                entities: failedToDeleteClasses,
                message: ` class(es) failed to delete.  Please try deleting them individually. `
            }
        ];

        thunkAPI.dispatch(updateSnackbarMessageUsingResults(displayCategoryArr));
    }
);

export const classSlice = createSlice({
    name: 'class',
    initialState,
    reducers: {
        setClassPagination: (state, action: PayloadAction<Partial<ClassPagination>>) => {
            state.pagination = { ...state.pagination, ...action.payload }
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllClasses.fulfilled, (state, action) => {
            if (!state.searchedClasses || state.searchedClasses.length <= 0) {
                state.searchedClasses = action.payload.data;
                state.searchedClassCount = action.payload.count;
            }
            state.classes = action.payload.data;
            state.classCount = action.payload.count
        })
        .addCase(getAllClasses.rejected, (state) => {
            state.classes = [];
            state.classCount = 0;
            state.searchedClasses = [];
            state.searchedClassCount = 0;
        })
        .addCase(searchClasses.fulfilled, (state, action) => {
            state.searchedClasses = action.payload.data;
            state.searchedClassCount = action.payload.count;
        })
        .addCase(searchClasses.rejected, (state) => {
            state.searchedClasses = [];
            state.searchedClassCount = 0;
        });
    }
});

export const { setClassPagination } = classSlice.actions;

export default classSlice.reducer;