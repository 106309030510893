import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardMedia,
    Chip,
    Grid,
    makeStyles,
    Typography,
    Button,
    Tooltip,
} from "@material-ui/core";
import { KeyboardReturn, AccountCircle, Autorenew, Cancel, School } from "@material-ui/icons";
import StudentEnrollment from './studentEnrollment';
import { resetStudentPasswordAsParent, unassignLicenseAsParent } from '../../../reduxModule/slices/parentSlice';
import { useAppDispatch } from '../../../hooks'
import { ACTION_BUTTON_FONT_SIZE } from "../../../constants";

interface LicenseCardProps {
    activeCard: number | null,
    bookEdition: string | null,
    cardExpiration: string,
    cardImage: string,
    cardTitle: string,
    id: number,
    licenseId: number | null,
    licenseTypeId: number,
    licenseProviderId: number,
    studentEmail: string | null,
    studentFirstName: string | null,
    studentLastName: string | null,
    changeActiveCard: (id: number | null) => void,
    setRefreshData: (arg: boolean) => void
}

const useStyles = makeStyles((theme) => ({
    card: {
        width: '600px',
        display: 'flex',
        paddingRight: '2%',
        flexDirection: "column",
        justifyContent: 'space-between',
        height: '250px',
        [theme.breakpoints.down('xs')]: {
            width: '450px'
        }
    },
    cardTitle: {
        textTransform: "uppercase",
        fontSize: "1.5rem",
        fontWeight: 600,
        textAlign: "center",
        width: '400px',
    },
    cardImage: {
        height: '250px',
        objectPosition: 'left',
    },
    cardInfo: {
        textTransform: "uppercase",
        fontSize: '1em',
        fontWeight: 600,
        width: '400px',
        textAlign: 'center'
    },
    cardButton: {
        width: "160px",
        border: "2px solid #303f9f",
        borderRadius: "20px",
        backgroundColor: "#303f9f",
        color: '#FFF',
        '&:hover': {
            backgroundColor: "#0f73c6",
            borderColor: "#0f73c6",
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        },
    },
    closeBtn: {
        color: 'gray',
        display: 'inline',
        marginTop: '-64px',
        float: 'right',
        borderRadius: '20px'
    },
    actionButton: {
        width: '40%',
        height: '100%',
        fontSize: ACTION_BUTTON_FONT_SIZE
    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
        textTransform: 'uppercase',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    editionLabel: {
        border: 'none',
        fontWeight: 'bold',
    },
    assignedTo: {
        color: '#757575',
        fontWeight: 'normal'
    },
    cardFrontInfo: {
        height: '85%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));

function LicenseCard(props: LicenseCardProps) {
    const [showStudentEnrollmentFields, setShowStudentEnrollmentFields] = useState<boolean>(false);
    const classes = useStyles();
    const assignedStatus = props.licenseId !== null ? `${props.studentFirstName} ${props.studentLastName}` : `Unassigned`;
    const manageButtonText = props.licenseId !== null ? 'Manage License' : 'Assign License';
    const dispatch = useAppDispatch();
    const displayDate = new Date(props.cardExpiration).toLocaleDateString(undefined, {day: 'numeric', month: 'long', year: 'numeric'});
    const studentNameTooltip = props.licenseId !== null ? `${props.studentFirstName} ${props.studentLastName}` : '';

    useEffect(() => {
        if (props.activeCard !== props.id) {
            setShowStudentEnrollmentFields(false);
        }
    }, [props.activeCard, props.id]);

    const handleManageButton = () => {
        const newActiveCard = props.activeCard === props.id ? null : props.id;
        setShowStudentEnrollmentFields(props.licenseId !== null ? false : true);
        props.changeActiveCard(newActiveCard);
    }

    const handleUnassignButton = async () => {
        if (props.licenseId !== null) {
            await dispatch(unassignLicenseAsParent(props.licenseId));
            props.setRefreshData(true);
            props.changeActiveCard(null);
        }
    }

    const handleResetPassword = async () => {
        if (props.studentEmail !== null) {
            await dispatch(resetStudentPasswordAsParent(props.studentEmail));
            props.changeActiveCard(null);
        }
    }

    return (
        <>
            <Grid item style={{marginTop: 30}}>
                <Card raised={true} className={classes.card}>
                    <Grid container direction='row' spacing={2}>
                        <Grid item sm={4}>
                            <CardMedia component='img' src={props.cardImage} className={classes.cardImage} alt={props.cardTitle} />
                        </Grid>
                        <Grid item container wrap='nowrap' style={{ height: '100%' }} direction='column' sm={8} xs={12}>
                            <Grid item>
                                <Tooltip title={props.cardTitle} >
                                    <div>
                                        <CardHeader 
                                        role={'heading'} 
                                        aria-level={2} 
                                        titleTypographyProps={{noWrap: true, className: classes.cardTitle}} 
                                        title={props.cardTitle} 
                                        style={{ padding: '1% 0% 0%' }} />
                                    </div>
                                </Tooltip>
                            </Grid>
                            {props.activeCard === props.id ? (
                                <Grid item>
                                    {showStudentEnrollmentFields ? (
                                        <StudentEnrollment
                                            bookEdition={props.bookEdition}
                                            cardTitle={props.cardTitle}
                                            licenseId={props.licenseId}
                                            licenseTypeId={props.licenseTypeId}
                                            licenseProviderId={props.licenseProviderId}
                                            studentEmail={props.studentEmail}
                                            studentFirstName={props.studentFirstName}
                                            studentLastName={props.studentLastName}
                                            licenseExpiration={props.cardExpiration}
                                            changeActiveCard={props.changeActiveCard}
                                            setRefreshData={props.setRefreshData}
                                        />
                                    ) : (
                                        <Grid container style={{ paddingTop: '35px' }}>
                                            <Grid item container justify="center">
                                                <Button className={classes.actionButton} onClick={() => setShowStudentEnrollmentFields(true)}>
                                                    <div className={classes.iconButtonLabel}>
                                                        <AccountCircle style={{ color: 'green', fontSize: '60px' }}/>
                                                        <Typography style={{ fontSize: ACTION_BUTTON_FONT_SIZE }}>Edit</Typography>
                                                    </div>
                                                </Button>
                                                <Button className={classes.actionButton} onClick={handleResetPassword}>
                                                    <div className={classes.iconButtonLabel}>
                                                        <Autorenew style={{ color: '#188ADF', fontSize: '60px'}}/>
                                                        <Typography style={{ fontSize: ACTION_BUTTON_FONT_SIZE }}>Reset Password</Typography>
                                                    </div>
                                                </Button>
                                            </Grid>
                                            <Grid item container justify="space-between" alignContent="flex-end" style={{marginTop: '45px'}}>
                                                <Button onClick={handleUnassignButton} startIcon={<Cancel fontSize="small" color="error"/>}>
                                                    Unassign 
                                                </Button>
                                                <Button onClick={handleManageButton} startIcon={<KeyboardReturn fontSize="small" color="action"/>}>
                                                    Return 
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            ) : (
                                <div className={classes.cardFrontInfo}>
                                    <div style={{ textAlign: 'center', visibility: props.bookEdition ? 'visible' : 'hidden' }}>
                                        <Chip variant='outlined' className={classes.editionLabel} size='small' label={'Teacher Edition'} avatar={<School fontSize='small' />} />
                                    </div>
                                    <div style={{ flex: '2', alignItems: 'center', height: '100%', display: 'flex' }}>
                                        <Button color='primary'
                                            className={classes.cardButton}
                                            onClick={() => handleManageButton()}
                                            style={{height: '40px'}}
                                        >
                                            {manageButtonText}
                                        </Button>
                                    </div>
                                    <Typography style={{ color: "#757575", textAlign: 'center' }}>Expires: {displayDate}</Typography>
                                    <Tooltip title={studentNameTooltip} arrow>
                                        <Typography noWrap className={classes.cardInfo}>
                                            <span style={{ display: props.licenseId !== null ? 'auto' : 'none' }} className={classes.assignedTo}>Assigned To: </span>
                                            {assignedStatus}
                                        </Typography>
                                    </Tooltip>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </>
    );
}

export default LicenseCard;