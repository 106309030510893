export const getLogin = (): string | null => {
    return sessionStorage.getItem('impersonatingLogin');
}

export const setLogin = (impersonatingLogin: string): void => {
    sessionStorage.setItem('impersonatingLogin', impersonatingLogin);
}

export const removeLogin = (): void => {
    sessionStorage.removeItem('impersonatingLogin');
}

export const isImpersonating = (): boolean => {
    if (getLogin()) {
        return true;
    }
    return false;
}