import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core';
import { useAppDispatch } from '../../hooks';
import { setUserPagination } from '../../reduxModule/slices/userSlice';
import { setSchoolPagination } from '../../reduxModule/slices/schoolSlice';
import { setClassPagination } from '../../reduxModule/slices/classSlice';

interface AlertDialogProps {
  handleClose(dialogStatus?: boolean): void,
  message: string,
  open: boolean,
  title: string,
  setSelectedRows?(): void,
  setCheckAllToggle?(): void,
  page?: number,
  tab?: string
}

const useStyles = makeStyles({
  cancelButton: {
    color: 'gray',
    backgroundColor: 'transparent',
    textTransform: 'none'
  },
  deleteButton: {
    textTransform: 'none'
  }
})

export default function AlertDialog(props: AlertDialogProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const handleContinue = () => {
    if (props.setSelectedRows && props.setCheckAllToggle) {
      props.handleClose()
      props.setSelectedRows();
      props.setCheckAllToggle();
      if (props.tab === "users") {
        dispatch(setUserPagination({ page: props.page }));
      } else if (props.tab === "schools") {
        dispatch(setSchoolPagination({ page: props.page }))
      } else {
        dispatch(setClassPagination({ page: props.page }))
      }
    }
  }

  let actionButtons;
  if (props.title === "Reset Password?") {
    actionButtons = (
      <>
        <Button onClick={() => props.handleClose(false)} className={classes.cancelButton} variant='contained'>
          Cancel
        </Button>
        <Button onClick={() => props.handleClose(true)} className={classes.deleteButton} color="secondary" variant='contained'>
          Yes, Reset
        </Button> 
      </>
    )
  } else if (props.title.includes("Select")) {
    actionButtons = (
      <Button onClick={() => props.handleClose(false)} color="primary" variant='contained'>
        Ok
      </Button>
    )
  } else if (props.title.includes("Continue")) {
    actionButtons = (
      <>
        <Button onClick={() => props.handleClose(false)} className={classes.cancelButton} variant='contained'>
          Cancel
        </Button>
        <Button onClick={handleContinue} color="primary" variant='contained'>
          Continue
        </Button>
      </>
    )
  } else {
    actionButtons = (
      <>
        <Button onClick={() => props.handleClose(false)} className={classes.cancelButton} variant='contained'>
          Cancel
        </Button>
        <Button onClick={() => props.handleClose(true)} className={classes.deleteButton} color="secondary" variant='contained'>
          Yes, Delete
        </Button>
      </>
    )
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {actionButtons}
        </DialogActions>
      </Dialog>
    </div>
  );
}
