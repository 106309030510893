import React, { useState, useEffect } from 'react';
import {
    TextField,
    makeStyles,
    Button,
    Typography,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { isEmailInDb } from '../../../clientLibrary/account/account';
import { useAppSelector } from '../../../hooks';
import { emailRegex } from "../../../common/Constants";
import { CreateBaseUserPayload, UserResponse } from '../../../clientLibrary/user/userInterfaces';
import { DistrictRoles, GlobalCPMRoles } from '../../../clientLibrary/Enums';
import { createStudentUser } from '../../../clientLibrary/user/user';
import { assignLicenseAsParent, updateStudentNameAsParent } from '../../../reduxModule/slices/parentSlice';
import { ParentLicenseAssignmentData } from '../../../clientLibrary/license/licenseInterfaces';
import { useAppDispatch } from '../../../hooks';
import { ACTION_BUTTON_FONT_SIZE } from '../../../constants';

const useStyles = makeStyles((theme) => ({
    studentInput: {
        width: '100%',
        padding: '0px 0px 5px',
    },
    enrollmentButtons: {
        width: '100%',
        paddingTop: '8px',
        display: 'flex',
        gap: '8px'
    },
    blueBtn: {
        backgroundColor: '#303f9f',
        color: '#fff',
        fontSize: ACTION_BUTTON_FONT_SIZE,
        '&:hover': {
            backgroundColor: '#0f73c6',
        },
        '&:disabled': {
            backgroundColor: '#fff',
            border: '1px dotted #bbbaba'
        }
    },
    redBtn: {
        backgroundColor: '#c51162',
        color: '#fff',
        fontSize: ACTION_BUTTON_FONT_SIZE,
        '&:hover': {
            backgroundColor: '#db3939'
        }
    },
    alert: {
        whiteSpace: "pre-line",
        alignItems: "center"
    },
    studentEmailDisplay: {
        fontWeight: 'bold',
        fontStyle: 'italic',
        width: '80%',
        padding: '25px 0px',
        color: '#757575',
    },
    helpIcon: {
        color: '#1884DF',
        float: 'right',
        padding: '0',
        marginTop: '25px',
        '&:focus': {
            backgroundColor: 'lightgray',
        }
    },
    helpIconTooltip: {
        maxWidth: '300px',
        fontSize: '.8em',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '200px'
        },
    }
}));

interface StudentEnrollmentProps {
    bookEdition: string | null,
    cardTitle: string,
    mobile?: boolean,
    licenseId: number | null,
    licenseTypeId: number,
    licenseProviderId: number,
    studentEmail: string | null,
    studentFirstName: string | null,
    studentLastName: string | null,
    licenseExpiration: string,
    changeActiveCard: (id: number | null) => void,
    setRefreshData: (arg: boolean) => void
}

const StudentEnrollment = (props: StudentEnrollmentProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const gridDirection = props.mobile ? 'column' : 'row';

    const students = useAppSelector(state => state.parent.students);
    const accountContext = useAppSelector(state => state.parent.accountContext);
    const assignedLicenses = useAppSelector(state => state.parent.assignedLicenses);

    const [credentials, setCredentials] = useState(props.studentEmail ? props.studentEmail : '');
    const [firstName, setFirstName] = useState(props.studentFirstName ? props.studentFirstName : '');
    const [lastName, setLastName] = useState(props.studentLastName ? props.studentLastName : '');

    const [userToEnroll, setUserToEnroll] = useState<UserResponse | null>(null);
    const [editStudentWithoutAssigning, setEditStudentWithoutAssigning] = useState(false);

    const [isEnrollBtnDisabled, setIsEnrollBtnDisabled] = useState(true);
    const [nameFieldsDisabled, setNameFieldsDisabled] = useState(true);
    const [emailFieldDisabled, setEmailFieldDisabled] = useState(false);
    const [helpTooltipOpen, setHelpTooltipOpen] = useState(false);

    const assigneeEmail = props.studentEmail ? props.studentEmail : '';
    const defaultBlack = 'black';
    const errorRed = '#C9094D';
    const [emailAlert, setEmailAlert] = useState('\u00A0');
    const [emailAlertColor, setEmailAlertColor] = useState(defaultBlack);

    useEffect((): void => { 
        if (!emailRegex.test(credentials)) {
            setNameFieldsDisabled(true);
        } else { setNameFieldsDisabled(false) }
        if (!credentials || !firstName || !lastName || !emailRegex.test(credentials)) {
            setIsEnrollBtnDisabled(true);
        } else { setIsEnrollBtnDisabled(false) }
        if (props.bookEdition === 'teacher') {
            const myParentAccount = accountContext?.primaryContact;
            if (myParentAccount) {
                setUserDisplayValues(myParentAccount);
                setEmailFieldDisabled(true);
                setNameFieldsDisabled(true);
                setUserToEnroll(myParentAccount);
                setEmailAlert('Only your own account can be assigned to the Teacher Edition');
                setEmailAlertColor(defaultBlack);
                if (props.licenseId !== null) {
                    setIsEnrollBtnDisabled(true);
                }
            }
        }
        if (props.licenseId !== null && props.bookEdition === null) {
            const myStudent = students.find(student => student.email === credentials);
            if (myStudent) {
                setUserToEnroll(myStudent);
                setEditStudentWithoutAssigning(true);
            }
        }
    }, [credentials, firstName, lastName, props.bookEdition, props.licenseId, accountContext, students]);

    const handleCredentialsChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCredentials(e.target.value.trim());
        setFirstName('');
        setLastName('');
    }

    const setUserDisplayValues = (user: UserResponse) => {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setCredentials(user.email);
    }

    const handleCredentialBlur = async () => {
        setEmailAlertColor(defaultBlack);
        setEmailAlert('\u00A0');
        setNameFieldsDisabled(false);
        setEditStudentWithoutAssigning(false);
        setUserToEnroll(null);
        if (!emailRegex.test(credentials)) {
            setEmailAlert('Invalid email format');
            setEmailAlertColor(errorRed);
            setNameFieldsDisabled(true);
        }
        try {
            const doesStudentExist = await isEmailInDb(credentials);
            if (doesStudentExist) {
                const myStudent = students.find(student => student.email === credentials);
                if (!myStudent) {
                    setNameFieldsDisabled(true);
                    setFirstName('');
                    setLastName('');
                    setEmailAlert('Student not associated with your account');
                    setEmailAlertColor(errorRed);
                } else {
                    setUserDisplayValues(myStudent);
                    setUserToEnroll(myStudent);
                    if (myStudent.email === accountContext?.primaryContact.email) {
                        setEmailAlert('Go to account settings to change your name');
                        setEmailAlertColor(defaultBlack);
                        setNameFieldsDisabled(true);
                    }
                    const licensesAssignedToThisStudent = assignedLicenses.filter(license => license.user.email === myStudent.email);
                    if (licensesAssignedToThisStudent.length > 0) {
                        const licenseOfSameType = licensesAssignedToThisStudent.find(license => license.licenseType.id === props.licenseTypeId);
                        if (licenseOfSameType) {
                            if (licenseOfSameType.id !== props.licenseId) {
                                setIsEnrollBtnDisabled(true);
                                setNameFieldsDisabled(true);
                                setEmailAlertColor(errorRed);
                                setEmailAlert('Student is already assigned to a license of this type');
                            } else {
                                setEditStudentWithoutAssigning(true);
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async () => {
        if (userToEnroll === null) {
            await enrollNewUser().then(() => props.setRefreshData(true)).then(() => props.changeActiveCard(null));
        } else {
            await enrollExistingUser().then(() => props.setRefreshData(true)).then(() => props.changeActiveCard(null));
        }
        setUserToEnroll(null);
    }

    const enrollExistingUser = async () => {
        let snackbarMessage = '';
        try {
            if (userToEnroll != null) {
                if (userToEnroll.firstName !== firstName || userToEnroll.lastName !== lastName) {
                    if (editStudentWithoutAssigning) {
                        snackbarMessage = `${userToEnroll.email} name updated successfully`;
                    } else {
                        snackbarMessage = `${userToEnroll.email} name updated and assigned to ${props.cardTitle}`;
                    }
                    const updatedStudentInfo = {
                        ...userToEnroll,
                        firstName: firstName,
                        lastName: lastName,
                        globalCPMRole: "Student",
                        districtID: accountContext?.id
                    } as UserResponse;
                    await dispatch(updateStudentNameAsParent({ userData: updatedStudentInfo, snackbarMessage: editStudentWithoutAssigning ? snackbarMessage : null }));
                } else {
                    snackbarMessage = `${userToEnroll.email} successfully assigned to ${props.cardTitle}.`;
                }
                if (!editStudentWithoutAssigning) {
                    const userData = { login: userToEnroll.login, expirationDate: props.licenseExpiration, consumerIDs: [props.licenseProviderId] } as ParentLicenseAssignmentData;
                    await dispatch(assignLicenseAsParent({ userData, snackbarMessage }));
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const enrollNewUser = async () => {
        const districtId = accountContext ? accountContext.id : 0;
        const studentPayload: CreateBaseUserPayload = {
            email: credentials,
            firstName: firstName,
            langKey: "en",
            lastName: lastName,
            login: credentials,
            password: credentials,
            confirmPassword: credentials,
            districtID: districtId,
            districtRole: DistrictRoles.Student.toUpperCase(),
            globalCPMRole: GlobalCPMRoles.Student.toUpperCase(),
            schoolID: 0,
            schoolRole: null,
        }
        try {
            const login = await createStudentUser(studentPayload);
            const userData = {login, expirationDate: props.licenseExpiration, consumerIDs: [props.licenseProviderId]} as ParentLicenseAssignmentData;
            await dispatch(assignLicenseAsParent({ userData, snackbarMessage: `${login} account successfully created and assigned to ${props.cardTitle}` }));
        } catch (error) {
            console.log(error);
        }
    }

    const clearFields = () => {
        setCredentials('');
        setFirstName('');
        setLastName('');
        setNameFieldsDisabled(false);
    }

    const handleCancel = () => {
        clearFields();
        props.changeActiveCard(null);
    }

    return (
        <div style={{ display: 'flex', gap: '16px', flexFlow: `${gridDirection} wrap`, height: '100%', justifyContent: props.mobile ? 'space-between' : 'flex-start' }}>
            <div style={{ width: '100%' }} >
                {props.licenseId !== null ? (
                    <>
                        <Tooltip placement='left-start'
                                 classes={{ tooltip: classes.helpIconTooltip }}
                                 title='To change the email assigned to this license, first you must Unassign the license, then Assign it to the new email'
                                 open={helpTooltipOpen}
                                 id='reassignment-tooltip'>
                            <IconButton className={classes.helpIcon}
                                        onClick={() => setHelpTooltipOpen(!helpTooltipOpen)}
                                        onBlur={() => setHelpTooltipOpen(false)}
                                        aria-label='How To Reassign License'
                                        aria-controls='reassignment-tooltip'
                                        aria-haspopup='dialog'
                                        disableRipple>
                                <HelpOutline/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow interactive title={assigneeEmail}>
                            <Typography noWrap className={classes.studentEmailDisplay}>{props.studentEmail}</Typography>
                        </Tooltip>
                    </>
                    ) : (
                    <TextField
                        inputProps={{ 'aria-label': 'Student Email' }}
                        autoFocus
                        className={classes.studentInput}
                        disabled={emailFieldDisabled}
                        label='Email'
                        value={credentials}
                        onChange={(e) => handleCredentialsChange(e)}
                        onBlur={handleCredentialBlur}
                        helperText={<span style={{ color: emailAlertColor }}>{emailAlert}</span>}
                    />
                )}
            </div>
            <div style={{ display: 'flex', gap: '8px', width: '100%', flexDirection: props.mobile ? 'column' : 'row' }}>
                    <TextField
                        inputProps={{ 'aria-label': 'First Name' }}
                        fullWidth
                        className={classes.studentInput}
                        label='First Name'
                        value={firstName}
                        disabled={nameFieldsDisabled}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField
                        inputProps={{ 'aria-label': 'Last Name' }}
                        fullWidth
                        className={classes.studentInput}
                        label='Last Name'
                        value={lastName}
                        disabled={nameFieldsDisabled}
                        onChange={(e) => setLastName(e.target.value)}
                    />
            </div>
            <div style={{ justifyContent: props.mobile ? 'space-between' : 'flex-end' }} className={classes.enrollmentButtons}>
                <Button
                    variant='contained'
                    className={classes.redBtn}
                    onClick={() => handleCancel()}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    className={classes.blueBtn}
                    disabled={isEnrollBtnDisabled}
                    onClick={() => handleSubmit()}
                >
                    Submit
                </Button>
            </div>
        </div>
    )
}

export default StudentEnrollment;