import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContractReportResponse, ContractTableData } from "../../clientLibrary/contract/contractInterfaces";
import { getContractReportsById, getCustomerContracts } from "../../clientLibrary/contract/contract";
import { setDataProgress, updateSnackbarError } from "./utilitySlice";
import { PaginationParams } from "../../clientLibrary/GeneralInterfaces";
import { LicenseTypeResponse } from "../../clientLibrary/license/licenseInterfaces";
import { getLicenseDisplayName } from "../UtilityFunctions";

export interface ContractState {
    contracts: ContractTableData[],
    contractCount: number,
    licenseForecastTableData: ParsedLicensesWithExpirations[],
    licenseForecastLoading: boolean,
    pagination: ContractPagination
}

interface ContractPagination {
    page: number,
    per_page: number,
    sort: string,
    sort_reverse: boolean
}

interface GetLicenseTableDataPayload {
    contracts: ContractTableData[],
    licenseTypeReference: Array<LicenseTypeResponse>
}

export interface ParsedLicense extends ContractReportResponse {
    abbr: string | null,
    role: string | null,
    licenseDisplayName: string,
    teacherLicensesTotal?: number,
    teacherLicensesRemaining?: number,
    licensePoolId?: number,
}

export interface SortedParsedLicense extends ParsedLicense {
    studentLicenseTypeName?: string,
    teacherLicenseTypeName?: string,
}

export interface ParsedLicensesWithExpirations {
    expiration: string,
    userType: string,
    licenseTypeName: string,
    licenseDisplayName: string
}

const initialState: ContractState = {
    contracts: [],
    contractCount: 0,
    licenseForecastTableData: [],
    licenseForecastLoading: false,
    pagination: { page: 0, per_page: 25, sort: "", sort_reverse: false }
}

export const getContracts = createAsyncThunk(
    'contract/getContracts',
    async (params: PaginationParams, thunkAPI: any) => {
        thunkAPI.dispatch(setDataProgress(true));
        try {
            const customerContractsResponse = await getCustomerContracts(params);
            thunkAPI.dispatch(setDataProgress(false));
            return customerContractsResponse;
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to fetch contract data.'));
            thunkAPI.dispatch(setDataProgress(false));
            throw error;
        }
    }
);

export const getLicenseForecastTableData = createAsyncThunk(
    'contract/getLicenseForecastTableData',
    async (payload: GetLicenseTableDataPayload, thunkAPI: any) => {
        const { contracts } = payload;

        const contractsArr = await Promise.all(contracts.map(async (contract) => {
            try {
                const contractReportsData = await getContractReportsById(contract.id);
                return contractReportsData;
            } catch (error) {
                console.error(error);
                throw error;
            }
        }));

        const licenses: ParsedLicensesWithExpirations[] = [];
        contractsArr.forEach(contract => {
            for (let license of contract.contractLicenses) {
                const [licenseType, userType] = license.licenseTypeName.split("_");
                const licenseTypeReference = thunkAPI.getState().license.licenseTypeReference;
                licenses.push({
                    licenseTypeName: licenseType,
                    licenseDisplayName: getLicenseDisplayName(license.licenseTypeName, licenseTypeReference),
                    userType: userType,
                    expiration: license.expiration,
                })
            }
        });

        return licenses;
    }
);

export const contractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        setContractPagination: (state, action: PayloadAction<Partial<ContractPagination>>) => {
            state.pagination = { ...state.pagination, ...action.payload }
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getContracts.fulfilled, (state, action) => {
            state.contracts = action.payload.data;
            state.contractCount = action.payload.count
        })
        .addCase(getContracts.rejected, (state) => {
            state.contracts = [];
            state.contractCount = 0;
        })
        .addCase(getLicenseForecastTableData.pending, (state) => {
            state.licenseForecastLoading = true;
        })
        .addCase(getLicenseForecastTableData.fulfilled, (state, action) => {
            state.licenseForecastTableData = action.payload;
            state.licenseForecastLoading = false;
        })
        .addCase(getLicenseForecastTableData.rejected, (state) => {
            state.licenseForecastTableData = [];
            state.licenseForecastLoading = false;
        });
    }
});

export const { setContractPagination } = contractSlice.actions;

export default contractSlice.reducer;

