import React, { useEffect, useState } from "react";
import BaseModalContainer from "../shared/baseModalContainer";
import { Grid } from "@material-ui/core";
import { getLicensesByLogin } from "../../../clientLibrary/license/license";
import { useAppSelector } from "../../../hooks";
import { LicenseProviderResponse } from "../../../clientLibrary/license/licenseInterfaces";
import TeacherLicenseTable from "../../teacher/home/teacherLicenseTable";
import { MailtoData, MailtoLink, MailtoLinkTypes } from '../../helperComponents/mailtoLink';

interface TeacherLicenseModalProps {
    modalOpen: boolean,
    handleClose: () => void,
}

export default function TeacherLicenseModal (props: TeacherLicenseModalProps) { 
    const login = useAppSelector(state => state.auth.userRole.login);
    const [licenses, setLicenses] = useState<LicenseProviderResponse[] | null>();

    const primaryContact = useAppSelector(state => state.account.customer?.primaryContact);

    useEffect(() => {
        (async () => {
            const licenses = await getLicensesByLogin(login);
            setLicenses(licenses);
        })()
    }, [login]);

    const body = (
        <Grid container spacing={1}>
            <TeacherLicenseTable licenses={licenses}/>
        </Grid>
    )

    let href: MailtoData = {
        mailTo: '',
        subject: 'Need eBook(s) for new class',
        body: ''
    }

    if (primaryContact) {
        href = {
            mailTo: primaryContact.email,
            subject: `Need eBook(s) for new class`,
            body: `Hi ${primaryContact.firstName}, \r\nI am attempting to create a new class but do not have the eBook(s) that I need. I would like to request the following eBook(s):\r\n`
        }
    }

    const reqEbookBtn = (
        <MailtoLink 
            variant="contained" 
            label="Request More Licenses"
            typographyVariant='inherit'
            type={MailtoLinkTypes.Button}
            href={href}
            className="reqEbookBtn"
            style={{height: "100%", marginLeft: "0px"}}
        />
    )

    return (
        <BaseModalContainer
            id="teacher-license"
            buttonName="Close"
            modalTitle="Licenses"
            handleClose={props.handleClose}
            modalOpen={props.modalOpen}
            handleAction={props.handleClose}
            optionalBtnComponent={reqEbookBtn}
        >
            {body}
        </BaseModalContainer>
    )
}