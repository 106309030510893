import { data_api_url } from "../../constants";
import axiosWithAuth from "../../reduxModule/axiosWithAuth";
import { GetUserInfoForAccountResponse } from "./authinterfaces";
import { UserResponse } from "../user/userInterfaces";

export const getUserInfoForAccount = async (): Promise<GetUserInfoForAccountResponse> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/account`);
    return {
        login: response.data.login,
        roles: response.data.roles,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        eWorkspaceEnabled: response.data.eWorkspaceEnabled
    };
}

export const searchUserDetailsBySearchString = async (searchStr: string): Promise<UserResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/users/search?searchStr=${searchStr}`);
    return response.data;
}