import { data_api_url } from "../../constants";
import axiosWithAuth from "../../reduxModule/axiosWithAuth";
import {
    AdminGroupSummaryReportResponse,
    LicenseAdminGroupResponse,
    LicenseConsumerResponse,
    LicenseProviderResponse,
    LicenseTypeResponse,
    LicenseUsersResponse,
    LicenseCountResponse,
    ParentLicenseAssignmentData,
    ParentLicenseTypeInfo,
    AssignedLicensesCountResponse
} from "./licenseInterfaces";

export const getAllLicenseAdminGroups = async (): Promise<LicenseAdminGroupResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/licenseAdminGroups`);
    return response.data;
}

export const getLicensesByPoolIdAndTypeName = async (poolId: number, typeName: string): Promise<LicenseUsersResponse[]> => {
    const response = await axiosWithAuth()
        .get(`${data_api_url}/ELS/api/licensePool/${poolId}/licenses/${typeName}/search?page=0&per_page=10&searchStr=`);
    return response.data;
}

export const getLicenseAdminGroupsByLogin = async (login: string): Promise<LicenseAdminGroupResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/user/${login}/licenseAdminGroups`);
    return response.data;
}

export const getLicenseAdminGroupSummaryReportById = async (adminGroupId: number): Promise<AdminGroupSummaryReportResponse[]> => {
    const response = await axiosWithAuth().get(
        `${data_api_url}/ELS/api/customer/licenseAdminGroup/${adminGroupId}/summaryReport`
    );
    return response.data;
}

export const getLicenseTypes = async (): Promise<LicenseTypeResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/licenseTypes`);
    return response.data;
}

export const getLicenseConsumersByLogin = async (login: string): Promise<LicenseConsumerResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/user/${login}/licenseConsumers`);
    return response.data;
}

export const getLicenseProvidersByLogin = async (login: string): Promise<LicenseProviderResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/licenses/${login}/provided`);
    return response.data;
}

export const getLicensesByLogin = async (login: string): Promise<LicenseProviderResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/licenses/user/${login}/url`);
    return response.data;
}

export const getLicenseCounts = async (): Promise<LicenseCountResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/contractLicenseCounts?per_page=1000`);
    return response.data;
}

export const getAssignedLicensesCount = async (customerId: string, licenseTypeName: string): Promise<AssignedLicensesCountResponse> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customerid/${customerId}/licenses/${licenseTypeName}/search?per_page=1`);
    return response.headers;
}

// parent dashboard licenses below

export const getAssignedParentLicenses = async (): Promise<LicenseProviderResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/licenses?per_page=1000`);
    return response.data;
}

export const getParentLicenseProviders = async (): Promise<LicenseConsumerResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/licenseProviders?per_page=1000`);
    return response.data;
}

export const assignLicenseToStudent = async (studentData: ParentLicenseAssignmentData): Promise<void> => {
    await axiosWithAuth().post(`${data_api_url}/ELS/api/license`, studentData);
}

export const unassignParentLicense = async (licenseId: number): Promise<void> => {
    await axiosWithAuth().delete(`${data_api_url}/ELS/api/licenses/${licenseId}`);
}

export const getLicenseTypeInformation = async (licenseTypeId: number): Promise<ParentLicenseTypeInfo> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/licenseTypes/${licenseTypeId}`);
    return response.data;
}