import { data_api_url } from "../../constants";
import axiosWithAuth from "../../reduxModule/axiosWithAuth";
import { CustomerDTO } from "./accountInterfaces";

export const getCustomerContextForAccount = async (): Promise<CustomerDTO> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/account/customercontext`);
    return response.data;
}

export const resetPasswordByLogin = async (login: string): Promise<void> => {
    await axiosWithAuth().put(`${data_api_url}/ELS/api/users/${login}/resetpassword`);
}

export const resetStudentPasswordByClassIdAndLogin = async (classId: number, login: string): Promise<void> => {
    await axiosWithAuth().put(`${data_api_url}/ELS/api/class/${classId}/student/${login}/passwordreset`);
}

export const isEmailInDb = async (email: string): Promise<boolean> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/users/email/${email}/exists`);
    return response.data;
}

export const isUsernameInDb = async (username: string): Promise<boolean> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/users/login/${username}/exists`);
    return response.data;
}

// parent dashboard

export const getParentAccountContext = async (): Promise<CustomerDTO> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/account/customercontext`);
    return response.data;
}