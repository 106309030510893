import { useCallback, useState } from "react";

interface AlertObject {
    severity: "success" | "warning" | "error" | "info" | undefined,
    message: string
}

const initialAlertState: AlertObject = {
    severity: undefined,
    message: ""
}

export const useSetAlert = () => {
    const [alert, setAlert] = useState(initialAlertState);

    const setSuccess = useCallback((message: string) => {
        setAlert({
            severity: "success",
            message
        });
    }, []);

    const setWarning = useCallback((message: string) => {
        setAlert({
            severity: "warning",
            message
        });
    }, []);

    const setError = useCallback((message: string) => {
        setAlert({
            severity: "error",
            message
        });
    }, []);

    const clear = useCallback(() => {
        setAlert({
            severity: undefined,
            message: ""
        })
    }, []);

    return [alert, setSuccess, setWarning, setError, clear] as const;
}