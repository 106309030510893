import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { ContentValue } from '../../clientLibrary/GeneralInterfaces';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 150,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus, hover, active, visited, focus-within': {
      backgroundColor: '#076d42',
      color: '#ffffff',
    },
  },
}));
interface CustomizedSelectsProps<T> {
  filterValues: ContentValue<T>[],
  handleFilterSelect: (item: ContentValue<T>) => void,
  filterVal: string
}

export default function CustomizedSelects<T extends string>(props: CustomizedSelectsProps<T>) {
  const inputStyles = useStyles();
  const [filter, setFilter] = React.useState(props.filterVal);

  const handleChange = (value: string) => {
    const foundContent = props.filterValues.find(item => item.value === value);
    if (foundContent) {
      setFilter(foundContent.value);
      props.handleFilterSelect(foundContent);
    }
  };

  useEffect(() => {
    setFilter(props.filterVal)
  }, [props.filterVal])

  return (
    <div>
      <FormControl variant="outlined">
        <Select
          value={filter}
          onChange={(e) => handleChange(e.target.value as string)}
          displayEmpty
          input={
            <InputBase classes={inputStyles} />
          }
          className="iconresfilter"
        >
          {
            props.filterValues.map((item) => {
              return <MenuItem key={item.name} value={item.value}> {item.name} </MenuItem >
            }
            )
          }
        </Select>
      </FormControl>

    </div>
  );
}
