import { Typography } from '@material-ui/core';
import React, { Component, ReactNode, ErrorInfo  } from 'react';

interface ErrorBoundaryProps {
    children: ReactNode;
  }
  
interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
}

    public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
        return (
            <div className="errorHandling">
            <h1>Oops Something went wrong !!!</h1>
            <Typography variant="subtitle2">
                Sorry, There was an Error.
                <br />
                <a href="/">Go To Home</a>
            </Typography>
            </div>
            );
        }

        return this.props.children; 
    }
}

export default ErrorBoundary;
