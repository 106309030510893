import { data_api_url } from "../../constants";
import axiosWithAuth from "../../reduxModule/axiosWithAuth";
import { DataCount } from "../GeneralInterfaces";
import {
    CreateBaseUserPayload,
    SearchUsersParams,
    UpdateUserPayload,
    UserResponse,
    ParentUserResponse
} from "./userInterfaces";

export const createFacultyUser = async (facultyData: CreateBaseUserPayload): Promise<void> => {
    await axiosWithAuth().post(`${data_api_url}/ELS/api/customer/keycloak/registerFaculty`, facultyData);
}

export const createStudentUser = async (studentData: CreateBaseUserPayload): Promise<string> => {
    const response = await axiosWithAuth().post(`${data_api_url}/ELS/api/customer/keycloak/registerStudent`, studentData);
    return response.data;
}

export const enrollStudentByLoginAndToken = async (login: string, token: string): Promise<number> => {
    const response = await axiosWithAuth().put(`${data_api_url}/ELS/api/enrollstudent/${login}/enrollmentTokens/${token}`);
    return response.status;
}

export const searchUsers = async (params: SearchUsersParams): Promise<DataCount<UserResponse>> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/users/search`, { params });
    return {
        data: response.data,
        count: parseInt(response.headers['x-pagination-totalcount'])
    }
}

export const getTotalUserCount = async (): Promise<number> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/users/search`);
    return parseInt(response.headers['x-pagination-totalcount']);
}

export const getUserInfoByLogin = async (login: string): Promise<UserResponse> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/userdetails/${login}/url`);
    return response.data;
}

export const getUserByIdentifier = async (identifier: string): Promise<{ status: number, data: UserResponse | null }> => {
    try {
        const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/user/${identifier}`);
        return {
            status: response.status,
            data: response.data
        }
    } catch (err) {
        const anyErr = err as any;
        if (anyErr.response) {
            return {
                status: anyErr.response.status,
                data: null
            }
        }
        throw err;
    }
}

export const searchStudentsForTeacher = async (login: string, searchStr: string): Promise<UserResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/users/search?teacher=${login}`, { params: { searchStr, per_page: 1000 } });
    return response.data;
}

export const updateUserInfo = async (userData: UpdateUserPayload): Promise<void> => {
    await axiosWithAuth().post(`${data_api_url}/ELS/api/customer/userdetails/url`, userData);
}

export const checkIfLoginExists = async (login: string): Promise<boolean> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/users/login/${login}/exists`);
    return response.data;
}

export const checkIfEmailExists = async (email: string): Promise<boolean> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/users/email/${email}/exists`);
    return response.data;
}

export const deleteUserByLogin = async (login: string): Promise<void> => {
    await axiosWithAuth().delete(`${data_api_url}/ELS/api/customer/userdetails/${login}/url`);
}

// parent dashboard

export const getParentStudents = async (): Promise<ParentUserResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/users/search?per_page=1000`);
    return response.data;
}

export const updateParentStudent = async (studentData: UserResponse): Promise<void> => {
    await axiosWithAuth().post(`${data_api_url}/ELS/api/customer/userdetails`, studentData);
}