import { data_api_url } from "../../constants";
import axiosWithAuth from "../../reduxModule/axiosWithAuth";
import { DataCount } from "../GeneralInterfaces";
import {
    CreateSchoolPayload,
    SchoolResponse,
    SchoolsParams,
    TeacherResponse,
    UpdateSchoolPayload,
    CreateBulkSchoolPayload
} from "./schoolInterfaces";

export const createSchool = async (payload: CreateSchoolPayload | CreateBulkSchoolPayload): Promise<void> => {
    await axiosWithAuth().post(`${data_api_url}/ELS/api/school`, payload);
}


export const findSchoolsBySearch = async (params: SchoolsParams): Promise<DataCount<SchoolResponse>> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/school/search`, { params });
    return {
        data: response.data,
        count: parseInt(response.headers['x-pagination-totalcount'])
    }
}

export const updateSchool = async (payload: UpdateSchoolPayload): Promise<void> => {
    await axiosWithAuth().put(`${data_api_url}/ELS/api/school`, payload);
}

export const deleteSchoolById = async (schoolId: number): Promise<void> => {
    await axiosWithAuth().delete(`${data_api_url}/ELS/api/school/${schoolId}`);
}

export const findTeachersBySchoolId = async (schoolId: number): Promise<TeacherResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/users/search?school=${schoolId}&schoolRole=Teacher`, { params: { searchStr: "", per_page: 1000 } });
    return response.data;
}



