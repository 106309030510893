import React from "react";
import { DistrictSerializedLicenses } from "../../../clientLibrary/license/licenseInterfaces";
import LicenseExpirationRow from "./licenseExpirationRow";
import { Grid, Paper, Typography } from "@material-ui/core";
import { NAVY_TEXT_COLOR } from "./licenseUtilizationRow";
import { ELS_PRIMARY_BLUE_COLOR } from "./utilizationReport";
import { daysRemainingUntilExpiration } from "../../../constants";
export interface ExpirationReportProps {
    expiringLicenses: DistrictSerializedLicenses[];
}

export default function ExpirationReport(props: ExpirationReportProps) {
    return (
        props.expiringLicenses.length === 0 || daysRemainingUntilExpiration < -90 ?
        <Grid container direction='column' alignItems="center" spacing={1} style={{padding: '100px 0px'}}>
            <Grid item>
                <Typography style={{color: ELS_PRIMARY_BLUE_COLOR, fontWeight: 'bold', textAlign: 'center'}} variant="h5" >You don't have any licenses that are expiring soon.</Typography>
            </Grid>
            <Grid item>
                <Typography style={{color: NAVY_TEXT_COLOR, textAlign: 'center'}} variant="subtitle1">When you do, you'll see them here.</Typography>
            </Grid>
        </Grid>
        :
        <Paper>
            <Grid style={{ backgroundColor: 'white', borderRadius: '4px' }}>
                {props.expiringLicenses.map((license: DistrictSerializedLicenses, i) => {
                    return (
                        <LicenseExpirationRow key={i}
                            licenseType={license.edition}
                            licenseName={license.licenseName}
                            licensesTotal={license.totalLicenseCount}
                            totalExpiredLicenseCount={license.totalExpiredLicenseCount}
                            expiration={license.expiration}
                            licenseUtilizationTotal={license.licenseUtilizationTotal}
                            licenseUtilizationPercentage={license.licenseUtilizationPercentage}
                        />
                    )
                })}
            </Grid>
        </Paper>
    )
}