import React from "react";
import ParentLicenses from "../licenses/index";
import { Switch, Route, Redirect } from "react-router-dom";

const Content = () => {
  return (
    <Switch>
      <Route exact path="/student-licenses/licenses" render={() => <ParentLicenses/>}/>
      <Route path="*" render={() => (<Redirect to="/student-licenses/licenses" />)} />
    </Switch>
  );
};

export default React.memo(Content);
