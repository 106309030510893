import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export type AlertType = 'success' | 'warning' | 'error' | 'info' | "";

interface SnackBarProps {
  open: boolean,
  message: string,
  type: AlertType,
  handleClose: () => void,
}

export default function SnackBar(props: SnackBarProps) {
  return (
    <div>
      <Snackbar
        open={props.open}
        autoHideDuration={null}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MuiAlert style={{ whiteSpace: "pre-line" }} onClose={props.handleClose} severity={props.type || 'info'} elevation={6} variant="filled">
          {props.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
