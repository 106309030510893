import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import { getSerializedLicenses } from '../../../reduxModule/slices/districtSlice';
import LicenseReports from './licenseReports';

export default function DistrictLicenses() { 
    const licenses = useAppSelector(state => state.district.licenses);
    const dispatch = useAppDispatch();
    
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = (async() => {
            await dispatch(getSerializedLicenses());
            setIsLoading(false);
        })
        fetchData();
    }, [dispatch]);

    return isLoading ? (
        <CircularProgress size={50} style={{padding: '200px'}}/>
    ) : (
        <LicenseReports licenses={licenses}/>
    )
}