import { data_api_url } from "../../constants";
import axiosWithAuth from "../../reduxModule/axiosWithAuth";
import { ContractDTOResponse, ContractReportResponse } from "./contractInterfaces";
import { PaginationParams, DataCount } from "../GeneralInterfaces";

export const getContractReportsById = async (contractId: number): Promise<ContractReportResponse> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/contractdetails/${contractId}`);
    return response.data;
}

export const getCustomerContracts = async (params: PaginationParams): Promise<DataCount<ContractDTOResponse>> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/contracts/search`, { params });
    return {
        data: response.data,
        count: parseInt(response.headers['x-pagination-totalcount'])
    }
}