import { getClassInfoForCsv } from "../clientLibrary/class/class";
import { 
    getFullName, 
    arrSortByProperty,
    getLicenseTotalCount,
    getLicenseNameOnlyFromLicenseTypeDescription,
    getExpirationData,
    getExpiredLicenseTotalCount
} from "../common/GeneralFunction";
import { SchoolResponse } from "../clientLibrary/school/schoolInterfaces";
import { 
    License,
    LicenseTypeResponse,
    LicenseCountResponse,
    LicenseProviderResponse,
    ParentLicenseSerialized,
    LicenseConsumerResponse, 
    DistrictSerializedLicenses
} from "../clientLibrary/license/licenseInterfaces";
import { SortedParsedLicense } from "./slices/contractSlice";
import { getAssignedLicensesCount, getLicenseTypeInformation } from "../clientLibrary/license/license";
import { UniqueIds } from "../clientLibrary/GeneralInterfaces";
import { CustomerDTO } from "../clientLibrary/account/accountInterfaces";

export const getLicenseDisplayName = (licenseName: string, licenseTypeReference: LicenseTypeResponse[]) => {
    const match = licenseTypeReference.find(val => val.name === licenseName);
    if (match) {
        const displayName = match.description.split(/ student | teacher/)[0].replace(" license for", "");
        return displayName;
    }
    return licenseName;
}

export const getSchoolIdFromName = (schools: SchoolResponse[], name: string) => {
    const school = schools.find(val => val.name.toLowerCase() === name.toLowerCase());
    return school ? school.id : 0;
}

const getAbbrAndRole = (individualRecord: License) => {
    const abbr = individualRecord.licenseTypeName.split("_")[0];
    const role = individualRecord.licenseTypeName.toLowerCase().includes("teacher") ? "teacher" : "student";
    return { abbr, role }
}

export const generateLicenseObj = (individualRecord: License, licenseTypeReference: LicenseTypeResponse[]) => {
    const { abbr, role } = getAbbrAndRole(individualRecord);
    let licenseObj = {
        ...individualRecord,
        abbr,
        role,
        licenseTypeName: individualRecord.licenseTypeName,
        licenseDisplayName: getLicenseDisplayName(individualRecord.licenseTypeName, licenseTypeReference) || "",
        licensePoolId: individualRecord.licensePoolId || 0,
        [`${role}LicensesTotal`]: individualRecord.totalLicensesAllowed,
        [`${role}LicensesUsed`]: individualRecord.usedLicenses,
        [`${role}LicensesRemaining`]: individualRecord.licensesRemaining,
    }
    return licenseObj
}

export const sortUniqueLicenseArray = (array: any[]): any[] => {
    let licenseArray: SortedParsedLicense[] = [];
    array.forEach(item => {
        let index = licenseArray.findIndex((val) => val.abbr === item.abbr);
        let exists = index !== -1;
        const licenseDataWithLicenseTypeName: SortedParsedLicense = {
            ...item,
            [`${item.role}LicenseTypeName`]: item.licenseTypeName,
        }
        if (exists) {
            licenseArray[index] = { ...licenseArray[index], ...licenseDataWithLicenseTypeName }
        } else {
            licenseArray.push(licenseDataWithLicenseTypeName);
        }
    });
    return arrSortByProperty(licenseArray, "licenseDisplayName");
}

const generateCsv = (csvBuilder: string[][], label: string) => {
    const csvString = csvBuilder
        .map(columnArray => columnArray.join(","))
        .join("%0A");

    const aTag = document.createElement("a");
    aTag.href = "data:attachment/csv," + csvString;
    aTag.target = "_Blank";
    aTag.download = `${label}Details.csv`;
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
}

export const downloadClassesCsv = async (classCount: number) => {
    const classInfoResponse = await getClassInfoForCsv(classCount);
    const csvBuilder = [[
        'Class',
        'Teacher',
        'Pin',
        'Start Date',
        'End Date',
        'School',
        'Course Name',
        'Active Students'
    ]];
    const classData = classInfoResponse.data;

    for (let i = 0; i < classData.length; i++) {
        csvBuilder.push([
            classData[i].name.replace(/,/g, " "),
            getFullName(classData[i].teacher),
            classData[i].pin,
            classData[i].startDate,
            classData[i].endDate,
            classData[i].schoolName,
            classData[i].schoolName,
            classData[i].schoolName,
        ]);
    }

    generateCsv(csvBuilder, "class");
}

export const mergeEntityArraysByProperty = (searchEntities: any[], allEntities: any[], property = "id") => {
    const searchedEntities = searchEntities.map(searchedEntity => {
        const matchingEntity = allEntities.find(val => val[property] === searchedEntity[property]);
        return {
            ...searchedEntity,
            ...matchingEntity
        }
    });
    return searchedEntities;
}


const parentLicenseBuilder = (  license: LicenseCountResponse | LicenseProviderResponse,
                                licenseProviderId: number,
                                bookImageURL: string): ParentLicenseSerialized => {
    const isAssigned = (license: LicenseCountResponse | LicenseProviderResponse): license is LicenseProviderResponse => {
        return 'user' in license;
    }
    const assigned = isAssigned(license);
    const studentEmail = assigned ? license.user.email : null;
    const studentFirstName = assigned ? license.user.firstName : null;
    const studentLastName = assigned ? license.user.lastName : null;
    const expiration = license.contract.contractExpiration;
    const licenseId = assigned ? license.id : null;
    const licenseTypeId = license.licenseType.id;
    const licenseName = getLicenseNameOnlyFromLicenseTypeDescription(license.licenseType.description);
    const edition = license.licenseType.name.toLowerCase().includes('teacher') ? 'teacher' : null;
    return {
        studentEmail,
        studentFirstName,
        studentLastName,
        edition,
        expiration,
        licenseId,
        licenseTypeId,
        licenseName,
        licenseProviderId,
        bookImageURL,
    }
}

const getLicenseBookImage = async (licenseTypeId: number, licenseTypeDescription: string): Promise<string> => {
    const licenseData = await getLicenseTypeInformation(licenseTypeId);
    let bookImageURL = '';
    
    const spanishImageURL = licenseData.books.find(book => book.description.includes('Spanish'))?.imageURL
    const englishImageURL = licenseData.books.find(book => !book.description.includes('Spanish'))?.imageURL

    if (spanishImageURL !== undefined && licenseTypeDescription.includes('Spanish')) {
        bookImageURL = spanishImageURL;
    }

    if (englishImageURL !== undefined && !licenseTypeDescription.includes('Spanish')) {
        bookImageURL = englishImageURL;
    }

    return bookImageURL;
}

export const parentLicenseSerializer = async (licenseCounts: LicenseCountResponse[],
                                        assignedLicenses: LicenseProviderResponse[],
                                        licenseProviders: LicenseConsumerResponse[]): Promise<ParentLicenseSerialized[]> => {
    const serializedLicenses: ParentLicenseSerialized[] = [];
    await Promise.all(licenseCounts.map(async (license) => {
        if (license.remaining > 0) {
            const bookImageURL = await getLicenseBookImage(license.licenseType.id, license.licenseType.description);
            let licenseProviderId = 0;
            licenseProviders.forEach((provider) => {
                if (provider.associatedGroupAllowance.licenseType.id === license.licenseType.id) {
                    licenseProviderId = provider.id
                }
            });
            for(let i=0; i < license.remaining; i++) {
                serializedLicenses.push(parentLicenseBuilder(license, licenseProviderId, bookImageURL));
            }
        }
    }))
    await Promise.all(assignedLicenses.map(async (license) => {
        const bookImageURL = await getLicenseBookImage(license.licenseType.id, license.licenseType.description);
        serializedLicenses.push(parentLicenseBuilder(license, license.licenseConsumer.id, bookImageURL));
    }))
    serializedLicenses.sort((a, b) => a.licenseName.localeCompare(b.licenseName));
    return serializedLicenses;
}

const districtLicenseBuilder = (license: LicenseCountResponse, licenses: LicenseCountResponse[], licenseUtilizationTotal?: number) => {
    const expiration = getExpirationData(license.licenseType.id, licenses);
    const licenseId = license.licenseType.id;
    const licenseName = getLicenseNameOnlyFromLicenseTypeDescription(license.licenseType.description);
    const edition = license.licenseType.name;
    const totalLicenseCount = getLicenseTotalCount(license.licenseType.id, licenses);
    const totalExpiredLicenseCount = getExpiredLicenseTotalCount(license.licenseType.id, licenses);
    let licenseUtilizationPercentage;
    if (licenseUtilizationTotal !== undefined) {
        licenseUtilizationPercentage = Math.round((licenseUtilizationTotal / totalLicenseCount) * 100);
    }
    return {
        expiration,
        licenseId,
        licenseName,
        edition,
        totalLicenseCount,
        totalExpiredLicenseCount,
        licenseUtilizationTotal: licenseUtilizationTotal || 0,
        licenseUtilizationPercentage: licenseUtilizationPercentage || 0
    }
}

const getAssignedLicensesTotal = async (customerId: string, licenseTypeName: string): Promise<number> => {
    const assignedLicensesTotal = await getAssignedLicensesCount(customerId, licenseTypeName);
    return Number(assignedLicensesTotal["x-pagination-totalcount"]);
}

export const districtLicenseSerializer = async (licenses: LicenseCountResponse[], accountContext: CustomerDTO) => { 
    const serializedLicenses: DistrictSerializedLicenses[] = [];
    let uniqueIds = {} as UniqueIds
    await Promise.all(licenses.map(async (license) => {
        const id = license.licenseType.id.toString();
        if (!(id in uniqueIds)) {
            uniqueIds[id] = true;
            const licenseUtilizationTotal = await getAssignedLicensesTotal(accountContext.customerId, license.licenseType.name)
            serializedLicenses.push(districtLicenseBuilder(license, licenses, licenseUtilizationTotal))
        }
    }))
    // Sort by license name, and then license edition
    serializedLicenses.sort((a, b) => a.licenseName.localeCompare(b.licenseName) || a.edition.localeCompare(b.edition));
    return serializedLicenses;
}

export const districtLicenseSerializerWithoutUtilizationData = (licenses: LicenseCountResponse[]) => { 
    const serializedLicenses: DistrictSerializedLicenses[] = [];
    let uniqueIds = {} as UniqueIds
    licenses.forEach((license) => {
        const id = license.licenseType.id.toString();
        if (!(id in uniqueIds)) {
            uniqueIds[id] = true;
            serializedLicenses.push(districtLicenseBuilder(license, licenses))
        }
    })
    // Sort by license name, and then license edition
    serializedLicenses.sort((a, b) => a.licenseName.localeCompare(b.licenseName) || a.edition.localeCompare(b.edition));
    return serializedLicenses;
}