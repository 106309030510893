import React, { useEffect } from "react";
import TeacherClasses from "../classes/index";
import { Switch, Route, Redirect } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getCustomerContext } from "../../../reduxModule/slices/accountSlice"
import { getUserLicenseConsumersByLogin } from "../../../reduxModule/slices/licenseSlice";
import { getAllSchools } from "../../../reduxModule/slices/schoolSlice";

const Content = () => {
  const dispatch = useAppDispatch();
  const login = useAppSelector(state => state.auth.userRole.login);

  //Get Initial Data for Teacher
  useEffect(() => {
    dispatch(getCustomerContext());
    dispatch(getAllSchools());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUserLicenseConsumersByLogin(login))
  }, [dispatch, login]);

  return (
    <div className="cardsSectionMain" style={{ display: "flex", justifyContent: "center" }}>
      <Switch>
        <Route exact path="/student-licenses/classes" render={() => <TeacherClasses />} />
        <Route path="*" render={() => <Redirect to="/student-licenses/classes" />} />
      </Switch>
    </div>
  );
};

export default React.memo(Content);
