import React from 'react';
import UploadContainer from '../../helperComponents/uploadContainer';
import { Typography } from '@material-ui/core';
import { BulkAddModalProps } from '../../../clientLibrary/GeneralInterfaces';
import { UploadType } from '../../../clientLibrary/Enums';
import { makeCsvArrayConverter } from '../../../common/GeneralFunction';
import { BulkUserData } from '../../../reduxModule/slices/userSlice';
import { useAppSelector } from '../../../hooks';

interface UserTableData {
  userType: string,
  school: string,
  firstName: string,
  lastName: string,
  email: string,
  username?: string
}

const userTablePropertyMap: Record<string, keyof UserTableData> = {
  "User Type": "userType",
  "School": "school",
  "First Name": "firstName",
  "Last Name": "lastName",
  "Email": "email",
  "Username": "username"
}

export const csvArrayToBulkUserData = makeCsvArrayConverter<BulkUserData>(userTablePropertyMap);

const tableHead = ['User Type', 'School', 'First Name', 'Last Name', 'Email']

export default function BulkAddUsersModal(props: BulkAddModalProps) {
  const schools = useAppSelector(state => state.school.schools);
  
  const uploadNote = (
    <Typography variant="caption">
      User Type Column should contain either "Student" or "Teacher".
      <br />
      Schools should already be added to the system.
      <br />
      Usernames will be set to the user's email.
      <br />
      Temporary passwords will be set to the user's username. Users should login to update to a new password.
    </Typography>
  );

  return (
    <UploadContainer
      id="upload-users"
      modalTitle="Upload Users"
      uploadNote={uploadNote}
      type={UploadType.User}
      handleClose={props.handleClose}
      modalOpen={props.modalOpen}
      handleSubmit={props.handleSubmit}
      tableHead={tableHead}
      schools={schools}
    >
    </UploadContainer>
  );
}
