import {
    Grid,
    makeStyles,
    TableCell,
    TableRow,
} from "@material-ui/core";
import React, { Dispatch, SetStateAction } from "react";
import { CurrentPast } from ".";
import { UserResponse } from "../../../clientLibrary/user/userInterfaces";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getEnrolledStudentsByClassId, removeStudentFromClass } from "../../../reduxModule/slices/userSlice";
import { resetStudentPassword } from "../../../reduxModule/slices/accountSlice";
import { getUserLicenseConsumersByLogin } from "../../../reduxModule/slices/licenseSlice";
import EllipsisMenu, { MenuOptions } from "../../helperComponents/ellipsisMenu";

interface StudentRowProps {
    student: UserResponse,
    setStudentData: Dispatch<SetStateAction<UserResponse | null>>,
    filterValue: CurrentPast,
    classId: number,
    isRostered: boolean,
    highlight?: boolean,
    setTransferModalOpen: Dispatch<SetStateAction<boolean>>
}

export const useRowStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    firstNameColumn: {
        [theme.breakpoints.down("xs")]: {
            display: "none"
        },
    },
}));

const useStyles = makeStyles({
    "@keyframes highlightGreenThenFade": {
        "0%": {
            backgroundColor: "#05803c3b"
        },
        "40%": {
            backgroundColor: "#05803c3b"
        },
        "100%": {
            backgroundColor: "inherit"
        }
    },
    zeroPadding: {
        padding: 0
    },
    "greenHighlight": {
        animation: "$highlightGreenThenFade 7s"
    },
});

export const StudentRow = (props: StudentRowProps) => {
    const { student, classId, isRostered } = props;
    const login = useAppSelector(state => state.auth.userRole.login);

    const dispatch = useAppDispatch()
    const classes = useStyles();

    const rowClasses = useRowStyles();

    const resetPassword = async () => {
        await dispatch(resetStudentPassword({ classId, login: student.login }));
    };

    const removeStudent = async () => {
        await dispatch(removeStudentFromClass({ classId, login: student.login }));
        await dispatch(getEnrolledStudentsByClassId(classId));
        dispatch(getUserLicenseConsumersByLogin(login));
    }

    const transferStudent = () => {
        props.setStudentData(student)
        props.setTransferModalOpen(true);
    }

    const EllipsisMenuProps: MenuOptions[] = [
        {
            color: "primary",
            handler: resetPassword,
            name: "Reset Password"
        },
        {
            color: "inherit",
            handler: transferStudent,
            name: "Transfer Student"
        },
        {
            color: "secondary",
            handler: removeStudent,
            name: "Remove Student"
        }
    ]

    return (
        <TableRow
            key={student.login}
            className={`${rowClasses.root} ${props.highlight && classes.greenHighlight}`}
        >
            <TableCell component="th" scope="row">
                {student.login}
            </TableCell>
            <TableCell className={rowClasses.firstNameColumn}>
                {student.firstName}
            </TableCell>
            <TableCell>
                {student.lastName}
            </TableCell>
            <TableCell align="right" className={classes.zeroPadding}>
                <Grid container direction="column" justify="space-between">
                    {
                        !isRostered && props.filterValue === "current" &&
                        (<Grid item>
                            <EllipsisMenu menuOptions={EllipsisMenuProps}/>
                        </Grid>
                        )
                    }
                </Grid>
            </TableCell>
        </TableRow >
    )
}