import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';

const UnderConstruction = () => {

    return (
        <Grid container spacing={2} justify="center" alignContent='center'>
            <Grid item>
                    <Typography variant="h5">
                        Currently this page is only available to active District Admins, Teachers, and Parents with purchased materials. If you believe you are seeing this page in error, please contact your account administrator.
                    </Typography>
            </Grid>
            <Grid item>
                <Button variant="outlined" color="primary" href={ process.env.NODE_ENV === "production" ? "https://my.cpm.org" : "https://my-test.cpm.org"}>
                    Back to Home
                </Button>
            </Grid>
        </Grid>
    );
}

export default UnderConstruction;