import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerDTO } from "../../clientLibrary/account/accountInterfaces";
import { DisplayCategory } from "../../clientLibrary/GeneralInterfaces";
import { createSchool, deleteSchoolById, findSchoolsBySearch, findTeachersBySchoolId, updateSchool } from "../../clientLibrary/school/school";
import { CreateBulkSchoolPayload, CreateSchoolPayload, SchoolResponse, SchoolsParams, TeacherResponse, UpdateSchoolPayload } from "../../clientLibrary/school/schoolInterfaces";
import { updateSnackbarMessageUsingResults } from "../../common/GeneralFunction";
import { mergeEntityArraysByProperty } from "../UtilityFunctions";
import { setBackdrop, updateSnackbarError, updateSnackbarSuccess } from "./utilitySlice";

export interface SchoolState {
    pagination: SchoolPagination,
    schools: SchoolResponse[],
    schoolCount: number,
    searchedSchools: SchoolResponse[],
    searchedSchoolCount: number,
    teachersForSchool: TeacherResponse[]
}

interface SchoolPagination {
    searchStr: string,
    page: number,
    per_page: number,
    sort: string,
    sort_reverse: boolean,
}

interface AddBulkSchoolsPayload {
    bulkSchoolData: BulkSchoolData[],
    customer: CustomerDTO
}

interface DeleteBulkSchoolsData {
    id: number,
    name: string
}
export interface BulkSchoolData {
    address: string,
    city: string,
    school: string,
    state: string,
    zipcode: string | number
}

const initialState: SchoolState = {
    pagination: { searchStr: "", page: 0, per_page: 25, sort: "", sort_reverse: false },
    schools: [],
    schoolCount: 0,
    searchedSchools: [],
    searchedSchoolCount: 0,
    teachersForSchool: []
}

export const createNewSchool = createAsyncThunk(
    'school/createNewSchool',
    async (payload: CreateSchoolPayload, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await createSchool(payload);
            thunkAPI.dispatch(updateSnackbarSuccess('New school created successfully.'));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Create school failed.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const getAllSchools = createAsyncThunk(
    'school/getAllSchools',
    async (_, thunkAPI) => {
        try {
            const params = {
                searchStr: "",
                per_page: 1000,
                page: 0,
                sort: "",
                sort_reverse: false
            }
            const schoolsResponse = await findSchoolsBySearch(params)
            return schoolsResponse;
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to fetch school data.'));
            throw error;
        }
    }
);

export const searchSchools = createAsyncThunk(
    'school/searchSchools',
    async (params: SchoolsParams, thunkAPI) => {
        try {
            const schoolsResponse = await findSchoolsBySearch(params);
            return schoolsResponse;
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to fetch school data.'));
            throw error;
        }
    }
);

export const editSchoolDetails = createAsyncThunk(
    'school/editSchoolDetails',
    async (payload: UpdateSchoolPayload, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await updateSchool(payload);
            thunkAPI.dispatch(updateSnackbarSuccess('School updated successfully'));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            console.log(error);
            thunkAPI.dispatch(updateSnackbarError('Failed to edit school data.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const deleteSchool = createAsyncThunk(
    'school/deleteSchool',
    async (schoolId: number, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await deleteSchoolById(schoolId);
            thunkAPI.dispatch(updateSnackbarSuccess(`The school was successfully deleted.`));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            console.log(error);
            thunkAPI.dispatch((updateSnackbarError(`Failed to delete the school.`)));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const findTeachersBySchool = createAsyncThunk(
    'school/findTeachersBySchool',
    async (schoolId: number, thunkAPI) => {
        try {
            const teachersResponseData = await findTeachersBySchoolId(schoolId);
            return teachersResponseData;
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to find teachers for this school.'));
            throw error;
        }
    }
);

export const addBulkSchools = createAsyncThunk(
    'school/addBulkSchools',
    async (payload: AddBulkSchoolsPayload, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        const successfullyAddedSchools: string[] = [];
        const failedToAddSchools: string[] = [];

        for (let schoolToAdd of payload.bulkSchoolData) {
            const populatedSchoolInfo: CreateBulkSchoolPayload = {
                name: schoolToAdd.school,
                description: `${schoolToAdd.school} : ${payload.customer.name} : ${schoolToAdd.state} : ${schoolToAdd.city}`,
                address: schoolToAdd.address,
                city: schoolToAdd.city,
                state: schoolToAdd.state,
                zipcode: schoolToAdd.zipcode,
                customer: payload.customer
            }

            try {
                await createSchool(populatedSchoolInfo);
                successfullyAddedSchools.push(populatedSchoolInfo.name);
            } catch (error) {
                console.log(error);
                failedToAddSchools.push(populatedSchoolInfo.name);
            }
        }
        thunkAPI.dispatch(setBackdrop(false));

        const displayCategoryArr: DisplayCategory[] = [
            {
                arrayType: 'success',
                entities: successfullyAddedSchools,
                message: ` school(s) successfully added. `
            },
            {
                arrayType: 'failure',
                entities: failedToAddSchools,
                message: ` school(s) failed to add.  Please check your file or add the school(s) individually using the "+ New School" button `
            }
        ];

        thunkAPI.dispatch(updateSnackbarMessageUsingResults(displayCategoryArr));
    });

export const deleteBulkSchools = createAsyncThunk(
    'school/deleteBulkSchools',
    async (schoolPayload: DeleteBulkSchoolsData[], thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        const successfullyDeletedSchools: string[] = [];
        const failedToDeleteSchools: string[] = [];
        for (let schoolInfo of schoolPayload) {
            try {
                await deleteSchoolById(schoolInfo.id);
                successfullyDeletedSchools.push(schoolInfo.name);
            } catch (error) {
                console.log(error);
                failedToDeleteSchools.push(schoolInfo.name);
            }
        }

        thunkAPI.dispatch(setBackdrop(false));

        const displayCategoryArr: DisplayCategory[] = [
            {
                arrayType: 'success',
                entities: successfullyDeletedSchools,
                message: ` schools successfully deleted. `
            },
            {
                arrayType: 'failure',
                entities: failedToDeleteSchools,
                message: ` schools failed to delete.  Please try deleting them individually. `
            }
        ];

        thunkAPI.dispatch(updateSnackbarMessageUsingResults(displayCategoryArr));
    }
)

export const schoolSlice = createSlice({
    name: 'school',
    initialState,
    reducers: {
        setSchoolPagination: (state, action: PayloadAction<Partial<SchoolPagination>>) => {
            state.pagination = { ...state.pagination, ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAllSchools.fulfilled, (state, action) => {
            if (!state.searchedSchools || state.searchedSchools.length <= 0) {
                state.searchedSchools = action.payload.data;
                state.searchedSchoolCount = action.payload.count;
            }
            state.schools = action.payload.data;
            state.schoolCount = action.payload.count;
        })
        .addCase(getAllSchools.rejected, (state) => {
            state.schools = [];
            state.schoolCount = 0;
            state.searchedSchools = [];
            state.searchedSchoolCount = 0;
        })
        .addCase(searchSchools.fulfilled, (state, action) => {
            const searchedSchoolsData: SchoolResponse[] = mergeEntityArraysByProperty(action.payload.data, state.schools);
            state.searchedSchools = searchedSchoolsData;
            state.searchedSchoolCount = action.payload.count || state.searchedSchoolCount
        })
        .addCase(searchSchools.rejected, (state) => {
            state.searchedSchools = [];
            state.searchedSchoolCount = 0;
        })
        .addCase(findTeachersBySchool.fulfilled, (state, action) => {
            state.teachersForSchool = action.payload;
        })
        .addCase(findTeachersBySchool.rejected, (state) => {
            state.teachersForSchool = [];
        });
    }
});

export const { setSchoolPagination } = schoolSlice.actions;

export default schoolSlice.reducer;