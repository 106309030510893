import { differenceInDays } from "date-fns";

declare global {
    interface Window {
        REACT_APP_SSO_API_URL: string;
        REACT_APP_DATA_API_URL: string;
        REACT_APP_BASE_URL: string;
        REACT_APP_LOGIN_REDIRECT_URL: string;
        REACT_APP_LOGIN_REDIRECT_URLN: string;
        REACT_APP_NODE_ENV: string;
    }
}
export const sso_api_url = process.env.REACT_APP_SSO_API_URL || window.REACT_APP_SSO_API_URL;
export const data_api_url = process.env.REACT_APP_DATA_API_URL || window.REACT_APP_DATA_API_URL;
export const base_url = process.env.REACT_APP_BASE_URL || window.REACT_APP_BASE_URL;
export const login_redirect_url = process.env.REACT_APP_LOGIN_REDIRECT_URL || window.REACT_APP_LOGIN_REDIRECT_URL;
export const login_redirect_urlN = process.env.REACT_APP_LOGIN_REDIRECT_URLN || window.REACT_APP_LOGIN_REDIRECT_URLN;
//node_env is unreliable, more testing required before further use.
export const node_env = process.env.REACT_APP_NODE_ENV || window.REACT_APP_NODE_ENV;

export const tutorial_video_link_youtube = "https://www.youtube.com/embed/3SOBIzMZnN4";
export const tutorial_video_link_vimeo = "https://player.vimeo.com/video/716795783?h=b197b0e530&badge=0&autopause=0&player_id=0&app_id=58479";

export const ACTION_BUTTON_FONT_SIZE = '0.875rem';

export const date = new Date();
export const currentYear = date.getUTCFullYear().toString();
// Licenses expire on June 30th, at the end of the academic year
export const expirationDate = new Date(Number(currentYear), 5, 30);
export const daysRemainingUntilExpiration = differenceInDays(expirationDate, date);