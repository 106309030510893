import React from 'react';
import { Paper, useMediaQuery } from '@material-ui/core';
import { DistrictSerializedLicenses } from '../../../clientLibrary/license/licenseInterfaces';
import LicenseUtilizationRow from './licenseUtilizationRow';

interface LicenseReportColumns {
    licenses: DistrictSerializedLicenses[];
}

export default function LicenseUtilizationColumn(props: LicenseReportColumns) {           
    const mobile = useMediaQuery('(max-width: 600px)');

    return (
        <Paper style={ mobile ? {borderWidth: '0px'} : { backgroundColor: 'white', border: 'solid white', borderWidth: '15px 30px'}}>
            {props.licenses.map((license: DistrictSerializedLicenses, i) => {
                return (
                    <LicenseUtilizationRow key={i}
                        licenseType={license.edition}
                        licenseName={license.licenseName}
                        licensesTotal={license.totalLicenseCount}
                        totalExpiredLicenseCount={license.totalExpiredLicenseCount}
                        expiration={license.expiration}
                        licenseUtilizationTotal={license.licenseUtilizationTotal}
                        licenseUtilizationPercentage={license.licenseUtilizationPercentage}
                    />
                )
            })}
        </Paper>
    )
}
