import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCustomerContextForAccount } from "../../clientLibrary/account/account";
import { getLicenseCounts } from "../../clientLibrary/license/license";
import { DistrictSerializedLicenses } from "../../clientLibrary/license/licenseInterfaces";
import { districtLicenseSerializer, districtLicenseSerializerWithoutUtilizationData } from "../UtilityFunctions";

export interface DistrictState {
    licenses: Array<DistrictSerializedLicenses>
};

const initialState: DistrictState = {
    licenses: []
};

export const getSerializedLicenses = createAsyncThunk(
    'district/getSerializedLicenses',
    async (_: void, thunkAPI) => {
        try {
            const [licenses, accountContext] = await Promise.all([thunkAPI.dispatch(getUnserializedLicenses()).unwrap(), getCustomerContextForAccount()]);
            return districtLicenseSerializer(licenses, accountContext);
        } catch (error) {
            throw error;
        }
    }
);

const getUnserializedLicenses = createAsyncThunk(
    'district/getUnserializedLicenses',
    async () => {
        try {
            const licenses = await getLicenseCounts();
            return licenses
        } catch (error) {
            throw error
        }
    }
);

export const districtSlice = createSlice({
    name: 'district',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getSerializedLicenses.fulfilled, (state, action) => {
            state.licenses = action.payload;
        })
        .addCase(getSerializedLicenses.rejected, (state) => {
            state.licenses = [];
        })
        .addCase(getUnserializedLicenses.fulfilled, (state, action) => {
            state.licenses = districtLicenseSerializerWithoutUtilizationData(action.payload);
        })
        .addCase(getUnserializedLicenses.rejected, (state) => {
            state.licenses = [];
        })
    }
});

export default districtSlice.reducer;