import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import logo from "../../../assets/images/CPM_logo.png";
import Tooltip from '@material-ui/core/Tooltip';
import UserMenu from "../../helperComponents/userMenu";
import Link from '@material-ui/core/Link';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { getUserRole } from "../../../reduxModule/slices/authSlice";
import { sso_api_url } from "../../../constants";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { isCPMAdmin, isDistrictAdmin, isParent, isTeacher } from "../../../common/AuthFunction";
import ImpersonateUser from "../../helperComponents/impersonateUser";
import * as Impersonate from "../../../common/Impersonate";
import '../../teacher/teacher.scss';
import { makeStyles } from "@material-ui/core";
import { node_env } from "../../../constants";

const useStyles = makeStyles((theme) => ({
    headerTitle: {
        fontSize: "1.5rem",
        color: '#233569',
        textShadow: '0px 0px 1px black',
        fontWeight: 'bold',
        padding: '10px',
        marginLeft: '15px',
        [theme.breakpoints.down(740)]: {
            display: "none"
        }
    },
}));

export type Roles = "CPM Admin" | "District Admin" | "Parent" | "Teacher" | "";

const Header = () => {
  const [userRole, setUserRole] = useState<Roles>('');
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const roles = useAppSelector(state => state.auth.userRole.roles);

  const handleLogout = () => {
    Impersonate.removeLogin();
    window.location.href = `${sso_api_url}/logout`;
  };

  useEffect(() => {
    if(isTeacher(roles)){
      setUserRole('Teacher');
    } 
    
    if(isDistrictAdmin(roles)){
      setUserRole('District Admin');
    } 

    if(isParent(roles)){
      setUserRole('Parent');
    }

    if(isCPMAdmin(roles)){
      setUserRole('CPM Admin');
    }
  }, [roles])

  const handleUnimpersonate = () => {
    Impersonate.removeLogin();
    dispatch(getUserRole());
  }

  console.log("Current environment: ", node_env);

  return (
    <header className="w-100">
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className="headerMainGrid"
      >
        <Grid item xs={7} className="logoGridSection">
          <Tooltip title="Logo" aria-label="CPM Logo">
          <Link href={ node_env === "production" ? "https://my.cpm.org" : "https://my-test.cpm.org"}>
              <img src={logo} alt="CPM Logo" className="logoImage" />
          </Link>
          </Tooltip>
          <div>
            <Typography role='heading' aria-level={1} variant="inherit" className={classes.headerTitle}>
            License Manager
            </Typography>
          </div>
        </Grid>
        <Grid item xs={2} container justify="flex-end">
          { userRole === 'District Admin' && <Button color="inherit" style={{display:"none"}}><SettingsApplicationsIcon fontSize="small" /> &nbsp;Integration</Button> }
          { userRole === 'CPM Admin' &&  !Impersonate.isImpersonating() && <ImpersonateUser />}
          { 
            Impersonate.isImpersonating() &&
              <Button onClick={handleUnimpersonate}
                      variant="contained"
                      color="primary"
              >
                Unimpersonate {Impersonate.getLogin()}
              </Button>
          }
        </Grid>
        <Grid
          item
          xs={3}
          className="userProfileGrid d-flex align-items-center w-100"
        >
          <div className="userProfileSection d-flex align-items-center">
            <Typography
              variant="caption"
              display="block"
              gutterBottom
            style={{color: '#05803c', fontWeight: 'bold', margin: '2px', fontSize: '18px'}}
            >
              {userRole}
            </Typography>
            <UserMenu role={userRole} logoutAction={() => handleLogout()}/>
          </div>
        </Grid>
      </Grid>
    </header>
  );
};
export default Header;
