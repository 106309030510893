import React, { useState } from "react";
import {
    Popover,
    IconButton,
    Grid,
    Button
} from "@material-ui/core";
import { MoreVert as EllipsisIcon } from "@material-ui/icons";

export interface MenuOptions {
    color: "inherit" | "default" | "primary" | "secondary",
    name: string,
    handler: () => void
}

interface EllipsisMenuProps {
    menuOptions: MenuOptions[]
};

const EllipsisMenu = (props: EllipsisMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    
    const handleActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const open = Boolean(anchorEl);

    const menuOptions = props.menuOptions.map((option) => {

        const onMenuOptionClick = () => {
            option.handler();
            setAnchorEl(null);
        }

        return (
            <Grid item key={option.name}>
                <Button style={{width: '100%', justifyContent: 'flex-start'}} color={option.color} onClick={onMenuOptionClick}>{option.name}</Button>
            </Grid>
        );
    });

    return (
        <>
            <IconButton onClick={handleActionsClick}>
                <EllipsisIcon />
            </IconButton>
            <Popover
                id="actions-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: -29
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                <Grid container direction="column">
                    {menuOptions}
                </Grid>
            </Popover>
        </>
    )
}

export default EllipsisMenu;