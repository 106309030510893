import React from 'react';
import {
    Typography,
    LinearProgress,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    makeStyles,
    useMediaQuery
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons"
import { ELS_PRIMARY_BLUE_COLOR, GRAY_BACKGROUND_COLOR } from './utilizationReport';
import LicenseForecastTable from './licenseForecastTable';
import { ExpirationData } from '../../../clientLibrary/GeneralInterfaces';
import LicenseTitleTooltip from './licenseTitleTooltip';
import LicenseForecastTableMobile from './licenseForecastTableMobile';
export interface LicenseDataProps {
    licenseName: string,
    licenseType: string,
    licensesTotal: number,
    totalExpiredLicenseCount: number,
    expiration: ExpirationData ,
    licenseUtilizationTotal: number,
    licenseUtilizationPercentage: number
}

export const NAVY_TEXT_COLOR = '#233569';
const ALERT_COLOR = '#f44336';

const useStyles = makeStyles(() => ({
    accordion: {
        width: '100%',
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_BACKGROUND_COLOR}`,
        backgroundColor: 'transparent',
        '&:last-child': { borderBottom: 'none' },
        '&::before': { backgroundColor: 'transparent' },
        '&:hover': {
            borderBottom: `1px solid ${ELS_PRIMARY_BLUE_COLOR}`
        },
        '.Mui-expanded&:hover': {
            borderBottom: `1px solid ${GRAY_BACKGROUND_COLOR}`,
        },
        '.Mui-expanded&:last-child&:hover': { borderBottom: 'none' }
    },
    accordionSummaryRoot: {
        padding: '0px 16px 0px 0px',
        '&:hover .MuiAccordionSummary-expandIcon': {
            backgroundColor: ELS_PRIMARY_BLUE_COLOR,
            color: 'white'
        }
    },
    accordionSummaryContent: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        overflow: 'hidden'
    },
    accordionSummaryExpandIcon: {
        transform: 'rotate(270deg)',
        padding: '0px'
    },
    rowText: {
        color: NAVY_TEXT_COLOR
    },
    alertFlag: {
        color: ALERT_COLOR
    }
}))

export default function LicenseUtilizationRow(props: LicenseDataProps) {
    const classes = useStyles();
    const mobile = useMediaQuery('(max-width: 600px)');

    return (
        <>
            {props.licensesTotal !== 0 && (
                <Accordion className={classes.accordion} square={true}>
                        <AccordionSummary
                            classes={{root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent, expandIcon: classes.accordionSummaryExpandIcon}}
                            id='panel1-header'
                            aria-controls='panel1-content'
                            expandIcon={<ExpandMore fontSize='default'/>}
                            >
                                <Grid 
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="flex-start"
                                    style={{overflow: 'hidden'}}
                                    >
                                    <LicenseTitleTooltip licenseName={props.licenseName} />
                                    <LinearProgress style={{ width: "100%" }} color={props.licenseUtilizationPercentage > 90 ? "secondary" : "primary"} variant='determinate' value={props.licenseUtilizationPercentage} />
                                    <Grid container justify="space-between">
                                        <Typography className={classes.rowText} variant="subtitle1" align="left">{props.licenseUtilizationTotal} / {props.licensesTotal}</Typography>
                                        <Typography className={classes.rowText} variant="subtitle1" align="right">{props.licenseUtilizationPercentage}%</Typography>
                                    </Grid>
                                </Grid>
                        </AccordionSummary>
                        <AccordionDetails style={{paddingBottom: '20px'}}>
                            <div style={{width: '100%'}}>
                                {mobile ?
                                <LicenseForecastTableMobile
                                    expirationData={props.expiration} 
                                    licensesCount={props.licensesTotal}
                                />
                                :
                                <LicenseForecastTable 
                                    expirationData={props.expiration} 
                                    licensesCount={props.licensesTotal}
                                />}
                            </div>
                        </AccordionDetails>
                </Accordion>
            )}
        </>
    )
}