import React, { useCallback, useState } from 'react';
import BaseModalContainer from "../modals/shared/baseModalContainer";
import {
    Grid,
    Button,
    LinearProgress,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    makeStyles
} from '@material-ui/core';
import { CSVReader, CSVDownloader } from 'react-papaparse'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
    bulkDataValidation,
    isFileCsvOrExcel,
    usernameColumnExists,
    validateBulkClasses,
    validateBulkUsers
} from '../../common/Validator';
import { ParseResult } from 'papaparse';
import { UploadType } from '../../clientLibrary/Enums';
import { csvArrayToBulkUserData } from '../modals/district/bulkAddUserModal';
import { csvArrayToBulkClassData } from '../modals/district/bulkAddClassModal';
import { LicenseUtilization } from '../../clientLibrary/class/classInterfaces';
import { useAppSelector } from '../../hooks';
import { useSetAlert } from '../../common/Alerts';
import { Alert } from '@material-ui/lab';
import { MailtoLink, MailtoLinkTypes } from './mailtoLink';
import { AvailableLicenses, generateAvailableLicenseCount } from './availableLicenses';
import { checkForEnoughLicensesByLicenseNames, generateNotEnoughLicensesHrefObj } from '../../common/LicenseValidation';
import { SchoolResponse } from '../../clientLibrary/school/schoolInterfaces';

const useStyles = makeStyles({
    green: {
        color: '#05803c'
    },
    red: {
        color: '#f26666'
    },
    tableHeadBackgroundColor: {
        backgroundColor: '#1884DF',
    },
    downloadTemplateBtn: {
        textTransform: "capitalize",
        color: "#1884df",
        fontSize: "20px",
        '&:hover': {
            color: "#095da3",
            textDecoration: "underline",
            backgroundColor: "#afdbff00"
        },
    }
});

interface UploadModalContainerProps {
    id: string,
    type?: UploadType,
    tableHead: string[],
    handleSubmit(bulkData: ParseResult<string>[]): void,
    handleClose(): void,
    modalTitle: string,
    modalOpen: boolean,
    children: React.ReactNode,
    uploadNote?: React.ReactNode,
    buttonReferenceGroup?: React.ReactNode,
    licenseUtilization?: LicenseUtilization[],
    entityName?: string,
    schools?: SchoolResponse[]
}

export default function UploadModalContainer(props: UploadModalContainerProps) {
    const { tableHead } = props;

    const [bulkData, setBulkData] = useState<ParseResult<string>[]>([]);
    const [isUploadDisabled, setIsUploadDisabled] = useState(true);
    const [isCheckingValidation, setIsCheckingValidation] = useState(false);

    // @ts-ignore
    // eslint-disable-next-line
    const [alert, setSuccessAlert, setWarningAlert, setErrorAlert, clearAlert] = useSetAlert();
    const [licenseLink, setLicenseLink] = useState<JSX.Element | null>();

    const availableLicenses = useAppSelector(state => state.license.licenseConsumers);
    const primaryContact = useAppSelector(state => state.account.customer?.primaryContact);

    const classes = useStyles();

    const handleClick = () => {
        props.handleSubmit(bulkData);
        closeModal();
    }

    const clearValidation = useCallback((data: ParseResult<string>[]) => {
        setSuccessAlert('The file is ready to submit.');
        setIsUploadDisabled(false);
        setBulkData(data);
    }, [setSuccessAlert]);

    const processBulkLicenseChecking = useCallback((data: ParseResult<string>[] | null = null) => {
        let studentCount = 999;

        if (data) studentCount = data.length - 1;
        if (props.entityName && props.licenseUtilization && primaryContact && props.modalOpen) {
            let checkedLicenseObj = checkForEnoughLicensesByLicenseNames(availableLicenses, props.licenseUtilization, studentCount);
            if(checkedLicenseObj.bookList){
                let currentLicenseCount = 0;
                if (props.licenseUtilization) {
                    currentLicenseCount = (generateAvailableLicenseCount(props.licenseUtilization, availableLicenses));
                }
                checkedLicenseObj = {
                    ...checkedLicenseObj,
                    mailtoData: generateNotEnoughLicensesHrefObj(primaryContact, props.entityName, checkedLicenseObj.bookList, studentCount - currentLicenseCount)
                }
            }
            const {okToEnroll, message, mailtoData} = checkedLicenseObj;
            if (okToEnroll === false && message && mailtoData) {
                setWarningAlert(message);
                setLicenseLink(<MailtoLink label={"Request More"} type={MailtoLinkTypes.Text} href={mailtoData} />);
            } else {
                setLicenseLink(null);
                if (data) clearValidation(data);
            }
        }
    }, [availableLicenses, primaryContact, props.entityName, props.licenseUtilization, setWarningAlert, props.modalOpen, clearValidation]);

    const tableHeadWithUsername = ['User Type', 'School', 'First Name', 'Last Name', 'Email', 'Username']

    const handleOnDrop = async (data: ParseResult<string>[], e: File) => {
        setIsUploadDisabled(true);
        setIsCheckingValidation(true);

        let validationErrorMessage;
        if (!usernameColumnExists(data)) {
            validationErrorMessage = bulkDataValidation(data, tableHead);
        } else {
            validationErrorMessage = bulkDataValidation(data, tableHeadWithUsername)
        }

        if (!isFileCsvOrExcel([e.type])) {
            setErrorAlert('Please upload a CSV file.');
        } else if (validationErrorMessage && data.length > 0) {
            setErrorAlert(validationErrorMessage);
        } else if (props.type === UploadType.User || props.type === UploadType.Class) {
            if (props.type === UploadType.User) {
                const hasEmailOrUsernameErrorMessage = await validateBulkUsers(csvArrayToBulkUserData(data), props.schools);
                if (hasEmailOrUsernameErrorMessage) {
                    setErrorAlert(hasEmailOrUsernameErrorMessage);
                } else {
                    clearValidation(data);
                }
            }
            if (props.type === UploadType.Class) {
                const bulkClassesErrorMessage = await validateBulkClasses(csvArrayToBulkClassData(data), availableLicenses, props.schools);
                if (bulkClassesErrorMessage) {
                    setErrorAlert(bulkClassesErrorMessage);
                } else {
                    clearValidation(data);
                }
            }
            if (props.licenseUtilization) {
                processBulkLicenseChecking(data);
            }
        } else {
            clearValidation(data);
        }
        setIsCheckingValidation(false);
    }

    const handleOnRemoveFile = () => {
        setIsUploadDisabled(true);
        setBulkData([]);
        clearAlert();
    }

    const closeModal = () => {
        handleOnRemoveFile();
        clearAlert();
        props.handleClose();
    }

    const body = (
        <Grid item container alignContent='space-between' direction="column" xs={12}>
            <Grid item container spacing={1} justify="space-between" style={{marginTop: "5px"}}>
                <Grid item style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Typography variant="h6" color="textPrimary">Instructions</Typography>
                </Grid>
                <Grid item>
                    <CSVDownloader
                        data={ [props.tableHead] }
                        filename={ props.modalTitle }
                    >
                        <Button
                            variant="text"
                            className={classes.downloadTemplateBtn}
                        >
                            Download Template
                        </Button>
                    </CSVDownloader>
                </Grid>
                <Grid item>
                    <Typography variant="body1">Please choose a .csv file with header names in same order as in the table:</Typography>
                </Grid>
                <Grid item container direction="column">
                    <Grid item>
                        <TableContainer component={Paper}>
                            <Table className="uploadtable">
                                <TableHead className={classes.tableHeadBackgroundColor}>
                                    <TableRow>
                                        {props.tableHead.map(head => (
                                            <TableCell key={`${head}_header`} className="uploadsampletable" style={{color: "white"}}>{head}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {props.tableHead.map(head => (
                                            <TableCell key={`${head}_placeholder_row_1`} className="uploadsampletable"></TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        {props.tableHead.map(head => (
                                            <TableCell key={`${head}_placeholder_row_2`} className="uploadsampletable"></TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item>
                        {props.uploadNote}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container direction="column" spacing={1} style={{ marginTop: "25px" }}>
                {
                props.licenseUtilization && (
                    <AvailableLicenses 
                    licenseUtilization={props.licenseUtilization}
                    teacherClassName={props.entityName}
                    />
                )
                }
                <Grid item style={{ maxHeight: 110 }}>
                    <CSVReader
                        onDrop={handleOnDrop}
                        addRemoveButton
                        onRemoveFile={handleOnRemoveFile}
                        config={
                            {
                                skipEmptyLines: true,
                            }
                        }
                        style={{
                            dropArea: {
                                borderRadius: 20,
                            },
                            dropAreaActive: {
                                borderColor: 'red',
                            },
                            dropFile: {
                                width: '50%',
                                height: 'auto',
                                padding: 10,
                                background: 'linear-gradient(rgb(238, 238, 238), rgb(221, 221, 221))',
                            },
                            fileSizeInfo: {
                                color: '#000000',
                                backgroundColor: 'transparent',
                                marginBottom: '0.5em',
                            },
                            fileNameInfo: {
                                color: '#000000',
                                backgroundColor: 'transparent',
                                lineHeight: 1,
                                overflowWrap: "anywhere",
                            },
                        }}
                    >
                        <span>Drop CSV file here or click to upload.</span>
                        <CloudUploadIcon fontSize='large'/>
                    </CSVReader>
                </Grid>
                <Grid item>
                    {
                        isCheckingValidation && (
                            <>
                                <Alert variant="outlined" severity="info">
                                    Checking file for any issues.
                                </Alert>
                                <LinearProgress />
                            </>
                        )
                    }
                    {
                        alert.severity && (
                            <Alert variant="outlined" severity={alert.severity} style={{ whiteSpace: "pre-line", alignItems: "center" }}>
                                {alert.message}
                                {licenseLink}
                            </Alert>
                        )
                    }
                </Grid>
            </Grid>
            <Grid item>
                {props.buttonReferenceGroup}
            </Grid>
        </Grid>
    );

    return (
        <BaseModalContainer
            id={props.id}
            buttonName="Upload"
            buttonDisabled={isUploadDisabled}
            modalTitle={props.modalTitle}
            handleClose={closeModal}
            modalOpen={props.modalOpen}
            handleAction={handleClick}
        >
            {body}
        </BaseModalContainer>
    );
}
