import { Grid, makeStyles, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { LicenseUtilization } from "../../clientLibrary/class/classInterfaces"
import { LicenseConsumerResponse } from "../../clientLibrary/license/licenseInterfaces"
import { getLicenseNameOnlyFromLicenseTypeDescription } from "../../common/GeneralFunction"
import { generateNotEnoughLicensesHrefObj } from "../../common/LicenseValidation"
import { useAppSelector } from "../../hooks"
import { MailtoData, MailtoLink, MailtoLinkTypes } from "./mailtoLink"

interface AvailableLicensesProps {
    licenseUtilization: LicenseUtilization[],
    teacherClassName?: string
}

const useStyles = makeStyles({
    teacherSecondaryButton: {
        textTransform: "capitalize",
        color: "#1884df",
        '&:hover': {
            color: "#095da3",
            textDecoration: "underline",
            backgroundColor: "white"
        }
    }
});

export const generateAvailableLicenseCount = (licenseUtilization: LicenseUtilization[], availableLicenses: LicenseConsumerResponse[]) => {
    let min;
    for(let license of licenseUtilization){
        const match = availableLicenses.find(availableLicense => availableLicense.associatedGroupAllowance.licenseType.name === license.licenseTypeName);
        let remaining = match?.associatedGroupAllowance.remaining;
        if(remaining !== undefined && (min === undefined || remaining < min)){
            min = remaining;
        }
    }
    return min ? min : 0;
}


export const AvailableLicenses = (props: AvailableLicensesProps) => {
    const classes = useStyles();
    const [href, setHref] = useState<MailtoData>({} as MailtoData);
    const availableLicenses = useAppSelector(state => state.license.licenseConsumers);
    const primaryContact = useAppSelector(state => state.account.customer?.primaryContact);

    useEffect(() => {
        if(primaryContact && props.teacherClassName){
            const hrefObj =
                generateNotEnoughLicensesHrefObj(
                    primaryContact, 
                    props.teacherClassName, 
                    props.licenseUtilization.map(license => getLicenseNameOnlyFromLicenseTypeDescription(license.licenseTypeDescription)),
                    999
                );
            setHref(hrefObj);
        }
    },[primaryContact, props.licenseUtilization, props.teacherClassName]);
    

    return (
        <Grid item container justify="space-between" alignItems="center">
            <Grid item>
                <Typography variant="h6">
                    Available Licenses: {generateAvailableLicenseCount(props.licenseUtilization, availableLicenses)}
                </Typography>
            </Grid>
            <Grid item>
                <MailtoLink 
                    label={"Request More"} 
                    type={MailtoLinkTypes.Button} 
                    href={href}
                    variant="text"
                    className={classes.teacherSecondaryButton}
                />
                </Grid>
        </Grid>
    );
}