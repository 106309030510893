import { makeStyles } from "@material-ui/core"

export type ClassNameMap<ClassKey extends string = string> = Record<ClassKey, string>;

export const useResponsiveTableStyles = makeStyles({
    tableCellResponsive: {
        "& .rwd-table-header": {
            display: "none"
        }
    },
    "@media(max-width: 768px)": {
        tableCellResponsive: {
            "& td": {
                display: "flex",
                justifyContent: "space-between",
                "& .rwd-table-header": {
                    display: "inline-block"
                },
            },
        },
        justifyFlexEnd: {
            justifyContent: "flex-end"
        },
        alignItemsFlexEnd: {
            alignItems: "flex-end",
            paddingRight: "7px"
        },
        zeroHorizontalMargins: {
            margin: "0 auto"
        },
        dropdownMarginLeft: {
            marginLeft: "7px"
        },
        searchSectionPadding: {
            paddingLeft: "8px !important"
        },
        teacherClassBtns: {
            justifyContent: "flex-start",
            marginLeft: "1px",
            marginTop: "-1px",
            marginBottom: "-4px"
        }
    },
}) as () => ClassNameMap