import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    makeStyles,
    Paper
} from '@material-ui/core';
import { getAcademicYearsByContractLength, getLicenseTableData } from '../../../common/GeneralFunction';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { GRAY_BACKGROUND_COLOR } from './utilizationReport';
import { NAVY_TEXT_COLOR } from './licenseUtilizationRow';
import { ExpirationData } from '../../../clientLibrary/GeneralInterfaces';
import { LicenseForecastTableData } from '../../../clientLibrary/license/licenseInterfaces';

interface LicenseTableProps {
    expirationData: ExpirationData;
    licensesCount: number;
}

export const useStyles = makeStyles(() => ({
    rowContentTextPrimary: {
        color: NAVY_TEXT_COLOR
    },
    columnHeader: {
        color: NAVY_TEXT_COLOR,
        fontWeight: 'bold',
        borderBottom: 'none',
        paddingBottom: '0px',
        width: '200px',
        letterSpacing: '0.05rem'
    },
    headerSecondaryText: {
        fontSize: '0.7rem', 
        letterSpacing: '0rem'
    },
    tableCellContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    licenseCountColumn: {
        width: '100%',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        gap: '20px',
        marginRight: '24px'
    },
    licenseCountColumnContent: {
        color: NAVY_TEXT_COLOR,
        width: '50%', 
        display: 'flex', 
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
}))

export default function LicenseForecastTableMobile(props: LicenseTableProps) {
    const academicYears = getAcademicYearsByContractLength(8);
    const tableData: LicenseForecastTableData[] = getLicenseTableData(academicYears, props.expirationData, props.licensesCount);

    const classes = useStyles();
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow style={{backgroundColor: GRAY_BACKGROUND_COLOR}}>
                        <TableCell className={classes.columnHeader} align='center'>
                            <div>Academic Year</div>
                            <div className={classes.headerSecondaryText}>July 1st - June 30th</div>
                        </TableCell>
                        <TableCell className={classes.columnHeader} style={{padding: '16px 0px 0px 25px'}} align='center'>
                            <div>Total Licenses</div>
                            <div>&nbsp;</div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map(row => {
                        return (
                            <TableRow key={row.academicYear}>
                                <TableCell className={classes.rowContentTextPrimary} align='center'>{row.academicYear}</TableCell>
                                <TableCell align='center' style={{paddingRight: '10px'}}>
                                    <div className={classes.tableCellContainer}>
                                        <div className={classes.licenseCountColumn} style={{marginRight: '0px'}}>
                                            <div className={classes.licenseCountColumnContent}>{row.remainingLicenses}</div>
                                            <div className={classes.licenseCountColumnContent} style={{justifyContent: 'flex-start'}}>
                                                <ArrowDropDownIcon color='error' style={row.expiringLicenses === 0 ? {visibility: 'hidden'} : {visibility: 'visible'}}/>
                                                {row.expiringLicenses === 0 ?
                                                <div>-</div>
                                                :
                                                <div style={{color: 'red'}}>{row.expiringLicenses}</div>
                                                } 
                                            </div>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}