import React, { useState, MouseEvent } from "react";
import { Typography, Tooltip } from "@material-ui/core";
import { NAVY_TEXT_COLOR } from "./licenseUtilizationRow";

interface LicenseTitleTooltipProps {
    licenseName: string
}

export default function LicenseTitleTooltip(props: LicenseTitleTooltipProps) {
    const [tooltipEnabled, setTooltipEnabled] = useState(false);

    const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
        if (currentTarget.scrollWidth > currentTarget.clientWidth) {
          setTooltipEnabled(true);
        }
      };
    
    const hideTooltip = () => setTooltipEnabled(false);

    return (
        <div style={{width: '100%', textAlign: 'start', overflow: 'hidden'}}>
            <Tooltip 
                title={props.licenseName} 
                onMouseEnter={handleShouldShow}
                onMouseLeave={hideTooltip}
                disableHoverListener={!tooltipEnabled} 
                disableTouchListener={!tooltipEnabled} 
                arrow 
                enterTouchDelay={1}>
                <Typography style={{color: NAVY_TEXT_COLOR}} variant="h6" noWrap>{props.licenseName}</Typography>
            </Tooltip>
        </div>
    )
}