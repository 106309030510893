import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getLicenseCounts,
         getAssignedParentLicenses,
         getParentLicenseProviders,
         unassignParentLicense,
         assignLicenseToStudent } from "../../clientLibrary/license/license";
import { ParentLicenseSerialized, LicenseProviderResponse, ParentLicenseAssignmentData } from "../../clientLibrary/license/licenseInterfaces";
import { parentLicenseSerializer } from "../UtilityFunctions";
import { getParentAccountContext, resetPasswordByLogin } from "../../clientLibrary/account/account";
import { CustomerDTO } from "../../clientLibrary/account/accountInterfaces";
import { getParentStudents, updateParentStudent } from "../../clientLibrary/user/user";
import { ParentUserResponse, UserResponse } from "../../clientLibrary/user/userInterfaces";
import { setBackdrop, updateSnackbarError, updateSnackbarSuccess } from "./utilitySlice";

export interface ParentState {
    licenses: Array<ParentLicenseSerialized>,
    accountContext: CustomerDTO | null,
    students: Array<ParentUserResponse>,
    assignedLicenses: Array<LicenseProviderResponse>
}

const initialState: ParentState = {
    licenses: [],
    accountContext: null,
    students: [],
    assignedLicenses: []
}

export const getSerializedLicenses = createAsyncThunk(
    'parent/getSerializedLicenses',
    async () => {
        try {
            const [assignedLicenses, licenseCounts, licenseProviders] = await Promise.all([getAssignedParentLicenses(), getLicenseCounts(), getParentLicenseProviders()]);
            return parentLicenseSerializer(licenseCounts, assignedLicenses, licenseProviders);
        } catch (error) {
            throw error;
        }
    }
);

export const getAccountContext = createAsyncThunk(
    'parent/getAccountContext',
    async () => {
        try {
            const accountContext = await getParentAccountContext();
            return accountContext;
        } catch (error) {
            throw error;
        }
    }
);

export const getStudents = createAsyncThunk(
    'parent/getStudents',
    async () => {
        try {
            const students = await getParentStudents();
            return students;
        } catch (error) {
            throw error;
        }
    }
);

export const getAssignedLicenses = createAsyncThunk(
    'parent/getAssignedLicenses',
    async () => {
        try {
            const assignedLicenses = await getAssignedParentLicenses();
            return assignedLicenses;
        } catch (error) {
            throw error;
        }
    }
);

export const resetStudentPasswordAsParent = createAsyncThunk(
    'parent/resetStudentPasswordAsParent',
    async (login: string, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await resetPasswordByLogin(login);
            thunkAPI.dispatch(updateSnackbarSuccess(`Temporary password reset to "${login}". Be sure the student logs in to update their password.`));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to reset password.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const unassignLicenseAsParent = createAsyncThunk(
    'parent/unassignLicenseAsParent',
    async (id: number, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await unassignParentLicense(id);
            thunkAPI.dispatch(updateSnackbarSuccess(`License unassigned successfully.`));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to unassign license.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const assignLicenseAsParent = createAsyncThunk(
    'parent/assignLicenseAsParent',
    async (payload: {userData: ParentLicenseAssignmentData, snackbarMessage: string}, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await assignLicenseToStudent(payload.userData);
            thunkAPI.dispatch(updateSnackbarSuccess(payload.snackbarMessage));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to assign license.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const updateStudentNameAsParent = createAsyncThunk(
    'parent/updateStudentNameAsParent',
    async (payload: {userData: UserResponse, snackbarMessage: string | null}, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await updateParentStudent(payload.userData);
            if (payload.snackbarMessage) thunkAPI.dispatch(updateSnackbarSuccess(payload.snackbarMessage));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to update student information'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const parentSlice = createSlice({
    name: 'parent',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getSerializedLicenses.fulfilled, (state, action) => {
            state.licenses = action.payload;
        })
        .addCase(getSerializedLicenses.rejected, (state) => {
            state.licenses = [];
        })
        .addCase(getAccountContext.fulfilled, (state, action) => {
            state.accountContext = action.payload;
        })
        .addCase(getAccountContext.rejected, (state) => {
            state.accountContext = null;
        })
        .addCase(getStudents.fulfilled, (state, action) => {
            state.students = action.payload;
        })
        .addCase(getStudents.rejected, (state) => {
            state.students = [];
        })
        .addCase(getAssignedLicenses.fulfilled, (state, action) => {
            state.assignedLicenses = action.payload;
        })
        .addCase(getAssignedLicenses.rejected, (state) => {
            state.assignedLicenses = [];
        })
    }
});

export default parentSlice.reducer;