import React from "react";
import { Button, Link, Typography } from "@material-ui/core"
import { useTeacherInfoBarStyles } from "../teacher/home/teacherInfo";
import { Variant } from "@material-ui/core/styles/createTypography";

export enum MailtoLinkTypes {
    Button = "Button",
    Text = "Text"
}

export interface MailtoData { mailTo: string, subject: string, body: string }

interface MailtoLinkProps {
    label: string;
    type: MailtoLinkTypes;
    href: MailtoData;
    typographyVariant?: "inherit" | Variant;
    startIcon?: JSX.Element;
    variant?: "text" | "outlined" | "contained" | undefined;
    color?: "inherit" | "primary" | "secondary" | "default" | undefined;
    className?: string;
    style?: React.CSSProperties;
}

export const MailtoLink = (props: MailtoLinkProps) => {
    const classes = useTeacherInfoBarStyles();
    const hrefString =
        `mailto:${props.href.mailTo}?subject=${props.href.subject}&body=${props.href.body}`;
    return (
        <>
            {
                props.type === MailtoLinkTypes.Button && (
                    <Button
                        color={props.color || "inherit"}
                        className={props.className || classes.actionBtn}
                        startIcon={props.startIcon}
                        href={hrefString}
                        size="small"
                        variant={props.variant || "text"}
                        style={props.style}
                    >
                        <Typography variant={props.typographyVariant || "h6"} style= {{paddingLeft: "2.2px"}}>{props.label}</Typography>
                    </Button>
                )
            }
            {props.type === MailtoLinkTypes.Text && (
                <Link href={hrefString}>{props.label}</Link>
            )}
        </>
    );
}