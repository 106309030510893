import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    CardMedia,
    Chip,
    Grid,
    makeStyles,
    Typography,
    Tooltip,
    CardContent,
    CardActionArea,
    Button,
} from "@material-ui/core";
import { KeyboardReturn, AccountCircle, Autorenew, Cancel, School } from "@material-ui/icons";
import { useAppDispatch } from '../../../hooks'
import { resetStudentPasswordAsParent, unassignLicenseAsParent } from '../../../reduxModule/slices/parentSlice';
import StudentEnrollment from './studentEnrollment';
import { ACTION_BUTTON_FONT_SIZE } from "../../../constants";

interface LicenseCardProps {
    activeCard: number | null,
    bookEdition: string | null,
    cardExpiration: string,
    cardImage: string,
    cardTitle: string,
    id: number,
    licenseId: number | null,
    licenseTypeId: number,
    licenseProviderId: number,
    studentEmail: string | null,
    studentFirstName: string | null,
    studentLastName: string | null,
    changeActiveCard: (id: number | null) => void,
    setRefreshData: (arg: boolean) => void
}

const CARD_WIDTH = '300px';

const useStyles = makeStyles(() => ({
    card: {
        width: CARD_WIDTH,
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'space-between',
        padding: '0px',
        height: '400px',
    },
    cardTitle: {
        textTransform: "uppercase",
        fontSize: "1.5rem",
        fontWeight: 600,
        textAlign: "center",
        width: CARD_WIDTH,
    },
    cardInfo: {
        textTransform: "uppercase",
        fontSize: '1em',
        fontWeight: 600,
        width: '270px',
        textAlign: 'center'
    },
    actionButtons: {
        width: '50%',
        height: '100%',
        fontSize: ACTION_BUTTON_FONT_SIZE
    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
        textTransform: 'uppercase',
        justifyContent: 'center',
        alignItems: 'center',
    },
    editionLabel: {
        border: 'none',
        fontWeight: 'bold',
        width: 'fit-content'
    },
    assignedTo: {
        color: '#757575',
        fontWeight: 'normal'
    }
}));

function LicenseCardMobile(props: LicenseCardProps) {
    const dispatch = useAppDispatch();
    const [showStudentEnrollmentFields, setShowStudentEnrollmentFields] = useState<boolean>(false);
    const classes = useStyles();
    const assignedStatus = props.licenseId !== null ? `${props.studentFirstName} ${props.studentLastName}` : `Unassigned`;
    const displayDate = new Date(props.cardExpiration).toLocaleDateString(undefined, {day: 'numeric', month: 'long', year: 'numeric'});

    useEffect(() => {
        if (props.activeCard !== props.id) {
            setShowStudentEnrollmentFields(false);
        }
    }, [props.activeCard, props.id]);

    const handleResetPassword = async () => {
        if (props.studentEmail !== null) {
            await dispatch(resetStudentPasswordAsParent(props.studentEmail));
            props.changeActiveCard(null);
        }
    }

    const handleUnassignButton = async () => {
        if (props.licenseId !== null) {
            await dispatch(unassignLicenseAsParent(props.licenseId));
            props.setRefreshData(true);
            props.changeActiveCard(null);
        }
    }

    const handleManageButton = () => {
        setShowStudentEnrollmentFields(props.licenseId !== null ? false : true);
        props.changeActiveCard(props.id);
    }

    return (
        <>
            <Grid item style={{marginTop: 30}}>
                <Card raised={true} className={classes.card}>
                    {props.activeCard === props.id ? (
                        <>
                            <Tooltip title={props.cardTitle} arrow enterTouchDelay={1}>
                                <div>
                                    <CardHeader 
                                        role={'heading'} 
                                        aria-level={2} 
                                        titleTypographyProps={{ noWrap: true, className: classes.cardTitle }} 
                                        title={props.cardTitle}
                                        className={classes.cardTitle} 
                                        style={{padding: '5% 1% 0%'}} />
                                </div>
                            </Tooltip>
                            {showStudentEnrollmentFields ? (
                                <div style={{ padding: '15px 10px 10px 10px', height: '100%' }}>
                                    <StudentEnrollment
                                        bookEdition={props.bookEdition}
                                        cardTitle={props.cardTitle}
                                        mobile
                                        licenseId={props.licenseId}
                                        licenseTypeId={props.licenseTypeId}
                                        licenseProviderId={props.licenseProviderId}
                                        studentEmail={props.studentEmail}
                                        studentFirstName={props.studentFirstName}
                                        studentLastName={props.studentLastName}
                                        licenseExpiration={props.cardExpiration}
                                        changeActiveCard={props.changeActiveCard}
                                        setRefreshData={props.setRefreshData}
                                    />
                                </div>
                            ) : (
                                <>
                                    <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '30px', justifyContent: 'space-between', padding: '10px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '20px' }}>
                                            <Button className={classes.actionButtons} onClick={() => setShowStudentEnrollmentFields(true)}>
                                                <div className={classes.iconButtonLabel}>
                                                    <AccountCircle style={{ color: 'green', fontSize: '60px' }}/>
                                                    <Typography style={{ fontSize: ACTION_BUTTON_FONT_SIZE }}>Edit</Typography>
                                                    <br/>
                                                </div>
                                            </Button>
                                            <Button className={classes.actionButtons} onClick={handleResetPassword}>
                                                <div className={classes.iconButtonLabel}>
                                                    <Autorenew style={{ color: '#188ADF', fontSize: '60px' }}/>
                                                    <Typography style={{ fontSize: ACTION_BUTTON_FONT_SIZE, whiteSpace: 'nowrap' }}>Reset Password</Typography>
                                                </div>
                                            </Button>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button onClick={handleUnassignButton} startIcon={<Cancel fontSize="small" color="error"/>}>
                                                Unassign 
                                            </Button>
                                            <Button onClick={() => props.changeActiveCard(null)} startIcon={<KeyboardReturn fontSize="small" color="action"/>}>
                                                Return 
                                            </Button>
                                        </div>
                                    </CardContent>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <CardActionArea onClick={handleManageButton} style={{ height: '100%' }}>
                                <CardMedia component='img' src={props.cardImage} height='285px' />
                                <CardHeader 
                                    titleTypographyProps={{ noWrap: true, className: classes.cardTitle }} 
                                    title={props.cardTitle}
                                    style={{ padding: '1% 1% 0%' }} />
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'center', visibility: props.bookEdition ? 'visible' : 'hidden' }} >
                                        <Chip variant='outlined' className={classes.editionLabel} size='small' label={'Teacher Edition'} avatar={<School fontSize='small' />} />
                                    </div>
                                    <Typography style={{ color: "#757575", textAlign: 'center' }}>Expires: {displayDate}</Typography>
                                    <Typography noWrap className={classes.cardInfo}>
                                        <span style={{ display: props.licenseId !== null ? 'auto' : 'none' }} className={classes.assignedTo}>Assigned To: </span>
                                        {assignedStatus}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </>
                    )}
                </Card>
            </Grid>
        </>
    );
}

export default LicenseCardMobile;