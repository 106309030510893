import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertType } from "../../components/helperComponents/snackBar";

export interface UtilityState {
    snackMessage: SnackMessage,
    pagination: Pagination,
    showBackDrop: boolean,
    dataProgress: boolean,
    determinateProgress: DeterminateProgress,
    highlightedRowValues: Record<number, string[]>
}

interface SnackMessage {
    type: AlertType,
    status: boolean,
    message: string
}

interface Pagination {
    [entity: string]: { page: number, per_page: number }
}

interface DeterminateProgress {
    title: string,
    showProgress: boolean,
    total: number,
    current: number
}

interface EntityPaginationPayload {
    entity: string,
    page: number,
    per_page: number
}

const initialState: UtilityState = {
    snackMessage: {
        type: "",
        status: false,
        message: ""
    },
    pagination: {
        user: { page: 0, per_page: 25 }
    },
    showBackDrop: false,
    dataProgress: false,
    determinateProgress: { title: "", showProgress: false, total: 0, current: 0 },
    highlightedRowValues: {}
}

export const setProgressCurrentThunk = createAsyncThunk(
    'user/setProgressCurrent',
    async (setter: (currentCount: number) => number, thunkAPI: any) => {
        const currentCount: number = thunkAPI.getState().utility.determinateProgress.current;
        thunkAPI.dispatch(setProgressCurrent(setter(currentCount)))
    }
);

export const utilitySlice = createSlice({
    name: 'utility',
    initialState,
    reducers: {
        updateSnackbarSuccess: (state, action: PayloadAction<string>) => {
            state.snackMessage.type = "success";
            state.snackMessage.status = true;
            state.snackMessage.message = action.payload;
        },
        updateSnackbarError: (state, action: PayloadAction<string>) => {
            state.snackMessage.type = "error";
            state.snackMessage.status = true;
            state.snackMessage.message = action.payload;
        },
        updateSnackbarWarning: (state, action: PayloadAction<string>) => {
            state.snackMessage.type = "warning";
            state.snackMessage.status = true;
            state.snackMessage.message = action.payload;
        },
        clearSnackbarMessage: (state) => {
            state.snackMessage.status = false;
        },
        setPagination: (state, action: PayloadAction<EntityPaginationPayload>) => {
            const entity = action.payload.entity;
            state.pagination[entity] = { page: action.payload.page, per_page: action.payload.per_page };
        },
        setBackdrop: (state, action: PayloadAction<boolean>) => {
            state.showBackDrop = action.payload;
        },
        setDataProgress: (state, action: PayloadAction<boolean>) => {
            state.dataProgress = action.payload;
        },
        setDeterminateProgress: (state, action: PayloadAction<boolean>) => {
            state.determinateProgress.showProgress = action.payload;
        },
        setProgressTitle: (state, action: PayloadAction<string>) => {
            state.determinateProgress.title = action.payload;
        },
        setProgressTotal: (state, action: PayloadAction<number>) => {
            state.determinateProgress.total = action.payload;
        },
        setProgressCurrent: (state, action: PayloadAction<number>) => {
            state.determinateProgress.current = action.payload;
        },
        resetProgressData: state => {
            state.determinateProgress.showProgress = false;
            state.determinateProgress.title = "";
            state.determinateProgress.current = 0;
            state.determinateProgress.total = 0;
        },
        addToHighlightedRowValues: (state, action: PayloadAction<{ classId: number, value: string }>) => {
            const { classId, value } = action.payload;
            if (state.highlightedRowValues[classId]) {
                state.highlightedRowValues =
                {
                    ...state.highlightedRowValues,
                    [classId]: [...state.highlightedRowValues[classId], value]
                }
            } else {
                state.highlightedRowValues = { ...state.highlightedRowValues, [classId]: [value] }
            }
        },
        clearHighlightedRowValues: (state) => {
            state.highlightedRowValues = {};
        }
    },
});

export const {
    updateSnackbarSuccess,
    updateSnackbarError,
    updateSnackbarWarning,
    clearSnackbarMessage,
    setPagination,
    setBackdrop,
    setDataProgress,
    setDeterminateProgress,
    setProgressTitle,
    setProgressTotal,
    setProgressCurrent,
    resetProgressData,
    addToHighlightedRowValues,
    clearHighlightedRowValues
} = utilitySlice.actions;

export default utilitySlice.reducer;