export enum LicenseType {
    Student = "student",
    Teacher = "teacher"
}

export enum UserType {
    District = "district",
    Teacher = "teacher"
}

export enum DistrictRoles {
    Administrator = "Administrator",
    Teacher = "Teacher",
    Student = "Student"
}

export enum GlobalCPMRoles {
    Administrator = "Administrator",
    Faculty = "faculty",
    Student = "student"
}

export enum SchoolRoles {
    Administrator = "Administrator",
    Teacher = "Teacher",
    Student = "Student"
}

export enum RegisterUserRoles {
    Student = "registerStudent",
    Faculty = "registerFaculty"
}

export enum ReportTypes {
    Contracts = "contracts",
    Licenses = "licenses"
}

export enum UsedLicenseReportTypes {
    Teacher = "Teacher",
    Student = "Student"
}

export enum BulkActions {
    Add = "Add",
    Delete = "Delete",
    Empty = ""
}

export enum ColumnType {
    Checkbox = "checkbox",
    Text = "text",
    Button = "button"
}

export enum EntityTask {
    Create = "create",
    Update = "update"
}

export enum UploadType {
    School = "school",
    Class = "class",
    User = "user",
    Student = "student"
}