import React from 'react';
import Grid from "@material-ui/core/Grid";

export default function Footer() {
  return (
    <footer>
      <Grid container className="footerMain">
        <Grid item xs={8} className="copyRightGrid">
          <p className="copyrightText">
            &copy; 2023 CPM Educational Program. All rights reserved
          </p>
        </Grid>
        <Grid item xs={4} className="privacypolicyGrid">
          <a className="privacypolicyLink" href="https://cpm.org/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy policy</a>
        </Grid>
      </Grid>
    </footer>
  )
}