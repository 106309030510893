import { Button, Collapse, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CurrentPast } from ".";
import { ClassesResponse, ClassUserResponse } from "../../../clientLibrary/class/classInterfaces";
import { UserResponse } from "../../../clientLibrary/user/userInterfaces";
import { arrSortByProperty } from "../../../common/GeneralFunction";
import { CheckLicensesResponse } from "../../../common/LicenseValidation";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getEnrolledStudentsByClassId } from "../../../reduxModule/slices/userSlice";
import { StudentEnrollment } from "./studentEnrollment";
import { StudentRow, useRowStyles } from "./studentRow";
import TransferStudentModal from "../../modals/teacher/transferStudentModal";

interface ClassTableProps {
    classId: number,
    filterValue: CurrentPast,
    handler: (updateClassData: ClassesResponse) => void,
    handleBulkEnrollment: (open: boolean) => void,
    availableLicenseInfo: CheckLicensesResponse | null
}

const useStyles = makeStyles((theme) => ({
    tableHeaderContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: "23px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 20px 10px 20px"
        },
    },
    tableHeaderStyle: {
        backgroundColor: "#1884df",
        color: "white",
        fontSize: "16px",
        fontWeight: 650,
    },
    enrollmentHeaderContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "35%",
        [theme.breakpoints.down("sm")]: {
            width: "49%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            justifyContent: "center",
        },
    },
    studentTableHeader: {
        width: "40%",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center"
        },
    },
    enrollmentBtns: {
        textTransform: "capitalize",
        color: "#1884df",
        '&:hover': {
            color: "#095da3",
            textDecoration: "underline",
            backgroundColor: "#afdbff00"
        },
    },
}));

function ClassTable(props: ClassTableProps) {
    const [openSingleEnrollment, setOpenSingleEnrollment] = useState(false);
    const classes = useStyles();
    const rowClasses = useRowStyles()

    const highlightedRowValues = useAppSelector(state => state.utility.highlightedRowValues);
    const studentsInClass = useAppSelector(state => state.user.studentsEnrolledToClass[props.classId]);
    const classData = useAppSelector(state => state.class.searchedClasses.find(classesResponse => classesResponse.id === props.classId));

    const [studentsForDisplay, setStudentsForDisplay] = useState<ClassUserResponse[]>([]);
    const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
    const [studentData, setStudentData] = useState<UserResponse | null>(null);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (studentsInClass) {
            setStudentsForDisplay(arrSortByProperty([...studentsInClass], "lastName"));
        }
    }, [studentsInClass]);

    useEffect(() => {
        if (classData) {
            dispatch(getEnrolledStudentsByClassId(classData.id));
        }
    }, [dispatch, classData]);

    const handleBulkAddClick = () => {
        if(props.availableLicenseInfo != null && props.availableLicenseInfo.okToEnroll){
            props.handleBulkEnrollment(true);
        } else {
            setOpenSingleEnrollment(!openSingleEnrollment);
        }
    }

    if (classData) {
        return (
            <>
                <Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    style={{ minHeight: "80px" }}
                >
                    <Grid item container className={classes.tableHeaderContainer}>
                        <Grid item className={classes.studentTableHeader}>
                            <Typography variant="h5" style={{ fontSize: "1.6rem" }}>
                                Students
                            </Typography>
                        </Grid>
                        {(props.filterValue === "current" && !classData.isRostered) &&
                            <Grid item container className={classes.enrollmentHeaderContainer}>
                                <Grid item style={{ width: "20%" }}>
                                    <Typography variant="h6" style={{ textAlign: "center" }}>
                                        Enroll:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => setOpenSingleEnrollment(!openSingleEnrollment)}
                                        variant="text"
                                        color="inherit"
                                        className={classes.enrollmentBtns}
                                        style={{ marginRight: "5px" }}
                                    >
                                        <Typography variant="h6" style={{ marginRight: "-5px" }}>
                                            Single
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item >
                                    <Typography variant="h5" style={{ paddingTop: "3px", margin: "0px", height: "50%" }}>
                                        |
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={handleBulkAddClick}
                                        variant="text"
                                        color="inherit"
                                        className={classes.enrollmentBtns}
                                        style={{ marginRight: "5px" }}
                                    >
                                        <Typography variant="h6">
                                            Bulk
                                        </Typography>
                                    </Button >
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Collapse in={openSingleEnrollment} style={{ width: "100%" }}>
                        <StudentEnrollment
                            classId={classData.id}
                            teacherClassName={classData.name}
                            setOpenSingleEnrollment={setOpenSingleEnrollment}
                            openSingleEnrollment={openSingleEnrollment}
                            availableLicenseInfo={props.availableLicenseInfo}
                        />
                    </Collapse>
                </Grid>
    
                <Grid container justify="center" alignItems="center" style={{ paddingBottom: 20 }}>
                    {studentsForDisplay?.length > 0 ? (
                        <>
                            <TableContainer>
                                <Table aria-label="students" size="medium" style={{ boxShadow: "0px 0px 1px 1px grey" }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableHeaderStyle} style={{ width: "30%" }}>Username</TableCell>
                                            <TableCell className={`${classes.tableHeaderStyle} ${rowClasses.firstNameColumn}`} style={{ width: "30%" }}>First Name</TableCell>
                                            <TableCell className={classes.tableHeaderStyle} style={{ width: "35%" }}>Last Name</TableCell>
                                            <TableCell className={classes.tableHeaderStyle} style={{ width: "5%" }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            studentsForDisplay.map((student: UserResponse) => {
                                                const isHighlighted =
                                                    highlightedRowValues[classData.id]?.includes(student.login);
                                                return <StudentRow
                                                    key={student.login}
                                                    student={student}
                                                    setStudentData={setStudentData}
                                                    filterValue={props.filterValue}
                                                    classId={classData.id}
                                                    isRostered={classData.isRostered}
                                                    highlight={isHighlighted}
                                                    setTransferModalOpen={setIsTransferModalOpen}
                                                />
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TransferStudentModal
                                studentData={studentData}
                                handleClose={() => setIsTransferModalOpen(false)}
                                modalOpen={isTransferModalOpen}
                                classId={props.classId}
                            />
                        </>
                    ) : (
                        <Typography variant="h6" style={{ marginTop: "10px" }}>
                            No Students Enrolled
                        </Typography>
                    )}
                </Grid>
            </>
        )
    } else {
        return null;
    }
}

export default ClassTable;