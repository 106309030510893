import React, { useState } from "react";
import BaseModalContainer from "../shared/baseModalContainer";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { tutorial_video_link_youtube, tutorial_video_link_vimeo } from "../../../constants";


interface TutorialVideoModalProps {
    modalOpen: boolean,
    handleClose: () => void,
}

const useStyles = makeStyles({
    videoButtons: {
        paddingTop: "10px",
        color: "#1884DF",
    }
}
);

export default function TutorialVideoModal (props: TutorialVideoModalProps) { 
    const [currentVideoPlayer, setCurrentVideoPlayer] = useState<string>("youtube");
    const classes = useStyles();

    const body = (
        <Grid container justify="center">
            <iframe
                width="560" 
                height="315" 
                src={currentVideoPlayer === "youtube" ? tutorial_video_link_youtube : tutorial_video_link_vimeo}
                title="YouTube video player" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
            <Grid item container justify="space-around">
                <Button className={classes.videoButtons} onClick={() => setCurrentVideoPlayer("youtube")} disabled={currentVideoPlayer === "youtube"}>Youtube</Button>
                <Button className={classes.videoButtons} onClick={() => setCurrentVideoPlayer("vimeo")} disabled={currentVideoPlayer === "vimeo"}>Vimeo </Button>
            </Grid>
        </Grid>
    )

    return (
        <BaseModalContainer
            id="tutorial-video"
            buttonName="Close"
            modalTitle="License Manager Tutorial"
            handleClose={props.handleClose}
            modalOpen={props.modalOpen}
            handleAction={props.handleClose}
        >
            {body}
        </BaseModalContainer>
    )
}