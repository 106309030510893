import React, { useEffect, useState } from "react";
import { CurrentPast } from ".";
import { ClassesResponse } from "../../../clientLibrary/class/classInterfaces";
import {
    Button,
    Card,
    CardHeader,
    Collapse,
    Grid,
    makeStyles,
    Typography
} from "@material-ui/core";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LockIcon from '@material-ui/icons/Lock';
import ClassTable from "./classTable";
import ClassInfo from "./classInfo";
import { ParseResult } from "papaparse";
import { addBulkStudents, getEnrolledStudentsByClassId } from "../../../reduxModule/slices/userSlice";
import UploadStudentModal, { csvArrayToBulkStudentData } from "../../modals/teacher/uploadStudentModal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getUserLicenseConsumersByLogin } from "../../../reduxModule/slices/licenseSlice";
import { checkForEnoughLicensesByLicenseNames, CheckLicensesResponse, generateNotEnoughLicensesHrefObj } from "../../../common/LicenseValidation";

interface ClassCardProps {
    classData: ClassesResponse,
    filterValue: CurrentPast,
    handler: (updateClassData: ClassesResponse) => void,
}

const useStyles = makeStyles(theme => ({
    card: {
        width: '98%',
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'space-between',
    },
    cardHeader: {
        padding: "0px 25px 0px 5px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            alignItems: "center",
            padding: "5px",
        },
    },
    classTitle: {
        textTransform: "uppercase",
        padding: "2%",
        fontSize: "1.5em",
        fontWeight: 600,
    },
    editBtn: {
        textTransform: "capitalize",
        color: "#1884df",
        '&:hover': {
            color: "#095da3",
            textDecoration: "underline",
            backgroundColor: "white"
        }
    },
    manageClassButtons: {
        padding: "0px",
        width: "130px",
        border: "2px solid #188adf",
        borderRadius: "20px",
        backgroundColor: "#188ADF",
        '&:hover': {
            backgroundColor: "#188ADF",
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        }
    },
}))

function ClassCard(props: ClassCardProps) {
    const [openClassDropdown, setOpenClassDropdown] = useState(false);
    const [bulkAddStudentOpen, setBulkAddStudentOpen] = useState(false);

    const [availableLicenseInfo, setAvailableLicenseInfo] = useState<CheckLicensesResponse | null>(null);
    const primaryContact = useAppSelector(state => state.account.customer?.primaryContact);
    const availableLicenses = useAppSelector(state => state.license.licenseConsumers);
    const licenseUtilization = props.classData.licenseUtilization;
    
    const login = useAppSelector(state => state.auth.userRole.login);

    useEffect(() => {
        if (primaryContact) {
            let checkedLicenseObj = checkForEnoughLicensesByLicenseNames(availableLicenses,licenseUtilization);
            if(checkedLicenseObj.bookList){
                checkedLicenseObj = {
                    ...checkedLicenseObj,
                    mailtoData: generateNotEnoughLicensesHrefObj(primaryContact, props.classData.name, checkedLicenseObj.bookList)
                }
            }
            setAvailableLicenseInfo(checkedLicenseObj);
        }
    }, [availableLicenses, licenseUtilization, primaryContact, props.classData.name]);
    
    const schools = useAppSelector(state => state.school.schools);
    const dispatch = useAppDispatch();

    const handleAddBulkStudents = async (csvFileContent: ParseResult<string>[]) => {
        let bulkStudentData = csvArrayToBulkStudentData(csvFileContent);
        const classId = props.classData.id;
        if (bulkStudentData) {
            bulkStudentData = bulkStudentData.map(data => {
                return {
                    ...data,
                    classId: `${classId}`
                }
            });
            await dispatch(addBulkStudents({ customerId: schools[0].customer.id, schoolId: schools[0].id, bulkStudentData }));
        }
        await dispatch(getUserLicenseConsumersByLogin(login));
        dispatch(getEnrolledStudentsByClassId(classId));
    }

    const classes = useStyles();

    return (
        <>
            <Grid item container spacing={3} direction="column" justify="center" alignItems="center" style={{ willChange: "transform", paddingTop: 0, marginTop: 30 }} >
                <Card raised={true} className={classes.card}>
                    <Grid item container direction="row" justify="space-between" alignItems="center" className={classes.cardHeader}>
                        <CardHeader disableTypography title={props.classData.name} className={classes.classTitle} />
                        {
                            openClassDropdown && !props.classData.isRostered && props.filterValue ==="current" && (
                                <Button onClick={() => props.handler(props.classData)} variant="text" color="inherit" className={classes.editBtn}>
                                    <Typography variant="h6">Edit</Typography>
                                </Button>
                            )
                        }
                        {
                            props.classData.isRostered && (
                                <Grid style={{ display: "flex", justifyContent: "flex-end", paddingRight: "5px", color: "grey" }}>
                                    <LockIcon style={{ fontSize: "1.4rem", paddingRight: "3px", paddingTop: "2px" }} />
                                    <Typography variant="h6" style={{ textTransform: "capitalize" }}>Rostered</Typography>
                                </Grid>
                            )
                        }
                    </Grid>
                    <ClassInfo classId={props.classData.id} />
                    <Grid item style={{ marginBottom: "10px" }}>
                        {
                            openClassDropdown
                                ?
                                (
                                    <Button color="primary"
                                        className={classes.manageClassButtons}
                                        style={{ backgroundColor: "white", }}
                                        onClick={() => setOpenClassDropdown(!openClassDropdown)}>
                                        <KeyboardArrowUpIcon style={{ fontSize: "2.2rem", color: "#188adf" }} />
                                    </Button>
                                )
                                : (
                                    <Button
                                        variant="text"
                                        className={classes.manageClassButtons}
                                        onClick={() => setOpenClassDropdown(!openClassDropdown)}
                                    >
                                        <KeyboardArrowDownIcon style={{ fontSize: "2.2rem", color: "#FFF" }} />
                                    </Button>
                                )
                        }
                    </Grid>
                    <Grid item>
                        <Collapse in={openClassDropdown}>
                            <ClassTable
                                classId={props.classData.id}
                                filterValue={props.filterValue}
                                handler={props.handler}
                                handleBulkEnrollment={() => setBulkAddStudentOpen(true)}
                                key={props.classData.pin}
                                availableLicenseInfo={availableLicenseInfo}
                            />
                        </Collapse>
                    </Grid>
                </Card>
            </Grid>
            <UploadStudentModal
                modalOpen={bulkAddStudentOpen}
                handleClose={() => setBulkAddStudentOpen(false)}
                handleSubmit={handleAddBulkStudents}
                licenseUtilization={props.classData.licenseUtilization}
                entityName={props.classData.name}
            />
        </>
    )
}

export default ClassCard