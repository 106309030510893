import { data_api_url } from "../../constants";
import axiosWithAuth from "../../reduxModule/axiosWithAuth";
import { DataCount } from "../GeneralInterfaces";
import {
    ClassesResponse,
    ClassDataResponse,
    ClassUserResponse,
    CreateClassPayload,
    SearchClassesParams,
    UpdateClassTeacherPayload,
    UpdateClassDistrictPayload
} from "./classInterfaces";

export const createClass = async (payload: CreateClassPayload): Promise<void> => {
    await axiosWithAuth().post(`${data_api_url}/ELS/api/customer/classes/`, payload);
}

export const getAllClassInfo = async (): Promise<DataCount<ClassesResponse>> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/classes/search`, { params: { per_page: 1000 } });
    return {
        data: response.data,
        count: parseInt(response.headers["x-pagination-totalcount"])
    }
}

export const getSearchedClassInfo = async (params: SearchClassesParams): Promise<DataCount<ClassesResponse>> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/classes/search`, { params });
    return {
        data: response.data,
        count: parseInt(response.headers["x-pagination-totalcount"])
    }
}

export const getClassInfoForCsv = async (classCount: number): Promise<DataCount<ClassesResponse>> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/classes/search`, { params: { page: 0, per_page: classCount } });
    return {
        data: response.data,
        count: parseInt(response.headers["x-pagination-totalcount"])
    }
}

export const getClassById = async (classId: number): Promise<ClassDataResponse> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/classes/${classId}`);
    return response.data;
}

export const getClassesByTeacherLogin = async (login: string): Promise<ClassDataResponse[] | never[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/customer/classes/search?teacher=${login}`);
    return response.data;
}

export const updateClassFromDistrict = async (classData: UpdateClassDistrictPayload): Promise<void> => {
    await axiosWithAuth().put(`${data_api_url}/ELS/api/customer/classes`, classData);
}

export const updateClassFromTeacher = async (classData: UpdateClassTeacherPayload): Promise<void> => {
    await axiosWithAuth().put(`${data_api_url}/ELS/api/customer/teacher/classes`, classData);
}

export const deleteClassById = async (classId: number): Promise<void> => {
    await axiosWithAuth().delete(`${data_api_url}/ELS/api/customer/classes/${classId}`);
}

export const getEnrolledStudentsForClass = async (classId: number): Promise<ClassUserResponse[]> => {
    const response = await axiosWithAuth().get(`${data_api_url}/ELS/api/class/${classId}/studentDetails`, { params: { per_page: 1000 } });
    return response.data;
}

export const removeStudentByClassIdAndLogin = async (classId: number, login: string): Promise<void> => {
    await axiosWithAuth().delete(`${data_api_url}/ELS/api/class/${classId}/student/${login}/url`);
}

export const addLicenseToClass = async (classId: number, providerId: number): Promise<void> => {
    await axiosWithAuth().put(
        `${data_api_url}/ELS/api/class/${classId}/book`, providerId,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
}

export const removeLicenseFromClass = async (classId: number, providerId: number): Promise<void> => {
    await axiosWithAuth().delete(`${data_api_url}/ELS/api/class/${classId}/book/${providerId}`);
}

export const regeneratePinById = async (pinId: number): Promise<string> => {
    const response = await axiosWithAuth().put(`${data_api_url}/ELS/api/enrollmentTokens/${pinId}/regeneratePin`);
    return response.data;
}