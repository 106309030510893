import React, { Fragment, useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

interface DateInputProps {
  initDate: Date | null,
  handleDateChange(date: Date | null): void,
  label: React.ReactNode,
  format: string,
  errStatus?: boolean,
  required: boolean,
  fullWidth: boolean,
  errMessage?: string,
  disabled?: boolean,
  minDate?: Date | null | string,
  maxDate?: Date | null
}

export default function DateInput(props: DateInputProps) {
  const { initDate, handleDateChange, label, format, errStatus, errMessage, ...otherProps } = props;
  const [selectedDate, setSelectedDate] = useState(initDate);

  const submitDateChange = (date: Date | null) => {
    setSelectedDate(date);
    handleDateChange(date);
  }

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          label={label}
          format={format}
          value={selectedDate}
          size="small"
          onChange={date => submitDateChange(date)}
          error={errStatus}
          helperText={errMessage}
          {...otherProps}
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
}

