import React, { useState } from 'react';
import UtilizationReport, { ELS_PRIMARY_BLUE_COLOR } from './utilizationReport';
import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import ExpirationReport from './expirationReport';
import LicenseReportsToggleButtons from './licenseReportsToggleButtons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { BUTTON_CONTAINER_HEIGHT } from '../classes';
import { NAVY_TEXT_COLOR } from './licenseUtilizationRow';
import CustomizedSelects from '../../helperComponents/customizedSelects';
import { ContentValue } from '../../../clientLibrary/GeneralInterfaces';
import { currentYear, daysRemainingUntilExpiration } from '../../../constants';
import { DistrictSerializedLicenses } from '../../../clientLibrary/license/licenseInterfaces';

export interface LicenseReportsProps {
    licenses: DistrictSerializedLicenses[]
}

type LicenseReports = "expiring" | "student" | "teacher";

const filterValues: ContentValue<LicenseReports>[] = [
    { name: "Expiring Licenses", value: "expiring" },
    { name: "Student Licenses", value: "student" },
    { name: "Teacher Licenses", value: "teacher" }
];

const HEIGHT_BTWN_TOGGLE_AND_REPORT = '25px';

const useStyles = makeStyles({
    toggleBreak: {
        marginBottom: HEIGHT_BTWN_TOGGLE_AND_REPORT,
        borderBottom: `1px solid ${ELS_PRIMARY_BLUE_COLOR}`,
        width: '100vw',
        position: 'relative',
        left: 'calc(-50vw + 50%)'
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '10px',
        color: NAVY_TEXT_COLOR,
        '&:hover': {
            color: ELS_PRIMARY_BLUE_COLOR
        }
    }
})

export default function LicenseReports(props: LicenseReportsProps) { 
    const expiringLicenses = props.licenses.filter((license) => currentYear in license.expiration)
    const classes = useStyles();
    
    const [activeTab, setActiveTab] = useState<string>(expiringLicenses.length === 0 || daysRemainingUntilExpiration < -90 ? 'student' : 'expiring');

    const handleReport = (_event: React.MouseEvent<HTMLElement>, newTab: string | null) => {
        if (newTab !== null) {
            setActiveTab(newTab); 
        }
    };

    const handleCustomizedSelects = (newRole: ContentValue<LicenseReports>) => {
       setActiveTab(newRole.value);
    }

    const bulkActionBreakPoint = useMediaQuery('max-width: 750px');
    const smallScreen = useMediaQuery('(max-width: 1023px)');

    return (
        <Grid item
            xl={7} lg={8} md={12} sm={12} xs={12}
            style={{margin: 'auto', padding: "15px 15px 0px"}}
        >
            <Grid 
                container 
                direction="column"
            >
                <Grid container justify='space-between' style={{flexWrap: 'nowrap', height: BUTTON_CONTAINER_HEIGHT}}>
                    <div className="tabs tabs-section-dropdown" style={bulkActionBreakPoint ? {padding: '0px'} : {padding: '0px 7px 0px 0px', display: 'flex', alignItems: 'center'}}>
                        <CustomizedSelects
                          filterValues={filterValues}
                          filterVal={activeTab}
                          handleFilterSelect={(item) => handleCustomizedSelects(item)}
                          />
                    </div>
                    <Grid container item xs={4} alignItems='flex-end' style={smallScreen ? {paddingBottom: '6px'} : {paddingBottom: '0px'}}>
                        <a href='https://shop.cpm.org/' target='_blank' rel="noopener noreferrer">
                            <span className={classes.link}>
                                <ExitToAppIcon fontSize='small'/>
                                &nbsp;
                                <Typography variant='subtitle1' noWrap>CPM Store</Typography>
                            </span>
                        </a>
                    </Grid>
                    <Grid item container justify='flex-end'>
                        <Grid item className="tabs d-flex tabs-section-mobile">
                            <LicenseReportsToggleButtons 
                                activeTab={activeTab}
                                handleReport={handleReport}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {!smallScreen && <div className={classes.toggleBreak}></div>}
            </Grid>
            {activeTab === "expiring" ?
            <ExpirationReport
                expiringLicenses={expiringLicenses}
            />
            :
            <UtilizationReport
                activeTab={activeTab}
                licenses={props.licenses}
            />
            }
        </Grid>
    )
}

