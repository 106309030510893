import React, { useEffect, useState, useCallback, useMemo } from 'react';
import BaseModalContainer from "./baseModalContainer";
import {
  TextField,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  IconButton,
} from '@material-ui/core';
import DateInput from '../../helperComponents/dateInput';
import DialogBox from "../../helperComponents/dialogBox";
import { format } from 'date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import {
  timezoneOffset,
  getFullName,
  arrayOfStringsToSingleStringDisplay,
  getLicenseNameOnlyFromLicenseTypeDescription
} from '../../../common/GeneralFunction';
import { FULLYEARINMS } from '../../../common/Constants';
import { Alert } from '@material-ui/lab';
import { getUserLicenseConsumersByLogin } from '../../../reduxModule/slices/licenseSlice';
import { findTeachersBySchool } from '../../../reduxModule/slices/schoolSlice';
import { ClassPagination, getAllClasses, searchClasses } from '../../../reduxModule/slices/classSlice';
import { getLicenseConsumersByLogin } from '../../../clientLibrary/license/license';
import { useSetAlert } from '../../../common/Alerts';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ClassesResponse } from '../../../clientLibrary/class/classInterfaces';
import { LicenseConsumerResponse } from '../../../clientLibrary/license/licenseInterfaces';
import { UpdateClassInfoForComponent, updateClassInfoObj } from '../../teacher/classes';
import { addLicenseToClass, getClassById, regeneratePinById, removeLicenseFromClass } from '../../../clientLibrary/class/class';
import RefreshIcon from '@material-ui/icons/Refresh';
import { setBackdrop, updateSnackbarError, updateSnackbarSuccess } from '../../../reduxModule/slices/utilitySlice';
import { MailtoData, MailtoLink, MailtoLinkTypes } from '../../helperComponents/mailtoLink';
import { mailToLinkStyles } from './createClassModal';

const useStyles = makeStyles({
  pointer: {
    cursor: "pointer"
  },
  invalidSelect: {
    backgroundColor: "#FFF4E5",
    transition: "background-color 0.2s ease-in"
  }
});
interface LicenseToRemove {
  id: number,
  name: string,
}

const licenseToRemoveObj: LicenseToRemove = {
  id: 0,
  name: ""
}

interface UpdateClassInfoProps {
  type: string,
  modalOpen: boolean,
  classInfo: UpdateClassInfoForComponent,
  setClassInfo(data: UpdateClassInfoForComponent): void,
  handleSubmit(data: ClassesResponse): void,
  handleClose(): void,
  filterValue?: string //Only for teacher dashboard
}

export default function UpdateClassInfo(props: UpdateClassInfoProps) {
  const [isNameInvalid, setIsNameInvalid] = useState(false);

  const [updateClassInfo, setUpdateClassInfo] = useState<UpdateClassInfoForComponent>({ ...updateClassInfoObj });

  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const [licenseToRemove, setLicenseToRemove] = useState<LicenseToRemove>({ ...licenseToRemoveObj });

  const [alert, setSuccessAlert, setWarningAlert, setErrorAlert, setClearAlert] = useSetAlert();

  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [isNotValidTeacher, setIsNotValidTeacher] = useState(false);

  const teachersForSchool = useAppSelector(state => state.school.teachersForSchool);
  const licenseConsumers = useAppSelector(state => state.license.licenseConsumers);
  const primaryContact = useAppSelector(state => state.account.customer?.primaryContact);
  
  const classLicenseUtilization = useAppSelector(state => {
    const classInfo = state.class.searchedClasses.find(val => val.id === props.classInfo.id);
    if (classInfo) {
      return classInfo.licenseUtilization;
    }
  });

  const classPagination = useAppSelector(state => state.class.pagination);

  let getClassesParams: Partial<ClassPagination> = { expired: props.filterValue === "past", per_page: 1000 };

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const mailToClass = mailToLinkStyles();

  const getEbookId = useCallback((licenseName: string): number | undefined => {
    if (licenseConsumers) {
      const eBook = licenseConsumers.find(
        val => val.associatedGroupAllowance.licenseType.description === licenseName
      );
      if (eBook) {
        return eBook.id;
      }
    }
  }, [licenseConsumers]);

  let href: MailtoData = {
    mailTo: '',
    subject: 'Need curriculum title(s) for new class',
    body: ''
}

if (primaryContact) {
    href = {
        mailTo: primaryContact.email,
        subject: `Need curriculum title(s) for new class`,
        body: `Hi ${primaryContact.firstName}, \r\nI am attempting to create a new class but do not have the curriculum title(s) that I need. I would like to request the following curriculum title(s):\r\n`
    }
}

  useEffect(() => {
    setUpdateClassInfo((updateClassInfo: UpdateClassInfoForComponent) => ({
      ...updateClassInfo,
      id: props.classInfo.id || 0,
      name: props.classInfo.name || '',
      startDate: props.classInfo.startDate || '',
      endDate: props.classInfo.endDate || '',
      teacher: props.classInfo.teacher,
      pin: props.classInfo.pin
    }));
    if (props.classInfo.teacher?.login) dispatch(getUserLicenseConsumersByLogin(props.classInfo.teacher.login));
  }, [dispatch, props.classInfo]);

  useEffect(() => {
    const schoolId = props.classInfo.schoolID;
    if (schoolId && props.type === "district") dispatch(findTeachersBySchool(schoolId));
  }, [dispatch, props.classInfo.schoolID, props.type]);

  useEffect(() => {
    if (props.classInfo.teacher) {
      setSelectedTeacher(props.classInfo.teacher.login);
    }
  }, [props.classInfo.teacher])

  const handleClick = () => {
    let valid = true;
    if (updateClassInfo!.name === '') {
      setIsNameInvalid(true);
      valid = false;
    } else {
      setIsNameInvalid(false);
    }

    if (valid) {
      props.handleSubmit({ ...props.classInfo, ...updateClassInfo } as ClassesResponse);
      closeModal();
    }
    return;
  }

  const closeModal = () => {
    setIsNameInvalid(false);
    setUpdateClassInfo({ ...updateClassInfoObj });
    setClearAlert();
    setSelectedTeacher('');
    props.setClassInfo({ ...updateClassInfoObj });
    props.handleClose();
    if (props.type === "district") {
      getClassesParams = { ...classPagination }
    }

    dispatch(searchClasses({
      ...getClassesParams,
      page: classPagination.page || 0,
      per_page: classPagination.per_page || 25,
      sort: classPagination.sort || "",
      sort_reverse: classPagination.sort_reverse || false,
      searchStr: classPagination.searchStr || ""
    }));
  }

  const handleEbookChange = async (licenseId: number) => {
    const license = licenseConsumers.find(val => val.id === licenseId);
    const licenseName = license?.associatedGroupAllowance.licenseType.description;
    if (classLicenseUtilization?.find(eBook => licenseName === eBook.licenseTypeDescription)) {
      setWarningAlert(`${licenseName} has already been selected.`);
    } else {
      setClearAlert();
      try {
        if (license) {
          await addLicenseToClass(props.classInfo.id, license.id);
          setSuccessAlert(`${licenseName} successfully added to class.`);
        }

      } catch (err) {
        setErrorAlert(`${licenseName} failed to add to this class. Please check with your district administrator to find out if you have enough licenses.`)
        if (err instanceof Error) {
          console.log(`Add licenseID:${license?.id} resulted in ${err.message}`);
        }
      }
    }
    dispatch(getAllClasses());
  }

  const handleRemoveEbook = useCallback(async (license) => {
    if (classLicenseUtilization!.length === 1) {
      setWarningAlert(`This is the last curriculum title remaining for ${props.classInfo.name}, so it cannot be removed.`)
    } else {
      setLicenseToRemove(license);
      setDialogMessage(`All students in this class will lose their access to ${license.name}.`)
      setOpenRemoveDialog(true);
    }
  }, [classLicenseUtilization, props.classInfo.name, setWarningAlert]);

  const handleRemoveDecision = async (dialogStatus: boolean) => {
    if (dialogStatus) {
      try {
        if (licenseToRemove != null) {
          const classId = props.classInfo.id;
          const providerId = licenseToRemove.id;
          await removeLicenseFromClass(classId, providerId);
          setSuccessAlert(`${licenseToRemove.name} successfully removed from class.`);
        }
      } catch (err) {
        setErrorAlert(`${licenseToRemove!.name} failed to be removed from the class.`);
        if (err instanceof Error) {
          console.log(`Remove licenseID:${licenseToRemove!.id} resulted in ${err.message}`);
        }
      }
    }
    setLicenseToRemove({ ...licenseToRemoveObj });
    setDialogMessage('')
    setOpenRemoveDialog(false);
    dispatch(getAllClasses());
  }

  const licenseList = useMemo(() => {
    return classLicenseUtilization?.map(license => {
      const licenseName = license.licenseTypeDescription;
      const licenseId = getEbookId(licenseName);
      return <Grid item
        key={licenseId}
        container
        direction="row-reverse"
        justify="flex-end"
        spacing={2}
      >
        <Grid item>
          <Typography variant="body1">{licenseName}</Typography>
        </Grid>
        {
          props.classInfo.teacher?.login === selectedTeacher &&
          <Grid item>
            <DeleteIcon
              color="secondary"
              className={classes.pointer}
              onClick={() => handleRemoveEbook({ id: licenseId, name: licenseName })}
            />
          </Grid>
        }
      </Grid>
    })
  }, [classLicenseUtilization, classes.pointer, props.classInfo.teacher, selectedTeacher, getEbookId, handleRemoveEbook]);

  const isNewTeacherLicenseProvider = async (login: string) => {
    const responseData = await getLicenseConsumersByLogin(login);
    const newTeacherLicenseTypeNames = responseData.map(license => {
      return license.associatedGroupAllowance.licenseType.name.toLowerCase();
    });
    const missingLicenses = [];
    if (classLicenseUtilization) {
      for (let license of classLicenseUtilization) {
        const licenseTypeName = license.licenseTypeName.toLowerCase();
        if (!newTeacherLicenseTypeNames.includes(licenseTypeName)) {
          missingLicenses.push(getLicenseNameOnlyFromLicenseTypeDescription(license.licenseTypeDescription));
        }
      }
      if (missingLicenses.length > 0) {
        return { status: false, missingLicenses }
      }
    }
    return { status: true, missingLicenses: [] };
  }

  const handleSelectTeacher = async (value: string) => {
    const teacher = teachersForSchool.find(val => val.login === value);
    const isLicenseProvider = await isNewTeacherLicenseProvider(value);
    setIsNotValidTeacher(false);
    if (teacher) {
      setUpdateClassInfo({ ...updateClassInfo, teacher });
    }
    setSelectedTeacher(value);
    if (props.classInfo.teacher.login === value) {
      setClearAlert();
    } else if (isLicenseProvider.status) {
      setWarningAlert(`Please save class with new teacher first before editing licenses.`);
    } else {
      setIsNotValidTeacher(true);
      const fullName = getFullName(teacher!);
      const missingLicenseDisplay = arrayOfStringsToSingleStringDisplay(isLicenseProvider.missingLicenses);
      setWarningAlert(`${fullName} does not have permission to distribute licenses for ${props.classInfo.name}. Please add ${missingLicenseDisplay} student licens(es) to the permissions of ${fullName} before you assign this teacher.`)
    }
  }

  const handleRegeneratePin = async () => {
    props.handleClose();
    dispatch(setBackdrop(true));
    try {
      const oldPin = props.classInfo.pin;
      const classData = await getClassById(props.classInfo.id);
      const newPin = await regeneratePinById(classData.enrollmentToken.id);
      await dispatch(searchClasses(getClassesParams));
      dispatch(updateSnackbarSuccess(`The old PIN of ${oldPin} has been successfuly changed to a new PIN of ${newPin}.\nPlease be sure to notify students of the change.`));
    } catch (error) {
      console.log(error);
      dispatch(updateSnackbarError("The PIN did not regenerate. Please try again."));
    }
    dispatch(setBackdrop(false));
  }

  const body = (
    <Grid container spacing={2}>
      <Grid item container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <TextField
            size="small"
            onChange={(e) => setUpdateClassInfo({ ...updateClassInfo, name: e.target.value })}
            label="Class Name"
            required
            variant="outlined"
            error={isNameInvalid}
            fullWidth={true}
            value={updateClassInfo!.name || ""}
          />
        </Grid>
        <Grid item container alignItems="center" justify="flex-start" xl={6} lg={6} md={6} sm={6} xs={12}>
          <Grid item style={{ paddingLeft: 14 }}>
            <Typography variant="h6">
              PIN: {props.classInfo.pin}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              color="primary"
              onClick={handleRegeneratePin}
            >
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DateInput
            label="Start Date"
            required
            format="yyyy/MM/dd"
            handleDateChange={(date: Date) => setUpdateClassInfo({ ...updateClassInfo, startDate: format(date, 'yyyy-MM-dd') })}
            initDate={timezoneOffset(updateClassInfo.startDate || "")}
            fullWidth={true}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <DateInput
            label="End Date"
            required
            format="yyyy/MM/dd"
            handleDateChange={(date: Date) => setUpdateClassInfo({ ...updateClassInfo, endDate: format(date, 'yyyy-MM-dd') })}
            initDate={timezoneOffset(updateClassInfo.endDate || "")}
            minDate={timezoneOffset(updateClassInfo.startDate || "")}
            maxDate={new Date(timezoneOffset(updateClassInfo!.startDate || "").getTime() + FULLYEARINMS)}
            fullWidth={true}
          />
        </Grid>
      </Grid>
      {
        props.type === "district" &&
        <Grid item xs={12}>
          <>
            <InputLabel id="teachers-from-school-label">Teacher</InputLabel>
            {teachersForSchool?.length > 0 &&
              <Select
                variant="outlined"
                labelId="teachers-from-school-label"
                id="teachers-from-school"
                label="Teacher"
                value={selectedTeacher}
                onChange={(e) => handleSelectTeacher(e.target.value as string)}
                fullWidth
                className={isNotValidTeacher ? classes.invalidSelect : ""}
              >
                {
                  teachersForSchool.map(teacher => {
                    return <MenuItem key={teacher.login} value={teacher.login}>
                      {teacher.firstName} {teacher.lastName}
                    </MenuItem>
                  })
                }
              </Select>
            }
          </>
        </Grid>
      }
      {
        props.classInfo.teacher?.login === selectedTeacher &&
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <Select
              defaultValue="Select a curriculum title..."
              onChange={(e) => handleEbookChange(e.target.value as number)}
            >
              <MenuItem disabled value="Select a curriculum title...">Select a curriculum title...</MenuItem>
              {
                licenseConsumers.map((license: LicenseConsumerResponse) => {
                  const description = license.associatedGroupAllowance.licenseType.description;
                  if (description.toLowerCase().includes('student')) {
                    return <MenuItem key={license.associatedGroupAllowance.id}
                      value={license.id}
                    >
                      {license.associatedGroupAllowance.licenseType.description}
                    </MenuItem>
                  }
                  return null;
                })
              }
               <MenuItem value={licenseConsumers.length} onClick={() => {closeModal()}}>
                    <MailtoLink 
                        variant="text" 
                        label="Not seeing a curriculum title? Request more here."
                        typographyVariant='inherit'
                        type={MailtoLinkTypes.Button}
                        href={href}
                        color="primary"
                        className={mailToClass.mailToLink}
                        style={{width: "100%", display: "flex", justifyContent: "flex-start"}}
                    />
                </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      }
      <Grid item xs={12}>
        {licenseList}
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {
          alert.message &&
          <Alert severity={alert.severity}>{alert.message}</Alert>
        }
      </Grid>
    </Grid>
  );

  return (
    <>
      <BaseModalContainer
        id="update-class"
        buttonName="Save"
        buttonDisabled={isNotValidTeacher}
        modalTitle="Update Class"
        handleClose={closeModal}
        modalOpen={props.modalOpen}
        handleAction={handleClick}
      >
        {body}
      </BaseModalContainer>
      {/* Confirm box for Removing A Book From A Class */}
      <DialogBox
        open={openRemoveDialog}
        message={dialogMessage}
        title={`Remove ${licenseToRemove.name}`}
        handleClose={(dialogStatus: boolean) => handleRemoveDecision(dialogStatus)}
      />
    </>
  );
}