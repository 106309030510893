import React from "react";
import { LicenseProviderResponse } from "../../../clientLibrary/license/licenseInterfaces";
import { getLicenseNameOnlyFromLicenseProvider } from "../../../common/GeneralFunction";
import { format } from "date-fns";
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";

interface TeacherLicenseTableProps {
    licenses?: LicenseProviderResponse[] | null;
}

const useStyles = makeStyles({
    tableContainer: {
        paddingBottom: "20px",
    },
    tableHeader: {
        fontWeight: "bold",
    },
    tableRow: {
        "&:last-child": {
            borderBottom: "2px solid white",
        }
    },
})

export default function TeacherLicenseTable(props: TeacherLicenseTableProps) {
    const classes = useStyles();
    const { licenses } = props;

    return (
            <TableContainer className={classes.tableContainer}>
                <Table style={{borderColor: 'rgba(0, 0, 0, 0.87)'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader} align="left">Name</TableCell>
                            <TableCell className={classes.tableHeader} align="left">Created</TableCell>
                            <TableCell className={classes.tableHeader} align="left">Expires</TableCell>
                            <TableCell className={classes.tableHeader} align="left">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {licenses && licenses?.map((license: LicenseProviderResponse) => {
                            return (
                                <TableRow key={license.id} className={classes.tableRow}>
                                    <TableCell align="left" size="small">{getLicenseNameOnlyFromLicenseProvider(license.name)}</TableCell>
                                    <TableCell align="left" size="medium">{format(license.createdDate, "yyyy-MM-dd")}</TableCell>
                                    <TableCell align="left" size="medium">{license.expiration}</TableCell>
                                    <TableCell align="left" size="small">{license.expired ? "Expired" : "Current"}</TableCell>
                                </TableRow>
                            )
                        })}
                        {(!licenses || licenses.length === 0) && <TableRow><TableCell align="center">No licenses found</TableCell></TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
    )
}