import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slices/authSlice';
import parentSlice from './slices/parentSlice';
import accountSlice from './slices/accountSlice';
import classSlice from './slices/classSlice';
import contractSlice from './slices/contractSlice';
import licenseSlice from './slices/licenseSlice';
import schoolSlice from './slices/schoolSlice';
import userSlice from './slices/userSlice';
import utilitySlice from './slices/utilitySlice';
import districtSlice from './slices/districtSlice';

export const store = configureStore({ reducer: 
    { 
    auth: authSlice,
    parent: parentSlice,
    utility: utilitySlice,
    account: accountSlice,
    contract: contractSlice,
    license: licenseSlice,
    school: schoolSlice,
    class: classSlice,
    user: userSlice,
    district: districtSlice,
    }
});

export default { store };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch