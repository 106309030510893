import React from "react";
import DistrictLicenses from "../licenses";
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

const Content = () => {
  return (
    <div style={{ paddingBottom: "60px" }}>
      <Switch>
        <Route exact path="/student-licenses/reports" render={() => <DistrictLicenses />} />
        <Route path="*" render={() => (<Redirect to="/student-licenses/reports" />)} />
      </Switch>
    </div>
  );
};

export default React.memo(Content);
