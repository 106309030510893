import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BaseModalContainer from "./baseModalContainer";
import { differenceInDays, format, isValid, parseISO } from 'date-fns';
import {
    TextField,
    FormControl,
    Grid,
    MenuItem,
    Select,
    makeStyles,
} from '@material-ui/core';

import { getUserLicenseConsumersByLogin } from '../../../reduxModule/slices/licenseSlice';
import { findTeachersBySchool } from '../../../reduxModule/slices/schoolSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { CreateClassPayload } from '../../../clientLibrary/class/classInterfaces';
import { SchoolResponse } from '../../../clientLibrary/school/schoolInterfaces';
import { UserResponse } from '../../../clientLibrary/user/userInterfaces';
import { UserType } from '../../../clientLibrary/Enums';
import DateInput from '../../helperComponents/dateInput';
import { timezoneOffset, getLaterDate } from '../../../common/GeneralFunction';
import { FULLYEARINMS } from '../../../common/Constants';
import { MailtoData, MailtoLink, MailtoLinkTypes } from '../../helperComponents/mailtoLink';

const createClassObj: CreateClassPayload = {
    name: '',
    startDate: '',
    endDate: '',
    schoolID: 0,
    teacher: {
        login: ''
    },
    licenseConsumerID: 0,
    description: ''
};

interface ClassErrorObject {
    name: boolean,
    startDate: boolean,
    endDate: boolean,
    licenseConsumerID: boolean,
    schoolID: boolean,
    userID: boolean
}

const errorObj: ClassErrorObject = {
    name: false,
    startDate: false,
    endDate: false,
    licenseConsumerID: false,
    schoolID: false,
    userID: false
}

interface CreateClassModalProps {
    type: UserType,
    handleSubmit(payload: CreateClassPayload): void,
    handleClose(): void,
    modalOpen: boolean
}

interface TeacherDisplayInfo {
    isSchoolSelected: boolean,
    value: UserResponse | null
}

export const mailToLinkStyles = makeStyles({
    mailToLink: {
        textTransform: "none",
        color: '#1884df',
        padding: '0px',
        marginLeft: "-3px",
        fontSize: '16px',
        '&:hover': {
            color: "#1884df",
            textDecoration: "underline",
            backgroundColor: "transparent"
        }
    }
});

export default function CreateClassModal(props: CreateClassModalProps) {
    const mailToClass = mailToLinkStyles();
    const [newClassInfo, setNewClassInfo] = useState<CreateClassPayload>(createClassObj);
    const [formErrors, setFormErrors] = useState(errorObj);
    const [selectedLicense, setSelectedLicense] = useState(-1);

    const [schoolInfo, setSchoolInfo] = useState<SchoolResponse | null>(null);
    const [teacherSchoolInfo, setTeacherSchoolInfo] = useState<SchoolResponse | null>(null);
    const [teacherDisplayInfo, setTeacherDisplayInfo] = useState<TeacherDisplayInfo>({ isSchoolSelected: false, value: null });

    const schools = useAppSelector(state => state.school.schools);
    const teachersForSchool = useAppSelector(state => state.school.teachersForSchool);
    const licenseConsumers = useAppSelector(state => state.license.licenseConsumers);
    const primaryContact = useAppSelector(state => state.account.customer?.primaryContact);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (schools?.length > 0) {
            setTeacherSchoolInfo(schools[0])
        }
    }, [schools]);

    let href: MailtoData = {
        mailTo: '',
        subject: 'Need curriculum title(s) for new class',
        body: ''
    }

    if (primaryContact) {
        href = {
            mailTo: primaryContact.email,
            subject: `Need curriculum title(s) for new class`,
            body: `Hi ${primaryContact.firstName}, \r\nI am attempting to create a new class but do not have the curriculum title(s) that I need. I would like to request the following curriculum title(s):\r\n`
        }
    }

    const handleClassValidation = () => {
        let valid = true;
        let localFormErrObj: ClassErrorObject = errorObj;
        (Object.keys(errorObj) as Array<keyof typeof errorObj>).forEach(field => {
            if (field !== "userID" && field !== "schoolID"
                && (newClassInfo[field] === "" || newClassInfo[field] === 0
                )) {
                valid = false;
                localFormErrObj = { ...localFormErrObj, [field]: true }
            }
        });

        if (!isValid(parseISO(newClassInfo.startDate))) {
            localFormErrObj = { ...localFormErrObj, startDate: true }
            valid = false;
        }

        if (!isValid(parseISO(newClassInfo.endDate))) {
            localFormErrObj = { ...localFormErrObj, endDate: true }
            valid = false;
        } else {
            if (newClassInfo.startDate !== null && newClassInfo.endDate !== null) {
                const endDate = new Date(newClassInfo.endDate);
                const startDate = new Date(newClassInfo.startDate);
                if (!(differenceInDays(endDate, startDate) < 366)) {
                    localFormErrObj = { ...localFormErrObj, endDate: true }
                    valid = false;
                }
            }
        }

        if (props.type === UserType.District) {
            if (selectedLicense === -1) {
                localFormErrObj = { ...localFormErrObj, licenseConsumerID: true }
                valid = false;
            }
            if (schoolInfo === null) {
                localFormErrObj = { ...localFormErrObj, schoolID: true }
                valid = false;
            }
            if (teacherDisplayInfo.value === null && teacherDisplayInfo.isSchoolSelected) {
                localFormErrObj = { ...localFormErrObj, userID: true }
                valid = false;
            }
        }

        setFormErrors(localFormErrObj);

        if (!valid) {
            return;
        } else {
            createClass();
        }
    }

    const createClass = () => {
        let payload: CreateClassPayload = {
            name: newClassInfo.name,
            description: newClassInfo.name,
            startDate: newClassInfo.startDate,
            endDate: newClassInfo.endDate,
            licenseConsumerID: newClassInfo.licenseConsumerID,
            schoolID: 0,
            teacher: {
                login: ""
            }
        };

        if (props.type === UserType.District && schoolInfo !== null && teacherDisplayInfo.value !== null) {
            payload = {
                ...payload,
                schoolID: schoolInfo.id,
                teacher: {
                    login: teacherDisplayInfo.value.login
                }
            }
        }

        if (props.type === UserType.Teacher && teacherSchoolInfo !== null) {
            payload = {
                ...payload,
                schoolID: teacherSchoolInfo?.id
            }
        }
        resetModal();
        props.handleSubmit(payload);
    }

    const closeModal = () => {
        resetModal();
        props.handleClose();
    }

    const resetModal = () => {
        setFormErrors(errorObj);
        setNewClassInfo(createClassObj);
        setSchoolInfo(null);
        setSelectedLicense(-1);
        setTeacherDisplayInfo({ isSchoolSelected: false, value: null });
    }

    //Find teacher for class
    const handleFindTeacher = (_: any, schoolResponse: SchoolResponse | null) => {
        if (schoolResponse) {
            dispatch(findTeachersBySchool(schoolResponse.id));
            setTeacherDisplayInfo({ value: null, isSchoolSelected: true });
        } else {
            setTeacherDisplayInfo({ isSchoolSelected: false, value: null });
        }
        setSchoolInfo(schoolResponse);
    }

    const handleSetTeacherInfo = (teacherInfo: TeacherDisplayInfo, userResponse: UserResponse) => {
        if (userResponse) {
            dispatch(getUserLicenseConsumersByLogin(userResponse.login));
            setTeacherDisplayInfo({ ...teacherInfo, value: userResponse })
        }
        setSelectedLicense(-1);
    }

    const handleSelectLicense = (licenseConsumerID: number) => {
        setSelectedLicense(licenseConsumerID);
        setNewClassInfo({
            ...newClassInfo,
            licenseConsumerID
        });
    }

    const body = (
        <div id="simple-modal-description">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        size="small"
                        onChange={(e) => setNewClassInfo({ ...newClassInfo, name: e.target.value, description: e.target.value })}
                        fullWidth
                        label="Class Name"
                        required
                        variant="outlined"
                        error={formErrors.name}
                    />
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <DateInput
                            label="Start Date"
                            required
                            format="yyyy/MM/dd"
                            handleDateChange={(date: Date) => setNewClassInfo({ ...newClassInfo, startDate: format(date, 'yyyy-MM-dd') })}
                            initDate={null}
                            fullWidth
                            errStatus={formErrors.startDate}
                        />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <DateInput
                                label="End Date"
                                required
                                disabled={newClassInfo.startDate === ""}
                                format="yyyy/MM/dd"
                                handleDateChange={(date: Date) => setNewClassInfo({ ...newClassInfo, endDate: format(date, 'yyyy-MM-dd') })}
                                initDate={null}
                                minDate={getLaterDate(newClassInfo.startDate)}
                                maxDate={new Date(timezoneOffset(newClassInfo.startDate).getTime() + FULLYEARINMS)}
                                fullWidth
                                errStatus={formErrors.endDate && newClassInfo.startDate !== ""}
                            />
                    </Grid>
                </Grid>
                {
                    props.type === "district" &&
                    <>
                        <Grid item xs={12}>
                            <Autocomplete
                                id="demo-simple-select-outlined"
                                fullWidth
                                size="small"
                                options={schools}
                                value={schoolInfo}
                                getOptionLabel={(option) => (option && option.name) || ""}
                                onChange={handleFindTeacher}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="School"
                                        required
                                        variant="outlined"
                                        size="small"
                                        error={formErrors.schoolID}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                disabled={!teacherDisplayInfo.isSchoolSelected}
                                id="demo-simple-select-outlined"
                                fullWidth
                                size="small"
                                value={teacherDisplayInfo.value}
                                options={teachersForSchool}
                                getOptionLabel={(option) => (option && option.firstName && option.lastName && `${option.firstName} ${option.lastName}`) || ''}
                                onChange={(_, value) => value && handleSetTeacherInfo(teacherDisplayInfo, value)}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Teacher"
                                        required
                                        variant="outlined"
                                        size="small"
                                        error={formErrors.userID}
                                    />
                                }
                            />
                        </Grid>
                    </>
                }
                <Grid item xs={12} >
                    <FormControl fullWidth variant="outlined">
                        <Select id="select" required
                            value={selectedLicense}
                            onChange={(e) => handleSelectLicense(parseInt(e.target.value as string))}
                            error={formErrors.licenseConsumerID}
                        >
                            <MenuItem disabled value={-1}>Select a curriculum title...</MenuItem>
                            {
                                licenseConsumers.map(license => {
                                    const description = license.associatedGroupAllowance.licenseType.description;
                                    if (description.toLowerCase().includes("student")) {
                                        return <MenuItem key={license.id}
                                            value={license.id}
                                        >
                                            {description}
                                        </MenuItem>
                                    }
                                    return null;
                                })
                            }
                            <MenuItem value={licenseConsumers.length} onClick={() => {closeModal()}}>
                                <MailtoLink 
                                    variant="text" 
                                    label="Not seeing a curriculum title? Request more here."
                                    typographyVariant='inherit'
                                    type={MailtoLinkTypes.Button}
                                    href={href}
                                    color="primary"
                                    className={mailToClass.mailToLink}
                                    style={{width: "100%", display: "flex", justifyContent: "flex-start"}}
                                />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </div >
    );

    return (
        <BaseModalContainer
            id="create-class"
            buttonName="Save"
            modalTitle="Create New Class"
            handleClose={closeModal}
            modalOpen={props.modalOpen}
            handleAction={handleClassValidation}
        >
            {body}
        </BaseModalContainer>
    );
}
