import React from "react";
// import "./../../../App.scss";
import "../teacher.scss"
import Header from "../../commonLayouts/header/index";
import Footer from "../../commonLayouts/footer/index";
import Content from "./content";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from '../../commonLayouts/errorBoundary';

function TeacherHome() {
  return (
    <div className="App">
      <div className="w-100 h-100">
        {/* Tab Navigation section  */}
        <BrowserRouter>
          <ErrorBoundary>
            <Header/>
            <Content/>
            <Footer/>
          </ErrorBoundary>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default TeacherHome;
