import React from "react";
import { 
    Grid, 
    Typography,
    makeStyles,
    useMediaQuery
} from "@material-ui/core";
import { currentYear, daysRemainingUntilExpiration } from "../../../constants";
import { ELS_PRIMARY_BLUE_COLOR, GRAY_BACKGROUND_COLOR } from "./utilizationReport";
import { NAVY_TEXT_COLOR } from "./licenseUtilizationRow";
import { LicenseDataProps } from "./licenseUtilizationRow";
import { ReactComponent as StudentIcon } from "./../../../assets/images/student-3.svg";
import { ReactComponent as TeacherIcon } from "./../../../assets/images/teacher-2.svg";
import LicenseTitleTooltip from "./licenseTitleTooltip";

const useStyles = makeStyles({
    container: {
        borderTop: `1px solid ${GRAY_BACKGROUND_COLOR}`,
        boxSizing: 'border-box'
    },
    text: {
        color: NAVY_TEXT_COLOR,
        textAlign: 'left'
    },
    iconText: {
        color: NAVY_TEXT_COLOR,
        fontSize: '0.75rem'
    }
})

export default function LicenseExpirationRow(props: LicenseDataProps) {
    const classes = useStyles();
    const expiringLicenseTotal = props.expiration[currentYear];
    const mobile = useMediaQuery('(max-width: 600px)');

    return (
        <Grid 
            container
            justify="space-between"
            alignItems="center" 
            className={classes.container}
            style={mobile ? {padding: '15px 30px'} : {padding: '25px 30px'}}>
            <Grid item xl={8} lg={6} md={6} sm={6} xs={12} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '20px', overflow: 'hidden'}}>
                <Grid item style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {props.licenseType.includes('student') ? 
                    <><StudentIcon stroke={ELS_PRIMARY_BLUE_COLOR} width='30px'/>
                    <Typography className={classes.iconText}>Student</Typography></>
                    :
                    <><TeacherIcon stroke={ELS_PRIMARY_BLUE_COLOR} width='30px'/>
                    <Typography className={classes.iconText}>Teacher</Typography></>
                }
                </Grid>
                <LicenseTitleTooltip licenseName={props.licenseName}/>
            </Grid>
            <Grid item>
                {daysRemainingUntilExpiration > 0 ?
                <Typography className={classes.text} variant="subtitle1" style={mobile ? {width: 'auto'} : {width: '325px'}}>
                    {expiringLicenseTotal} / {props.licensesTotal} licens{expiringLicenseTotal === 1 ? "e" : "es"} expir{expiringLicenseTotal === 1 ? "es" : "e"} in {daysRemainingUntilExpiration} days
                </Typography>
                :
                <Typography className={classes.text} variant="subtitle1" style={mobile ? {width: 'auto'} : {width: '280px'}}>
                    {props.totalExpiredLicenseCount} licens{props.totalExpiredLicenseCount === 1 ? "e" : "es"} have recently expired.
                    <br/>
                    There are {props.licensesTotal} licens{props.licensesTotal === 1 ? "e" : "es"} remaining.
                </Typography>
                }
            </Grid>
        </Grid>
    )
}
