import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserInfoForAccount, searchUserDetailsBySearchString } from "../../clientLibrary/auth/auth";
import { UserResponse } from "../../clientLibrary/user/userInterfaces";
export interface AuthState {
    userRole: {
        login: string,
        roles: string[],
        firstName: string,
        lastName: string,
        eWorkspaceEnabled: boolean
    }
    searchUserDetails: SearchUserDetailsState,
}

interface SearchUserDetailsState {
    users: UserResponse[],
    isLoading: boolean,
    error: string,
}

const initialState: AuthState = {
    userRole: {
        login: "",
        roles: [],
        firstName: "",
        lastName: "",
        eWorkspaceEnabled: false
    },
    searchUserDetails: {
        users: [],
        isLoading: false,
        error: "",
    }
}

export const getUserRole = createAsyncThunk(
    'auth/getUserRole',
    async () => {
        try {
            const userRoleData = await getUserInfoForAccount();
            return userRoleData;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }
);

export const searchUserDetails = createAsyncThunk(
    'auth/searchUserDetails',
    async (searchStr: string) => {
        try {
            const userDetailData = await searchUserDetailsBySearchString(searchStr);
            return userDetailData;
        } catch (error) {
            console.log(error)
            throw error;
        }
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        clearUserDetails: (state) => {
            state.searchUserDetails.users = [];
            state.searchUserDetails.isLoading = false;
            state.searchUserDetails.error = "";
        },
        clearData: (state) => {
            state.userRole.login = "";
            state.userRole.roles = [];
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getUserRole.fulfilled, (state, action) => {
            state.userRole = action.payload;
        })
        .addCase(getUserRole.rejected, (state) => {
            state.userRole.login = "";
            state.userRole.roles = [];
            state.userRole.firstName = "";
            state.userRole.lastName = "";
            state.userRole.eWorkspaceEnabled = false;
        })
        .addCase(searchUserDetails.pending, (state) => {
            state.searchUserDetails.users = [];
            state.searchUserDetails.isLoading = true;
        })
        .addCase(searchUserDetails.fulfilled, (state, action) => {
            state.searchUserDetails.users = action.payload;
            state.searchUserDetails.isLoading = false;
        })
        .addCase(searchUserDetails.rejected, (state) => {
            state.searchUserDetails.users = [];
            state.searchUserDetails.isLoading = false;
            state.searchUserDetails.error = 'There was an error in retrieving search data. Please try again.'
        });
    }
});

export const { clearUserDetails, clearData } = authSlice.actions;

export default authSlice.reducer;