import { capitalize } from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getClassById, getClassesByTeacherLogin, getEnrolledStudentsForClass, removeStudentByClassIdAndLogin } from "../../clientLibrary/class/class";
import { ClassUserResponse } from "../../clientLibrary/class/classInterfaces";
import { checkIfEmailExists, checkIfLoginExists, createFacultyUser, createStudentUser, deleteUserByLogin, enrollStudentByLoginAndToken, searchStudentsForTeacher, getTotalUserCount, getUserInfoByLogin, searchUsers, updateUserInfo, getUserByIdentifier } from "../../clientLibrary/user/user";
import { CreateBaseUserPayload, SearchUsersParams, UpdateUserPayload, UserResponse } from "../../clientLibrary/user/userInterfaces";
import { resetProgressData, setBackdrop, setDeterminateProgress, setProgressCurrentThunk, setProgressTitle, setProgressTotal, updateSnackbarError, updateSnackbarSuccess } from "./utilitySlice";
import { getFullName, updateSnackbarMessageUsingResults } from '../../common/GeneralFunction';
import { getSchoolIdFromName } from '../UtilityFunctions';
import { SchoolResponse } from '../../clientLibrary/school/schoolInterfaces';
import { DisplayCategory } from '../../clientLibrary/GeneralInterfaces';
import { getLicenseAdminGroupsByLogin, getLicenseProvidersByLogin } from '../../clientLibrary/license/license';
import { DistrictRoles } from '../../clientLibrary/Enums';

export interface UserState {
    pagination: UserPagination,
    users: UserResponse[],
    filterUserCount: number,
    userCount: number,
    editUserDetails: UserResponse | null,
    searchedStudentsWithTeacherClasses: UserResponse[],
    studentsEnrolledToClass: Record<number, ClassUserResponse[]>,
    isUserExists: ExistenceData,
    isEmailExists: ExistenceData
}

interface UserPagination {
    searchStr: string,
    page: number,
    per_page: number,
    sort: string,
    sort_reverse: boolean,
    schoolRole: DistrictRoles
}

export interface EnrollOrRemoveStudentPayload {
    classId: number,
    login: string,
    transfer?: boolean
}

interface ExistenceData {
    status: boolean,
    loading: boolean
}

interface AddBulkUsersPayload {
    bulkUserData: BulkUserData[],
    customerId: number,
    schools: SchoolResponse[]
}

interface AddBulkStudentsPayload {
    bulkStudentData: BulkStudentData[],
    customerId: number,
    schoolId: number
}

interface DeleteBulkUsersData {
    login: string,
    name: string,
    roles: string[]
}

export interface BulkUserData {
    email: string,
    firstName: string,
    lastName: string,
    school: string,
    userType: string,
    username?: string
}

export interface BulkStudentData {
    firstName: string,
    lastName: string,
    email: string,
    classId: string
}

export const initialState: UserState = {
    pagination: {
        searchStr: "",
        page: 0,
        per_page: 25,
        sort: "",
        sort_reverse: false,
        schoolRole: DistrictRoles.Student
    },
    users: [],
    filterUserCount: 0,
    userCount: 0,
    editUserDetails: null,
    searchedStudentsWithTeacherClasses: [],
    studentsEnrolledToClass: {},
    isEmailExists: { status: false, loading: false },
    isUserExists: { status: false, loading: false }
}

export const createNewStudent = createAsyncThunk(
    'user/createNewStudent',
    async (studentData: CreateBaseUserPayload, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await createStudentUser(studentData);
            thunkAPI.dispatch(updateSnackbarSuccess('Student created successfully.'));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Creating student failed. Please try again.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const createNewFaculty = createAsyncThunk(
    'user/createNewFaculty',
    async (facultyData: CreateBaseUserPayload, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await createFacultyUser(facultyData);
            thunkAPI.dispatch(updateSnackbarSuccess('Faculty created successfully.'));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Creating faculty failed. Please try again.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const getUserDetails = createAsyncThunk(
    'user/getUserDetails',
    async (params: SearchUsersParams, thunkAPI) => {
        try {
            const searchUsersResponse = await searchUsers(params);
            await thunkAPI.dispatch(getUserCount());
            return searchUsersResponse;
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to fetch user data.'));
            throw error;
        }
    }
);

const getUserCount = createAsyncThunk(
    'user/getUserCount',
    async (_, thunkAPI) => {
        try {
            const userCountResponse = await getTotalUserCount();
            return userCountResponse;
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to get user counts.'));
            throw error;
        }
    }
);

export const getEditUserDetails = createAsyncThunk(
    'user/getEditUserDetails',
    async (login: string, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            const userInfoResponseData = await getUserInfoByLogin(login);
            thunkAPI.dispatch(setBackdrop(false));
            return userInfoResponseData;
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to get the data for editing a user.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (userInfo: UpdateUserPayload, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await updateUserInfo(userInfo);
            thunkAPI.dispatch(updateSnackbarSuccess('User updated successfully.'));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Updating user failed. Please try again.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const deleteUser = createAsyncThunk(
    'user/deleteUser',
    async (login: string, thunkAPI: any) => {
        thunkAPI.dispatch(setBackdrop(true));
        const userRoles = thunkAPI.getState().user.users.find((user: UserResponse) => user.login === login).roles;
        let errorMsg: string = "";

        if ((await getLicenseAdminGroupsByLogin(login)).length > 0) {
            errorMsg = ` Failed to delete since user is a license administrator.`;
        } else if ((await getLicenseProvidersByLogin(login)).length > 0) {
            errorMsg = `Failed to delete since user currently provides licenses to other users.`
        } else if (userRoles.includes("FACULTY") && (await getClassesByTeacherLogin(login)).length > 0) {
            errorMsg = `Failed to delete the teacher user since they have at least one current class.`
        }

        if (errorMsg) {
            thunkAPI.dispatch(updateSnackbarError(errorMsg));
            thunkAPI.dispatch(setBackdrop(false));
            return;
        }

        try {
            await deleteUserByLogin(login);
            thunkAPI.dispatch(updateSnackbarSuccess(`The user was successfully deleted.`));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError(`Failed to delete the user.`));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

export const searchStudentsWithTeacherClasses = createAsyncThunk(
    'user/searchStudentsWithTeacherClasses',
    async (searchStr: string, thunkAPI: any) => {
        try {
            const login: string = thunkAPI.getState().auth.userRole.login;
            const studentsByTeacherResponseData = await searchStudentsForTeacher(login, searchStr);
            return studentsByTeacherResponseData;
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Not able to fetch student details.'));
            throw error;
        }
    }
);

export const getEnrolledStudentsByClassId = createAsyncThunk(
    'user/getEnrolledStudentsByClassId',
    async (classId: number, thunkAPI: any) => {
        try {
            const enrolledStudents = await getEnrolledStudentsForClass(classId);
            return { classId, enrolledStudents }
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to get enrolled student list'));
            throw error;
        }
    }
);

export const enrollStudent = createAsyncThunk(
    'user/createAsyncThunk',
    async (payload: EnrollOrRemoveStudentPayload) => {
        try {
            const tokenResponse = await getClassById(payload.classId);
            const responseStatus = await enrollStudentByLoginAndToken(payload.login, tokenResponse.enrollmentToken.token);
            return responseStatus;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
);

export const removeStudentFromClass = createAsyncThunk(
    'user/removeStudentFromClass',
    async (payload: EnrollOrRemoveStudentPayload, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        try {
            await removeStudentByClassIdAndLogin(payload.classId, payload.login);
            if (!payload.transfer) thunkAPI.dispatch(updateSnackbarSuccess('Student removed successfully.'));
            thunkAPI.dispatch(setBackdrop(false));
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError('Failed to remove student.'));
            thunkAPI.dispatch(setBackdrop(false));
            throw error;
        }
    }
);

interface TransferStudentPayload {
    oldClassId: number,
    login: string,
    newClassId: number
}

export const transferStudent = createAsyncThunk(
    'user/transferStudent',
    async (payload: TransferStudentPayload, thunkAPI) => {
        try {
            await thunkAPI.dispatch(removeStudentFromClass({login: payload.login, classId: payload.oldClassId, transfer: true}));
            thunkAPI.dispatch(setBackdrop(true));
            await thunkAPI.dispatch(enrollStudent({login: payload.login, classId: payload.newClassId}));
            thunkAPI.dispatch(updateSnackbarSuccess('Student transferred successfully.'));
        }
        catch (error) {
            console.error(error);
            thunkAPI.dispatch(updateSnackbarError('Failed to transfer student.'));
        }
        thunkAPI.dispatch(setBackdrop(false));
    }
);

export const checkUsernameExists = createAsyncThunk(
    'user/checkUsernameExists',
    async (login: string, thunkAPI) => {
        thunkAPI.dispatch(setUserExistsInfo({ status: false, loading: true }));
        try {
            const loginExistsResponseData = await checkIfLoginExists(login);
            thunkAPI.dispatch(setUserExistsInfo({ status: loginExistsResponseData, loading: false }));
        } catch (error) {
            thunkAPI.dispatch(setUserExistsInfo({ status: false, loading: false }));
            throw error;
        }
    }
);

export const checkEmailExists = createAsyncThunk(
    'user/checkEmailExists',
    async (email: string, thunkAPI) => {
        thunkAPI.dispatch(setEmailExistsInfo({ status: false, loading: true }));
        try {
            const emailExistsResponseData = await checkIfEmailExists(email);
            thunkAPI.dispatch(setEmailExistsInfo({ status: emailExistsResponseData, loading: false }));
        } catch (error) {
            thunkAPI.dispatch(setEmailExistsInfo({ status: false, loading: false }));
            throw error;
        }
    }
);

export const addBulkUsers = createAsyncThunk(
    'user/addBulkUsers',
    async (payload: AddBulkUsersPayload, thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        const successfullyAddedUsers: string[] = [];
        const failedToAddUsers: string[] = [];
        for (let userToAdd of payload.bulkUserData) {
            const userType = userToAdd.userType.toLowerCase();
            const capitalizedUserType = capitalize(userToAdd.userType);
            const schoolId = getSchoolIdFromName(payload.schools, userToAdd.school);
            const populatedUserInfo: CreateBaseUserPayload = {
                districtID: payload.customerId,
                districtRole: capitalizedUserType,
                schoolID: schoolId,
                schoolRole: capitalizedUserType,
                globalCPMRole: userType === "teacher" ? "faculty" : "student",
                firstName: userToAdd.firstName,
                lastName: userToAdd.lastName,
                email: userToAdd.email,
                login: userToAdd.username ? userToAdd.username : userToAdd.email,
                password: userToAdd.username ? userToAdd.username : userToAdd.email,
                confirmPassword: userToAdd.username ? userToAdd.username : userToAdd.email,
                langKey: "en",
            }

            const fullNameForDisplay = getFullName(userToAdd);

            try {
                if (userType === "teacher") {
                    await createFacultyUser(populatedUserInfo);
                } else if (userType === "student") {
                    await createStudentUser(populatedUserInfo);
                }
                successfullyAddedUsers.push(fullNameForDisplay);
            } catch (error) {
                console.log(error);
                failedToAddUsers.push(fullNameForDisplay);
            }
        }

        thunkAPI.dispatch(setBackdrop(false));

        const displayCategoryArr: DisplayCategory[] = [
            {
                arrayType: 'success',
                entities: successfullyAddedUsers,
                message: ` user(s) successfully added. Username and temporary password is your first and last name combined with no spaces. `
            },
            {
                arrayType: 'failure',
                entities: failedToAddUsers,
                message: ` user(s) failed to add.  Please check your file or add the user(s) individually using the "+ New" button `
            }
        ];
        thunkAPI.dispatch(updateSnackbarMessageUsingResults(displayCategoryArr));
    }
);

export const addBulkStudents = createAsyncThunk(
    'user/addBulkStudents',
    async (studentPayload: AddBulkStudentsPayload, thunkAPI) => {
        thunkAPI.dispatch(setDeterminateProgress(true));
        const { customerId, schoolId, bulkStudentData } = studentPayload;

        const successfullyAddedStudents: string[] = [];
        const failedToAddStudents: string[] = [];
        const total = bulkStudentData.length;
        thunkAPI.dispatch(setProgressTitle("students"));
        thunkAPI.dispatch(setProgressTotal(total));

        for (let student of bulkStudentData) {
            const fullNameForDisplay = getFullName(student);
            try {
                const studentExists = await checkIfEmailExists(student.email);
                let login = "";
                if (!studentExists) {
                    const studentInfo: CreateBaseUserPayload = {
                        districtID: customerId,
                        districtRole: "Student",
                        email: student.email,
                        firstName: student.firstName,
                        lastName: student.lastName,
                        login: student.email,
                        password: student.email,
                        schoolID: schoolId,
                        schoolRole: "Student",
                        langKey: "en",
                        globalCPMRole: "student"
                    }
                    await createStudentUser(studentInfo);
                    login = studentInfo.login;
                } else {
                    const user = await getUserByIdentifier(student.email);
                    if (user.data != null) {
                        login = user.data.login;
                    }
                }
                const responseStatus = await thunkAPI.dispatch(enrollStudent({ classId: +student.classId, login: login }));
                if (responseStatus.payload === 200) {
                    successfullyAddedStudents.push(fullNameForDisplay);
                } else {
                    failedToAddStudents.push(fullNameForDisplay);
                }
                thunkAPI.dispatch(setProgressCurrentThunk(currentCount => currentCount + 1));
            } catch (error) {
                failedToAddStudents.push(fullNameForDisplay);
                console.log(error);
            }
        }

        const displayCategoryArr = [
            {
                arrayType: 'success',
                entities: successfullyAddedStudents,
                message: ` student(s) successfully enrolled.  The default password is their email. Please have students log in and change their password. Password can be reset in the student table below.`,
            },
            {
                arrayType: 'failure',
                entities: failedToAddStudents,
                message: ` student(s) failed to add and/or enroll.  Please check your file or add the student(s) individually using the "Add" button. `
            },
        ]

        thunkAPI.dispatch(updateSnackbarMessageUsingResults(displayCategoryArr));
        thunkAPI.dispatch(resetProgressData());
    }
);

export const deleteBulkUsers = createAsyncThunk(
    'user/deleteBulkUsers',
    async (userPayload: DeleteBulkUsersData[], thunkAPI) => {
        thunkAPI.dispatch(setBackdrop(true));
        const successfullyDeletedUsers: string[] = [];
        const failedToDeleteUsers: string[] = [];
        const licenseAdminUsers: string[] = [];
        const teacherWithClassesUsers: string[] = [];
        const providesLicensesUsers: string[] = [];

        for (let user of userPayload) {
            if ((await getLicenseAdminGroupsByLogin(user.login)).length > 0) {
                licenseAdminUsers.push(user.name);
                continue;
            }
            if ((await getLicenseProvidersByLogin(user.login)).length > 0) {
                providesLicensesUsers.push(user.name);
                continue;
            }
            if (user.roles.includes("FACULTY") && (await getClassesByTeacherLogin(user.login)).length > 0) {
                teacherWithClassesUsers.push(user.name);
                continue;
            }

            try {
                await deleteUserByLogin(user.login);
                successfullyDeletedUsers.push(user.name);
            } catch (error) {
                console.log(error);
                failedToDeleteUsers.push(user.name);
            }
        }

        thunkAPI.dispatch(setBackdrop(false));

        const displayCategoryArr: DisplayCategory[] = [
            {
                arrayType: 'success',
                entities: successfullyDeletedUsers,
                message: ` user(s) successfully deleted. `
            },
            {
                arrayType: 'failure',
                entities: failedToDeleteUsers,
                message: ` user(s) failed to delete.  Please try deleting them individually. `
            },
            {
                arrayType: 'failure',
                entities: licenseAdminUsers,
                message: ` users(s) failed to delete since user(s) are license administrators.`
            },
            {
                arrayType: 'failure',
                entities: teacherWithClassesUsers,
                message: ` user(s) failed to delete since they have at least one current class.`
            },
            {
                arrayType: 'failure',
                entities: providesLicensesUsers,
                message: ` user(s) failed to delete since they currently provide licenses to other users.`
            }
        ];

        thunkAPI.dispatch(updateSnackbarMessageUsingResults(displayCategoryArr));
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserPagination: (state, action: PayloadAction<Partial<UserPagination>>) => {
            state.pagination = { ...state.pagination, ...action.payload };
        },
        clearEditUserDetails: (state) => {
            state.editUserDetails = null;
        },
        setUserExistsInfo: (state, action: PayloadAction<Partial<ExistenceData>>) => {
            state.isUserExists = { ...state.isUserExists, ...action.payload };
        },
        setEmailExistsInfo: (state, action: PayloadAction<Partial<ExistenceData>>) => {
            state.isEmailExists = { ...state.isEmailExists, ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getUserDetails.fulfilled, (state, action) => {
            state.users = action.payload.data;
            state.filterUserCount = action.payload.count
        })
        .addCase(getUserDetails.rejected, (state) => {
            state.users = [];
            state.filterUserCount = 0;
        })
        .addCase(getUserCount.fulfilled, (state, action) => {
            state.userCount = action.payload;
        })
        .addCase(getUserCount.rejected, (state) => {
            state.userCount = 0;
        })
        .addCase(getEditUserDetails.fulfilled, (state, action) => {
            state.editUserDetails = action.payload;
        })
        .addCase(getEditUserDetails.rejected, (state) => {
            state.editUserDetails = null;
        })
        .addCase(searchStudentsWithTeacherClasses.fulfilled, (state, action) => {
            state.searchedStudentsWithTeacherClasses = action.payload;
        })
        .addCase(searchStudentsWithTeacherClasses.rejected, (state) => {
            state.searchedStudentsWithTeacherClasses = [];
        })
        .addCase(getEnrolledStudentsByClassId.fulfilled, (state, action) => {
            const { classId, enrolledStudents } = action.payload;
            state.studentsEnrolledToClass = {
                ...state.studentsEnrolledToClass,
                [classId]: enrolledStudents
            }
        })
        .addCase(getEnrolledStudentsByClassId.rejected, (state) => {
            state.studentsEnrolledToClass = {};
        });
    }
});

export const {
    setUserPagination,
    clearEditUserDetails,
    setUserExistsInfo,
    setEmailExistsInfo
} = userSlice.actions;

export default userSlice.reducer;