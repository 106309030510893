import { LicenseUtilization } from "../clientLibrary/class/classInterfaces";
import { LicenseConsumerResponse } from "../clientLibrary/license/licenseInterfaces";
import { UserResponse } from "../clientLibrary/user/userInterfaces";
import { MailtoData } from "../components/helperComponents/mailtoLink";
import { arrayOfStringsToSingleStringDisplay,  getLicenseNameOnlyFromLicenseTypeDescription } from "./GeneralFunction";

export interface CheckLicensesResponse {
    okToEnroll: boolean,
    message?: string,
    bookList?: string[],
    mailtoData?: MailtoData,
}

const generateLicenseAlertMessage = (messageObj: Record<string, string>, studentCount: number) => {
    let message = Object.values(messageObj).join('\n');
    if (studentCount !== 1 && studentCount !== 999) {
        message = message + `You are trying to add ${studentCount} student${studentCount === 1 ? "" : "s"}.\n`
    }
    return message;
}

export const checkForEnoughLicensesByLicenseNames = (
    availableLicenses: LicenseConsumerResponse[],
    classBooks: LicenseUtilization[],
    studentCount = 1
): CheckLicensesResponse => {
    let messageObj = {} as Record<string, string>;
    for (let book of classBooks) {
        const matchedLicense =
            availableLicenses.find(license => license.associatedGroupAllowance.licenseType.name === book.licenseTypeName);
        const bookName = getLicenseNameOnlyFromLicenseTypeDescription(book.licenseTypeDescription);
        if (matchedLicense) {
            const remaining = matchedLicense.associatedGroupAllowance.remaining;
            if (remaining < studentCount) {
                messageObj[bookName] = `${bookName} has ${remaining} remaining licens${remaining === 1 ? "e" : "es"}.`;
            }
        } else {
            messageObj[bookName] = `${bookName} is not one of your available licenses.`;
        }
    }

    if (Object.keys(messageObj).length > 0) {
        return {
            okToEnroll: false,
            message: generateLicenseAlertMessage(messageObj, studentCount),
            bookList: Object.keys(messageObj)
        }
    }

    return {
        okToEnroll: true
    }
}

export const generateNotEnoughLicensesHrefObj = (primaryContact: UserResponse, teacherClassName: string, books: string[], count = 1): MailtoData => {
    const booksDisplay = arrayOfStringsToSingleStringDisplay(books);
    const hrefObj = {
        mailTo: primaryContact.email,
        subject: `Need licenses ${count !== 999 ? `for ${count} more student${count > 1 ? "s" : ""} ` : ""}for ${booksDisplay}`,
        body: `Hi ${primaryContact.firstName}. I do not have enough ${booksDisplay} licenses to enroll students in ${teacherClassName}. ${count !== 999 ? `I need ${count} more licens${count === 1 ? "e" : "es"}.` : ""} Please let me know what other futher information you may need from me.`
    }
    return hrefObj;
}