import React from "react";
import "./../../../App.scss";
import Header from "../../commonLayouts/header/index.tsx";
import Footer from "../../commonLayouts/footer/index.tsx";
import Content from "../../cpmadmin/home/content";
import UnderConstruction from "../../commonLayouts/underConstruction";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from '../../commonLayouts/errorBoundary';
import { node_env } from "../../../constants";

function CPMAdminHome() {
  return (
    <div className="App">
      <div className="w-100 h-100">
        {/* Tab Navigation section  */}
        <BrowserRouter>
          <ErrorBoundary>
            <Header/>
            { node_env === "development"
                ? <Content />
                : <UnderConstruction /> }
            <Footer/>
          </ErrorBoundary>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default CPMAdminHome;
