import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { getLicenseNameOnlyFromLicenseTypeDescription, timezoneOffset } from "../../../common/GeneralFunction";
import { format } from 'date-fns';
import { useAppSelector } from "../../../hooks";

interface ClassInfoProps {
    classId: number
}

const useStyles = makeStyles((theme) => ({
    infoBoxContainer: {
        minHeight: "130px",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "Column",
            justifyContent: "center"
        }
    },
    infoBox: {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        minHeight: "100px",
        padding: "15px 10px",
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        overflow: "hidden",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "flex-start",
            marginBottom: "0px",
            padding: "0px",
            minHeight: "70px",
        }
    },
    responsiveText: {
        fontSize: "1.3em",
        [theme.breakpoints.down(900)]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.down(720)]: {
            fontSize: "1em",
        },
        [theme.breakpoints.down(640)]: {
            fontSize: "0.9em"
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "1.3em",
        }
    },
    infoBoxContentLabel: {
        color: "grey",
        paddingTop: "10px",
    },
    infoBoxDateContainer: {
        padding: "23px 5px 23px 0px",
        [theme.breakpoints.down("md")]: {
            padding: "23px 0px 23px 0px",
        },
        [theme.breakpoints.down("xs")]: {
            minWidth: "300px",
            padding: "0px",
        },
    },
}));

function ClassInfo(props: ClassInfoProps) {
    const classData = useAppSelector(state => state.class.searchedClasses.find(classesResponse => classesResponse.id === props.classId));
    const studentData = useAppSelector(state => state.user.studentsEnrolledToClass[props.classId]);
    const studentCount = studentData != null ? studentData.length : classData?.studentCount;

    const classes = useStyles();
    let curriculum;

    if (classData?.licenseUtilization.length === 0) {
        curriculum = (
            <>
                <Typography className={classes.responsiveText} style={{color: "grey",  marginBottom: "-10px"}} variant="inherit">N/A</Typography>
                <Typography variant="subtitle2" className={classes.infoBoxContentLabel}>Curriculum</Typography>
            </>
        )
    } else if (classData?.licenseUtilization.length === 1) {
        curriculum = (
                <>
                <Grid item>
                        <Typography key={classData.licenseUtilization[0].licenseTypeDescription} variant="inherit" className={classes.responsiveText}>
                            {getLicenseNameOnlyFromLicenseTypeDescription(classData.licenseUtilization[0].licenseTypeDescription)}
                        </Typography>
                        <Typography variant="subtitle2" className={classes.infoBoxContentLabel}>Curriculum</Typography>
                </Grid>
                </>
        )
    } else {
        curriculum =  classData?.licenseUtilization.map((license) => {
            return (
                <Typography key={license.licenseTypeDescription} variant="inherit" className={classes.responsiveText} style={{ fontSize: "1em", paddingBottom: "2px" }}>
                    {getLicenseNameOnlyFromLicenseTypeDescription(license.licenseTypeDescription)}
                </Typography>
            )
        })
    }

    if (classData) {
        return (
            <Grid item container className={classes.infoBoxContainer}>
                <Grid item container sm={4} md={4} lg={4}>
                    <Grid item container  sm={6} md={6} lg={6} className={classes.infoBox}>
                        <Grid item>
                            <Typography variant="inherit" className={classes.responsiveText}>
                                {studentCount}
                            </Typography>
                            <Typography variant="subtitle2" className={classes.infoBoxContentLabel}>
                                Students
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container sm={6} md={6} lg={6} className={classes.infoBox}>
                        <Grid item>
                                {
                                    classData.pin ? (
                                        <Typography variant="inherit" className={classes.responsiveText}>
                                            {classData.pin}
                                        </Typography>
                                    ) : (
                                        <Typography variant="inherit" className={classes.responsiveText} style={{color: "grey"}}>
                                            N/A
                                        </Typography>
                                    )
                                }
                            <Typography variant="subtitle2" className={classes.infoBoxContentLabel}>
                                Pin
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container sm={4} md={4} lg={4} className={classes.infoBox}>
                    {curriculum}
                </Grid>
                <Grid item container sm={4} md={4} lg={4} className={`${classes.infoBoxDateContainer} ${classes.infoBox}`} style={{justifyContent: "center"}}>
                    <Grid item className={classes.responsiveText} style={{paddingLeft: "1px"}}>
                        <Typography variant="inherit" >{format(timezoneOffset(classData.startDate), "MMM d, yyyy")}</Typography>
                            &nbsp;
                            -
                            &nbsp;
                        <Typography variant="inherit" >{format(timezoneOffset(classData.endDate), "MMM d, yyyy")}</Typography>
                    </Grid>
                    <Grid item style={{marginTop: "-8px"}}>
                        <Typography variant="subtitle2" className={classes.infoBoxContentLabel} style={{paddingRight: "2px", paddingTop: "18px"}}>
                                    Term
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    } else {
        return null;
    }
}

export default ClassInfo;