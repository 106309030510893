import React from "react";
import { useSelector } from "react-redux";
import { Alert } from "@material-ui/lab";
import { Grid } from "@material-ui/core";

const Content = () => {
    const searchUserDetailsError = useSelector(state => state.auth.searchUserDetails.error);
    return (
        <Grid container align="center" justify="center">
            {
                searchUserDetailsError && 
                <Grid item xs={4}>
                    <Alert severity="error">{searchUserDetailsError}</Alert>
                </Grid>
            }
        </Grid>
    ) 
}

export default React.memo(Content);