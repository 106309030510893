import { PayloadAction } from "@reduxjs/toolkit";
import { DisplayCategory, ExpirationData, SnackbarMessageType, UniqueIds } from "../clientLibrary/GeneralInterfaces";
import { updateSnackbarSuccess, updateSnackbarError, updateSnackbarWarning } from "../reduxModule/slices/utilitySlice";
import { ParseResult } from "papaparse";
import { format } from "date-fns";
import { LicenseConsumerResponse, LicenseCountResponse, LicenseForecastTableData } from "../clientLibrary/license/licenseInterfaces";
import { SchoolResponse } from "../clientLibrary/school/schoolInterfaces";
import { currentYear } from "../constants";

export const timezoneOffset = (date: string): Date => {
    return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
}

export const getLaterDate = (startDate: string) => {
    const startDateInMS = Date.parse(startDate)
    const currentDateInMS = new Date().getTime();

    if (!startDate) {
        return timezoneOffset(format(new Date(), 'yyyy-MM-dd'))
    } else if ( startDateInMS > currentDateInMS) {
        return timezoneOffset(startDate)
    } else {
        return timezoneOffset(format(new Date(), 'yyyy-MM-dd'))
    }
}

//Compare arrays for equality in its order and members
export const arraysEqual = (arr1: any[], arr2: any[]): boolean => {
    if (arr1 === arr2) return true;
    if (arr1 == null || arr2 == null) return false;
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }
    return true;
}

export const arrayOfStringsToSingleStringDisplay = (array: string[]): string => {
    if (array.length === 1) {
        return "" + array[0];
    } else if (array.length === 2) {
        return array[0] + " and " + array[1];
    }
    const firstPart = array.slice(0, -1).join(", ");
    return firstPart + ", and " + array[array.length - 1];
}

//Bulk Add Success/Failure Message Generation
const displayItemResults = (displayCategoryArr: DisplayCategory[]): string => {
    let display = "";
    displayCategoryArr.forEach(item => {
        if (item.entities.length > 0) {
            display = display + (item.entities.length) + item.message;
        }
    });
    return display;
}

export const updateSnackbarMessageUsingResults = (displayCategoryArr: DisplayCategory[]): PayloadAction<string> => {
    const type = determineSnackbarType(displayCategoryArr);
    const message = `${displayItemResults(displayCategoryArr)}`;
    if (type === "success") {
        return updateSnackbarSuccess(message);
    } else if (type === "error") {
        return updateSnackbarError(message);
    } else if (type === "warning") {
        return updateSnackbarWarning(message);
    } else {
        console.error("The type of snackbar message was missing in one or more of the results array.");
        return updateSnackbarError("There was a problem with displaying the results.");
    }
}

const determineSnackbarType = (displayCategoryArr: DisplayCategory[]): SnackbarMessageType => {
    if (displayCategoryArr.every(element => element.arrayType === 'success' || element.entities.length === 0)) {
        return 'success';
    } else if (displayCategoryArr.every(element => element.arrayType === 'failure' || element.entities.length === 0)) {
        return 'error';
    }
    return 'warning';
}

export const getUniqueObjectArray = <T>(array: T[], property: keyof T) => {
    const flags: any = {};
    const filteredArray = array.filter(item => {
        const indexProp = item[property];
        if (flags[indexProp]) {
            return false
        }
        flags[indexProp] = true;
        return true;
    })
    return filteredArray;
}

export interface NamedEntity {
    firstName: string,
    lastName: string
}

export const getFullName = ({ firstName, lastName }: NamedEntity) => {
    return `${firstName} ${lastName}`
}

export const getFullNameForCredentials = ({ firstName, lastName }: NamedEntity) => {
    return (firstName + lastName).toLowerCase();
}

export const arrSortByProperty = <T, K extends keyof T>(array: T[], property: K): T[] => {
    return array.sort((a, b) => {
        if (a[property] < b[property]) return -1;
        if (a[property] > b[property]) return 1;
        return 0;
    });
}

type CsvArrayConverter<T> = (array: ParseResult<string>[]) => Record<keyof T, string>[] | undefined;

export const makeCsvArrayConverter = <T>(headerMap: Record<string, keyof T>): CsvArrayConverter<T> => {
    return (array: ParseResult<string>[]) => {
        if (!array[0].data) {
            console.log('There is a problem with the CSV file. Please check the file and try again');
        } else {
            type RowObject = Record<keyof T, string>;
            const parsedArray: RowObject[] = [];
            const headerArray: Array<keyof T> = array[0].data.map((item: any) => {
                if (headerMap[item] != null) return headerMap[item]
                throw new Error();
            });
            for (let i = 1; i < array.length; i++) { //Starting on index 1 since index-0 are headers
                const obj: RowObject = {} as RowObject;
                //Loop through and set properties based on headers
                for (let j = 0; j < headerArray.length; j++) {
                    const value = String(array[i].data[j]);
                    obj[headerArray[j]] = value;
                }
                parsedArray.push(obj);
            }
            return parsedArray;
        }
    }
}

export const isEmailValid = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const getLicenseNameOnlyFromLicenseTypeDescription = (licenseName: string): string => {
    return licenseName.replace(/student|teacher/i, "")
        .replace(/license/i, "")
        .replace("for s", "")
        .trim();
}

export const getLicenseNameOnlyFromLicenseProvider = (licenseName: string): string => {
    return licenseName.split("generated")[0]
        .replace("license", "").replace("teacher", "")
        .replace("Teacher", "").replace("License", "")
        .trim();
}

export const paginationAfterDelete = (entityArrayLength: number, selectedEntitiesLength: number, paginationPage: number): number => {
    let pageDiff = 0;
    if (entityArrayLength === selectedEntitiesLength) pageDiff = 1;
    return paginationPage - pageDiff;
}

export const getLicenseTotalCount = (licenseId: number, licenses: LicenseCountResponse[]) => {
    let count = 0;
    for (const license of licenses) {
        if (license.licenseType.id === licenseId) {
            count += license.count
        }
    }
    return count;
}; 

export const getExpiredLicenseTotalCount = (licenseId: number, licenses: LicenseCountResponse[]) => {
    let count = 0;
    for (const license of licenses) {
        const expirationYear = getYearFromDateString(license.contract.contractExpiration)
        if (license.licenseType.id === licenseId && license.expiredCount && currentYear === expirationYear) {
            count += license.expiredCount
        }
    }
    return count;
}

export const getAcademicYearsByContractLength = (contractLength: number): string[] => {
    const academicYears = [];
    const date = new Date();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    let count = 0;
    let secondCount = 1;
    for (let i = 1; i <= contractLength; i++) {
        if (month < 5 || (month === 5 && day < 30)) {
            let year = date.getFullYear() + count - 1;
            let nextYear = date.getFullYear() + secondCount - 1;
            academicYears.push(year + ' - ' + nextYear);
        } else { 
            let year = date.getFullYear() + count;
            let nextYear = date.getFullYear() + secondCount;
            academicYears.push(year + ' - ' + nextYear);
        }
        count++
        secondCount++
    } 
    return academicYears;  
};

const getYearFromDateString = (date: string) => {
    return date.slice(0,4)
}

const getLicenseTotalByExpirationYear = (year: string, licenseId: number, licenses: LicenseCountResponse[]) => {
    let licenseCount = 0;
    for (const license of licenses) {
        const expirationYear = getYearFromDateString(license.contract.contractExpiration);
        if (license.licenseType.id === licenseId && year === expirationYear) {
            licenseCount += license.count
        }
    }
    return licenseCount;
}

export const getExpirationData = (licenseId: number, licenses: LicenseCountResponse[]) => {
    const expirationData = {} as ExpirationData;
    for (const license of licenses) {
        const expirationYear = getYearFromDateString(license.contract.contractExpiration);
        const licenseCount = getLicenseTotalByExpirationYear(expirationYear, license.licenseType.id, licenses)
        if (license.licenseType.id === licenseId) {
            expirationData[expirationYear] = licenseCount
        }
    }
    return expirationData;
};

export const getLicenseTableData = (academicYears: string[], expirationData: ExpirationData, totalLicenseCount: number) => {
    const licenseTableData: LicenseForecastTableData[] = [];
    for (const yearRange of academicYears) {
        const year = yearRange.slice(0,4);
        if (year in expirationData) {
            if (year in expirationData && totalLicenseCount > 0) {
                totalLicenseCount = totalLicenseCount - expirationData[year]
            }
            licenseTableData.push({
                academicYear: yearRange,
                expiringLicenses: expirationData[year],
                remainingLicenses: totalLicenseCount
            });
        } else {
            licenseTableData.push({
                academicYear: yearRange,
                expiringLicenses: 0,
                remainingLicenses: totalLicenseCount
            });
        }
    }
    return licenseTableData;
};

export const isDateFormatValid = (date: string): boolean => {
    // YYYY-MM-DD
    const regex = /^([1-9][0-9]{3})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
    // MM-DD-YYYY
    const regex2 = /^(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])-([1-9][0-9]{3})$/

    if (regex.test(date) || regex2.test(date)) return true;
    else return false;
}

export const getUniqueLicensesFromLicenseConsumerData = (licenses: LicenseConsumerResponse[]): LicenseConsumerResponse[] => {
    const uniqueLicenses: LicenseConsumerResponse[] = [];
    let uniqueIds = {} as UniqueIds;
    licenses.forEach((license) => {
        const id = license.associatedGroupAllowance.licenseType.id
        if (!(id in uniqueIds)) {
            uniqueIds[id] = true;
            uniqueLicenses.push(license);
        }
    })
    return uniqueLicenses;
}

export const doesCurriculumTitleExist = (curriculumTitle: string, licenses?: LicenseConsumerResponse[]) => {
    if (licenses) {
        for (let i = 0; i < licenses.length; i++) {
          let license = getLicenseNameOnlyFromLicenseTypeDescription((licenses[i].associatedGroupAllowance.licenseType.description).toLowerCase());
          if (curriculumTitle.toLowerCase() === license) {
            return true;
          }
        }
        return false;
    }
}

export const doesSchoolExist = (schoolName: string, schools?: SchoolResponse[]) => {
    if (schools) {
      for (let i = 0; i < schools.length; i++) {
        const existingSchoolName = schools[i].name.toLowerCase();
        if (schoolName.toLowerCase() === existingSchoolName) {
          return true;
        }
      }
    }
    return false;
}
