import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateSnackbarError } from "./utilitySlice";
import { getAllLicenseAdminGroups, getLicenseAdminGroupSummaryReportById, getLicenseConsumersByLogin, getLicenseTypes } from "../../clientLibrary/license/license";
import { License, LicenseAdminGroupResponse, LicenseConsumerResponse, LicenseTypeResponse } from "../../clientLibrary/license/licenseInterfaces";

export interface LicenseState {
    licenses: Array<License>,
    licenseTypeReference: Array<LicenseTypeResponse>,
    licenseConsumers: Array<LicenseConsumerResponse>,
    adminGroupsData: Array<LicenseAdminGroupResponse>
}

const initialState: LicenseState = {
    licenses: [],
    licenseTypeReference: [],
    licenseConsumers: [],
    adminGroupsData: []
}

export const getLicenseUsage = createAsyncThunk(
    'license/getLicenseUsage',
    async (_, thunkAPI: any) => {
        try {
            const licensePools = await getAllLicenseAdminGroups();
            const promises = licensePools.map((licensePool) => getLicenseAdminGroupSummaryReportById(licensePool.id));
            const values = await Promise.all(promises);
            return values.flatMap((value, index) => {
                const licensePoolId = licensePools[index].id;
                const licensePoolGroupName = licensePools[index].groupName;
                return value.map(item => ({
                    ...item,
                    licensePoolId,
                    licensePoolGroupName,
                    usedLicenses: item.totalLicensesAllowed - item.licensesRemaining
                }));
            });
        } catch (error) {
            thunkAPI.dispatch(updateSnackbarError("Failed to fetch license usage data."));
            throw error;
        }
    }
);

export const getLicenseTypeReference = createAsyncThunk(
    'license/getLicenseTypeReference',
    async () => {
        try {
            const licenseTypeData = await getLicenseTypes();
            return licenseTypeData;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
);

export const getUserLicenseConsumersByLogin = createAsyncThunk(
    'license/getUserLicenseConsumersByLogin',
    async (login: string) => {
        try {
            const licenseConsumerResponseData = await getLicenseConsumersByLogin(login);
            return licenseConsumerResponseData;
        } catch (error) {
            console.error(`getUserLicenseConsumersByLogin(${login}) resulted in ${error}`);
            throw error;
        }
    }
);

export const getLicenseAdminGroups = createAsyncThunk(
    'license/getLicenseAdminGroups',
    async () => {
        try {
            const licenseAdminGroups = await getAllLicenseAdminGroups();
            return licenseAdminGroups;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
);

export const licenseSlice = createSlice({
    name: 'license',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getLicenseUsage.fulfilled, (state, action) => {
            state.licenses = action.payload;
        })
        .addCase(getLicenseUsage.rejected, (state) => {
            state.licenses = [];
        })
        .addCase(getLicenseTypeReference.fulfilled, (state, action) => {
            state.licenseTypeReference = action.payload;
        })
        .addCase(getLicenseTypeReference.rejected, (state) => {
            state.licenseTypeReference = [];
        })
        .addCase(getUserLicenseConsumersByLogin.fulfilled, (state, action) => {
            state.licenseConsumers = action.payload;
        })
        .addCase(getUserLicenseConsumersByLogin.rejected, (state) => {
            state.licenseConsumers = [];
        })
        .addCase(getLicenseAdminGroups.fulfilled, (state, action) => {
            state.adminGroupsData = action.payload;
        })
        .addCase(getLicenseAdminGroups.rejected, (state) => {
            state.adminGroupsData = [];
        });
    }
});

export default licenseSlice.reducer;