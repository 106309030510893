import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    modal: {
        minHeight: '600px'
    },
    content: {
        minHeight: "auto",
    },
    actions: {
        margin: 0,
        padding: theme.spacing(1),
    },
    widthAndSpacing: {
        "& .MuiDialog-paper": {
            marginLeft: "0px",
            marginRight: "0px",
            width: "95%"
        }
    },
    ".green-button.Mui-disabled ": {
        backgroundColor: '#e0e0e0',
        color: '#05803c',
        '&:hover': {
            backgroundColor: '#e0e0e0',
            color: '#8c8c8c',
            cursor: 'not-allowed',
        }
    }
}));

interface DialogTitleProps {
    id: string,
    children: React.ReactNode,
    onClose(): void
}

const DialogTitle = (props: DialogTitleProps) => {
    const { id, children, onClose } = props;
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography id={id} className={classes.root} >
            <Typography variant="h6">{children}</Typography>
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
    );
};

interface BaseModalContainerProps {
    id: string,
    modalOpen: boolean,
    modalTitle: React.ReactNode,
    children: React.ReactNode,
    handleClose(): void,
    buttonDisabled?: boolean,
    buttonName?: string,
    fullWidth?: boolean,
    handleAction?(): void,
    optionalBtnComponent?: React.ReactNode
}

export default function BaseModalContainer(props: BaseModalContainerProps) {
    const classes = useStyles();
    return (
        <div>
            <Dialog
                className={classes.widthAndSpacing}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.modalOpen || false}
                fullWidth={!!props.fullWidth}
                maxWidth={props.fullWidth ? "md" : "sm"}
            >
                <DialogTitle id={props.id} onClose={props.handleClose}>
                    {props.modalTitle}
                </DialogTitle>
                <MuiDialogContent className={classes.content} dividers>{props.children}</MuiDialogContent>
                {(props.optionalBtnComponent || props.buttonName) && (
                    <MuiDialogActions>
                        <Grid container spacing={2} justify={!!props.optionalBtnComponent ? "space-between" : "flex-end"}>
                            {props.optionalBtnComponent && (
                                <Grid item>
                                    {props.optionalBtnComponent}
                                </Grid>
                            )
                            }
                            {props.buttonName && (
                                <Grid item>
                                    <Button
                                        variant={props.buttonDisabled ? "outlined" : "contained"}
                                        className={props.buttonDisabled ? classes[".green-button.Mui-disabled "] : "green-button"}
                                        onClick={props.handleAction}
                                        disabled={props.buttonDisabled}
                                    >
                                        {props.buttonName}
                                    </Button>
                                </Grid>
                            )
                            }
                        </Grid>
                    </MuiDialogActions>
                )}
            </Dialog>
        </div>
    );
}