import React from "react";
import "./../../../App.scss";
import Header from "../../commonLayouts/header/index";
import Footer from "../../commonLayouts/footer/index";
import Content from "./content";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from '../../commonLayouts/errorBoundary';

function DistrictHome() {
  return (
    <div className="App">
      <div className="w-100 h-100">
        <BrowserRouter>
          <ErrorBoundary>
            <Header />
            <Content />
            <Footer />
          </ErrorBoundary>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default DistrictHome;
