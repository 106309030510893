import {
    Button,
    Grid,
    makeStyles,
    TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from 'react';
import { DistrictRoles, GlobalCPMRoles, SchoolRoles } from "../../../clientLibrary/Enums";
import { createStudentUser, getUserByIdentifier } from "../../../clientLibrary/user/user";
import { CreateBaseUserPayload, UserResponse } from "../../../clientLibrary/user/userInterfaces";
import { useSetAlert } from "../../../common/Alerts";
import { emailRegex } from "../../../common/Constants";
import { CheckLicensesResponse } from "../../../common/LicenseValidation";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getUserLicenseConsumersByLogin } from "../../../reduxModule/slices/licenseSlice";
import { EnrollOrRemoveStudentPayload, enrollStudent, getEnrolledStudentsByClassId } from "../../../reduxModule/slices/userSlice";
import { addToHighlightedRowValues, clearHighlightedRowValues, setBackdrop } from "../../../reduxModule/slices/utilitySlice";
import { MailtoLink, MailtoLinkTypes } from "../../helperComponents/mailtoLink";

const useStyles = makeStyles((theme) => ({
    enrollmentContainer: {
        margin: "0px",
        minHeight: "90px",
        width: "100%",
    },
    enrollmentForm: {
        padding: "8px 28px",
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            alignItems: "center",
            flexDirection: "column",
            padding: "8px 0px"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "8px 0px 8px 20px"
        },
    },
    enrollmentInputs: {
        width: "75%",
        justifyContent: "space-evenly",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            alignItems: "center",
            flexDirection: "row",
        },
        [theme.breakpoints.down(760)]: {
            width: "100%",
            flexDirection: "column",
            paddingRight: "12px",
            margin: "0px",
            justifyContent: "space-between",
            minHeight: "140px"
        },
    },
    individualInput: {
        [theme.breakpoints.down("sm")]: {
            marginTop: "5px"
        }
    },
    enrollmentActions: {
        width: "25%",
        justifyContent: "flex-end",
        [theme.breakpoints.down(1280)]: {
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "15px",
            marginLeft: "14.5px",
            padding: "5px 20px"
        },
        [theme.breakpoints.down(760)]: {
            fontSize: "14px",
            marginLeft: "0px"
        },
    },
    blueBtn: {
        backgroundColor: '#fff',
        color: '#1884DF',
        border: "1px solid #1884DF",
        fontSize: "16px",
        [theme.breakpoints.down(760)]: {
            fontSize: "14px",
            margin: "0px"
        },
        '&:hover': {
            backgroundColor: '#1884DF',
            color: '#fff',
        }
    },
    blueBtnDisabled: {
        fontSize: "16px",
        [theme.breakpoints.down(760)]: {
            fontSize: "14px",
            margin: "0px"
        },
    },
    redBtn: {
        backgroundColor: "#fff",
        textTransform: "capitalize",
        color: "#C9094D",
        border: "1px solid #C9094D",
        fontSize: "16px",
        [theme.breakpoints.down(760)]: {
            fontSize: "14px",
            margin: "0px"
        },
        "&:hover": {
            backgroundColor: "#C9094D",
            color: "#fff"
        }
    },
    alert: {
        whiteSpace: "pre-line",
        alignItems: "center"
    }
}));

interface StudentEnrollmentProps {
    classId: number,
    teacherClassName: string,
    setOpenSingleEnrollment: (open: boolean) => void,
    openSingleEnrollment: boolean,
    availableLicenseInfo: CheckLicensesResponse | null
}


export const StudentEnrollment = (props: StudentEnrollmentProps) => {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const credentialRef = useRef<HTMLInputElement>(null);

    const schools = useAppSelector(state => state.school.schools);
    const customerId = useAppSelector(state => state.account.customer?.id);

    const existingStudents = useAppSelector(state => state.user.studentsEnrolledToClass[props.classId]);
    const login = useAppSelector(state => state.auth.userRole.login);

    const [credentials, setCredentials] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [showFormElements, setShowFormElements] = useState(true);

    const [nameDisabled, setNameDisabled] = useState(false);
    const [isEnrollBtnDisabled, setIsEnrollBtnDisabled] = useState(true);
    const [isUserAlreadyEnrolled, setIsUserAlreadyEnrolled] = useState(false);

    const [userToEnroll, setUserToEnroll] = useState<EnrollOrRemoveStudentPayload | null>(null);

    const [alert, setSuccessAlert, setWarningAlert, setErrorAlert, clearAlert] = useSetAlert();
    // @ts-ignore
    // eslint-disable-next-line
    const [licenseAlert, setLicenseSuccessAlert, setLicenseWarningAlert, setLicenseErrorAlert, clearLicenseAlert] = useSetAlert();
    const [licenseLink, setLicenseLink] = useState<JSX.Element | null>();

    useEffect((): void => {
        if (!credentials || !firstName || !lastName || isUserAlreadyEnrolled) {
            setIsEnrollBtnDisabled(true);
        } else { setIsEnrollBtnDisabled(false) }
    }, [credentials, firstName, lastName, isUserAlreadyEnrolled]);

    useEffect(() => {
        if(props.availableLicenseInfo != null){
            const {okToEnroll, message, mailtoData} = props.availableLicenseInfo;
            if (okToEnroll === false && message && mailtoData) {
                setShowFormElements(false);
                setLicenseErrorAlert(message);
                setLicenseLink(<MailtoLink label={"Request More"} type={MailtoLinkTypes.Text} href={mailtoData} />);
            } else {
                setShowFormElements(true);
                setLicenseLink(null);
                clearLicenseAlert();
            }
        }
    }, [clearLicenseAlert, props.availableLicenseInfo, setLicenseErrorAlert]);

    const setUserDisplayValues = (user: UserResponse) => {
        setNameDisabled(true);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setCredentials(user.email);
    }

    const clearState = () => {
        if (nameDisabled) {
            setFirstName("");
            setLastName("");
        }
        setNameDisabled(false);
        setIsUserAlreadyEnrolled(false);
    }

    const handleCredentialsChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        clearState();
        setCredentials(e.target.value.trim());
    }

    const handleCredentialBlur = async () => {
        clearAlert();
        try {
            const userResponse = await getUserByIdentifier(credentials);
            if (userResponse.status === 403) {
                setErrorAlert("User already in another district.");
                setIsUserAlreadyEnrolled(true);
            } else {
                const user = userResponse.data;
                if (user) {
                    setUserDisplayValues(user);
                    const userEnrolled = existingStudents.some(val => val.login === user.login);
                    if (userEnrolled) {
                        setWarningAlert(`${user.email} is already enrolled in this class.`);
                        setIsUserAlreadyEnrolled(true);
                    } else {
                        setUserToEnroll({ classId: props.classId, login: user.login });
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const enrollExistingUser = async () => {
        if (userToEnroll != null) {
            const response: any = await dispatch(enrollStudent({ classId: userToEnroll.classId, login: userToEnroll.login }));
            if (response.error) {
                setErrorAlert(`${userToEnroll.login} failed to enroll.`)
            } else {
                setSuccessAlert(`${userToEnroll.login} has been enrolled.`);
            }
        }
    }

    const enrollNewUser = async () => {
        if (customerId && props.classId != null) {
            const studentPayload: CreateBaseUserPayload = {
                schoolID: schools[0].id,
                schoolRole: SchoolRoles.Student,
                districtID: customerId,
                districtRole: DistrictRoles.Student,
                login: credentials,
                firstName: firstName,
                lastName: lastName,
                email: credentials,
                password: credentials,
                confirmPassword: credentials,
                langKey: "en",
                globalCPMRole: GlobalCPMRoles.Student,
            }
            const fullName = `${firstName} ${lastName}`;
            
            try {
                const login = await createStudentUser(studentPayload);
                const response: any = await dispatch(enrollStudent({ classId: props.classId, login: login }));
                if (response.error) {
                    setErrorAlert(`${fullName} failed to enroll.`)
                } else {
                    setSuccessAlert(`${fullName} has been enrolled. The default password is their username. Please have the student log in and change their password. Password can be reset in the student table below.`);
                }
            } catch (error) {
                console.log(error);
                setErrorAlert(`${fullName} failed to enroll.`);
            };
        }
    }

    const handleClick = async () => {
        dispatch(clearHighlightedRowValues());
        if (!emailRegex.test(credentials)) {
            setErrorAlert("Username must be in the form of an e-mail address.");
            return;
        }

        await dispatch(setBackdrop(true));

        if (userToEnroll === null) {
            await enrollNewUser();
        } else {
            await enrollExistingUser();
        }

        dispatch(setBackdrop(false));

        const classId = props.classId;

        if (classId) {
            dispatch(addToHighlightedRowValues({ classId: classId, value: credentials.toLowerCase() }));
            dispatch(getEnrolledStudentsByClassId(classId));
            dispatch(getUserLicenseConsumersByLogin(login));
        }

        setUserToEnroll(null);
        handleNext();
    }

    const clearFields = () => {
        setCredentials("");
        setFirstName("");
        setLastName("");
    }

    const handleNext = () => {
        clearFields();
        if (credentialRef && credentialRef.current != null) {
            credentialRef.current.focus();
        }
    }

    const handleCancel = () => {
        props.setOpenSingleEnrollment(false);
        clearFields();
        clearAlert();
    }

    return (
        <Grid item container alignItems="center" direction="column" className={classes.enrollmentContainer}>
            {
                showFormElements && (
                    <Grid item container className={classes.enrollmentForm}>
                        <Grid container className={classes.enrollmentInputs}>
                            <Grid item>
                                <TextField
                                    autoFocus
                                    className={classes.individualInput}
                                    inputRef={credentialRef}
                                    label="Email or username"
                                    value={credentials}
                                    onChange={(e) => { handleCredentialsChange(e) }}
                                    onBlur={handleCredentialBlur}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    className={classes.individualInput}
                                    label="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    disabled={nameDisabled}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    className={classes.individualInput}
                                    label="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    disabled={nameDisabled}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container className={classes.enrollmentActions}>
                            <Grid item>
                                <Button
                                    color="primary"
                                    className={!isEnrollBtnDisabled && !isUserAlreadyEnrolled ? classes.blueBtn : classes.blueBtnDisabled}
                                    style={{ marginLeft: "-38%", textTransform: "capitalize" }}
                                    variant="outlined"
                                    disabled={isEnrollBtnDisabled || isUserAlreadyEnrolled}
                                    onClick={() => handleClick()}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    className={classes.redBtn}
                                    onClick={() => handleCancel()}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            <Grid item container spacing={5} justify="center" alignItems="center" style={{ height: "20%", padding: "0px", minHeight: "55px" }}>
                <Grid item container spacing={3} direction="column" justify="center" alignItems="center" style={{ padding: "30px 20px", marginBottom: "6px" }}>
                    {
                        alert.severity && (
                            <Grid item>
                                <Alert variant="outlined" severity={alert.severity} className={classes.alert}>
                                    {alert.message}
                                </Alert>
                            </Grid>
                        )
                    }
                    {
                        licenseAlert.severity && (
                            <Grid item>
                                <Alert variant="outlined" severity={licenseAlert.severity} className={classes.alert}>
                                    {licenseAlert.message}
                                    {licenseLink}
                                </Alert>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}