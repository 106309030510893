import React from "react";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import { INACTIVE_GRAY_COLOR, ELS_PRIMARY_BLUE_COLOR } from "./utilizationReport";

interface licenseReportsToggleButtonProps {
    activeTab: string;
    handleReport: (_event: React.MouseEvent<HTMLElement>, newTab: string | null) => void
}

const useStyles = makeStyles({
    toggleGroup: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        '& .MuiToggleButton-root': {
            color: INACTIVE_GRAY_COLOR,
            backgroundColor: 'transparent',
            textTransform: 'none',
            fontWeight: 'bold',
            border: 'none',
            borderRadius: '0px',
            padding: '7px 7px 0px 7px'
        },
        '& .MuiToggleButton-root.Mui-selected': {
            color: ELS_PRIMARY_BLUE_COLOR,
            backgroundColor: 'transparent',
            textTransform: 'none',
            fontWeight: 'bold',
            borderBottom: `2px solid ${ELS_PRIMARY_BLUE_COLOR}`,
            padding: '7px 7px 0px 7px'
        }
    }
})

export default function LicenseReportsToggleButtons(props: licenseReportsToggleButtonProps) {
    const classes = useStyles();
    
    return (
        <ToggleButtonGroup
            value={props.activeTab}
            exclusive
            onChange={props.handleReport}
            className={classes.toggleGroup}
        >
            <ToggleButton value="expiring" size="large">
                Expiring Licenses
            </ToggleButton>
            <ToggleButton value="student" size="large">
                Student Licenses
            </ToggleButton>
            <ToggleButton value="teacher" size="large">
                Teacher Licenses
            </ToggleButton>
        </ToggleButtonGroup>
    )
}