import React, { useState, useEffect, useCallback } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useAppSelector, useAppDispatch} from "../../hooks";
import { searchUserDetails, getUserRole, clearUserDetails } from "../../reduxModule/slices/authSlice";
import { UserResponse } from "../../clientLibrary/user/userInterfaces";
import _ from "lodash";
import LinearProgress from '@material-ui/core/LinearProgress';
import * as Impersonate from "../../common/Impersonate";

const useStyles = makeStyles({
    searchBoxWidth: {
        minWidth: "500px"
    }
});

const loadingText: JSX.Element = (
    <>
        <Typography variant="body1">Searching...</Typography>
        <LinearProgress />
    </>
);

export default function ImpersonateUser() {
    const [toggleSearch, setToggleSearch] = useState(false);
    const [searchStr, setSearchStr] = useState("");

    const selectedSearchUserDetails = useAppSelector(state => state.auth.searchUserDetails);
    
    const dispatch = useAppDispatch();
    const classes = useStyles();

    useEffect(() => {
        dispatch(clearUserDetails());
    }, [ dispatch ]);

    const debouncedSearchUserDetails = useCallback(
		_.debounce((value: string) => {
            dispatch(searchUserDetails(value));     
        } , 750),
		[ dispatch ], // will be created only once initially
	);

    const handleChange = (value: string) => {
        setSearchStr(value);
        if(value !== ''){
            debouncedSearchUserDetails(value);
        }
    }

    const handleLoginSelect = (value: UserResponse | null) => {
        Impersonate.setLogin(value!.login);
        dispatch(getUserRole());
    }

    const handleClose = () => {
        setSearchStr('');
        dispatch(clearUserDetails());
    }

    return (
        <>
            {
                toggleSearch ? (
                    <Grid item 
                          container 
                          justify="space-between"
                          alignItems="flex-end"
                    >
                        <Grid item >
                            <Autocomplete
                                className={classes.searchBoxWidth}
                                id="combo-box-users"
                                options={selectedSearchUserDetails.users}
                                getOptionLabel={(option) => `${option.login} ${option.firstName} ${option.lastName}` }
                                getOptionSelected={(option, value) => option.login === value.login}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label="Search For Users by Login, First or Last Name" 
                                        onChange={(e) => handleChange(e.target.value)}
                                        value={searchStr}
                                    />}
                                onClose={handleClose}
                                loading={selectedSearchUserDetails.isLoading}
                                loadingText={loadingText}
                                onChange={(_e, value) => handleLoginSelect(value)}
                                />
                        </Grid>
                        <Grid item>
                            <Button onClick={() => setToggleSearch(!toggleSearch)} variant="contained" color="primary">X</Button>
                        </Grid>
                    </Grid>
                    ) : (
                    <Grid item>
                        <Button onClick={() => setToggleSearch(!toggleSearch)} variant="contained" color="primary">Impersonate</Button>
                    </Grid>
                    )
            }
        </>
    )
}