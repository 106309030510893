import axios from "axios";
import { sso_api_url } from '../constants';
import * as Impersonate from "../common/Impersonate";
import {registerRefreshInterceptor} from "@cpmdevops/cpm-auth";

interface AxiosHeaders {
    "impersonating-login": string | null;
}

const axiosWithAuth = () => {
    const redirectUri = `${sso_api_url}`;

    const impersonatingLogin = Impersonate.getLogin();

    const headers: AxiosHeaders = {
        "impersonating-login": impersonatingLogin || ""
    };

    const instance = axios.create({
        headers: headers,
        withCredentials: true
    });

    registerRefreshInterceptor({axiosInst: instance, ssoUrl: redirectUri,  redirect: false})
    
    instance.interceptors.response.use(response=>response,
      err=>{
        if (err.response.status === 401) {
          console.log('Axios saw a 401 so clearing cookie and redirecting to login page.')
          document.cookie ='cpm-sso-token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=cpm.org; path=/;';
          window.location.href = redirectUri;
        } else {
          throw err;
        }
      }
    );
  
    return instance;
  };

  export default axiosWithAuth;
