import React, { useEffect, useState } from "react";
import { getUserRole } from "./reduxModule/slices/authSlice";
import { useAppDispatch, useAppSelector } from "./hooks";
import DistrictHome from './components/district/home/index';
import TeacherHome from './components/teacher/home/index';
import ParentHome from './components/parent/home';
import CPMAdminHome from "./components/cpmadmin/home";
import { isCPMAdmin, isDistrictAdmin, isParent, isTeacher } from "./common/AuthFunction";
import SnackBar from "./components/helperComponents/snackBar";
import { clearSnackbarMessage } from "./reduxModule/slices/utilitySlice";
import UnderConstruction from "./components/commonLayouts/underConstruction";
import {registerRefreshTimeout} from "@cpmdevops/cpm-auth";
import {sso_api_url} from "./constants";

const App = () => {
    const dispatch = useAppDispatch();
    const roles = useAppSelector(state => state.auth.userRole.roles);
    const snackMessage = useAppSelector(state => state.utility.snackMessage);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        console.log('Registering refresh timeout...')
        registerRefreshTimeout({
            redirect: false,
            ssoUrl: sso_api_url
        });
    }, [])

    useEffect(() => {
        (async() => {
            await dispatch(getUserRole());
            setIsLoading(false)
        })();
        dispatch(clearSnackbarMessage());
    }, [dispatch]);

    let app: JSX.Element | null = null;

    if (isCPMAdmin(roles)) {
        app = <CPMAdminHome />;
    } else if (isParent(roles)) {
        app = <ParentHome />;
    } else if (isDistrictAdmin(roles)) {
        app = <DistrictHome />;
    } else if (isTeacher(roles)) {
        app = <TeacherHome />;
    } else {
        app = <UnderConstruction />;
    };

    return (
        <div className="App">
            {isLoading ? null : app}
            <SnackBar
                open={snackMessage.status}
                message={snackMessage.message}
                type={snackMessage.type}
                handleClose={() => dispatch(clearSnackbarMessage())}
            />
        </div>
    )
};

export default App;
