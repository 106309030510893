import React from 'react';
import UploadContainer from '../../helperComponents/uploadContainer';
import { BulkStudentData } from '../../../reduxModule/slices/userSlice';
import { makeCsvArrayConverter } from '../../../common/GeneralFunction';
import { UploadType } from '../../../clientLibrary/Enums';
import { BulkAddModalProps } from '../../../clientLibrary/GeneralInterfaces';

const studentTablePropertyMap: Record<string, keyof BulkStudentData> = {
  "First Name": "firstName",
  "Last Name": "lastName",
  "Email": "email",
}

export const csvArrayToBulkStudentData = makeCsvArrayConverter<BulkStudentData>(studentTablePropertyMap);

const tableHead = Object.keys(studentTablePropertyMap);

export default function UploadStudentModal(props: BulkAddModalProps) {
  return (
    <UploadContainer
      id="upload-students"
      type={UploadType.Student}
      modalTitle="Upload Students"
      handleClose={props.handleClose}
      modalOpen={props.modalOpen}
      handleSubmit={props.handleSubmit}
      tableHead={tableHead}
      licenseUtilization={props.licenseUtilization}
      entityName={props.entityName}
    >
    </UploadContainer>
  );
}