import { arraysEqual, doesCurriculumTitleExist, doesSchoolExist, isDateFormatValid, isEmailValid } from "./GeneralFunction";
import { ParseResult } from "papaparse";
import { isEmailInDb, isUsernameInDb } from "../clientLibrary/account/account";
import { SchoolResponse } from "../clientLibrary/school/schoolInterfaces";
import { LicenseConsumerResponse } from "../clientLibrary/license/licenseInterfaces";
import { checkIfLoginExists } from "../clientLibrary/user/user";

export const isEmptyString = (value: string): boolean => {
  return value.trim() === ""
}

const doesCSVContainEmptyString = (array: ParseResult<string>[]): boolean => {
  if (array.length === 1) return true; //Header Row of CSV
  return array.some(row => row.data.some(isEmptyString));
}

export const isFileCsvOrExcel = (arr2: string[]): boolean => {
  const arr1 = [
    "text/csv",
    ".csv",
    "application/vnd.ms-excel"
  ];

  const res = arr1.filter(e => arr2.indexOf(e) !== -1);
  return res.length === arr2.length;
}

const validationMessages = {
  fileMissing: 'Missing file. Please upload a file and try again.',
  headersMismatch: 'Your headers don\'t match what is below. Be sure to download the template.',
  dataMissing: 'Missing data. Please check that no fields are empty and upload again.'
}

export const bulkDataValidation = (bulkData: ParseResult<string>[], tableHead: string[]): string => {
  let validationMessage = '';
  if (bulkData.length === 0) {
    validationMessage = validationMessages.fileMissing;
  }
  else if (bulkData[0] && !arraysEqual(bulkData[0].data, tableHead)) {
    validationMessage = validationMessages.headersMismatch;
  }
  else if (doesCSVContainEmptyString(bulkData)) {
    validationMessage = validationMessages.dataMissing;
  }
  return validationMessage;
}

interface BulkError {
  type: "emailFormat" | "emailAdded" | "usernameAdded" | "emailInDb" | "usernameInDb" | "dataFormatStart" | "dataFormatEnd" | "curriculumTitle" | "schoolInDB" | "loginInDb",
  value: string
}

const generateMessage = (initialMsg: string, array: string[]): string => {
  if (array.length > 0) {
    array.forEach((item, index) => {
      initialMsg += `${item}${index === array.length - 1 ? "." : ","} `
    })
    return initialMsg;
  }
  return "";
}

const generateErrorMessage = (errorsArr: BulkError[], initialErrMessageObj: Record<string, string>) => {
  let errorMessage = "";
  for (let property in initialErrMessageObj) {
    const initialMsg = initialErrMessageObj[property];
    const filteredErrors = errorsArr
      .filter(val => val.type === property)
      .map(val => val.value);
    errorMessage += generateMessage(initialMsg, filteredErrors);
  }
  return errorMessage;
}

interface EmailsAndUsernamesArrays {
  emails: string[],
  usernames: string[]
}

interface BaseUser {
  firstName: string,
  lastName: string,
  email: string,
  school: string,
  username: string
}
interface ClassData {
  teacherLogin: string,
  startDate: string,
  endDate: string,
  curriculumTitleId: string,
  school: string
}

export const validateBulkUsers = async (bulkData?: BaseUser[], schools?: SchoolResponse[]): Promise<string> => {
  const alreadyAddedFromCsv: EmailsAndUsernamesArrays = { emails: [], usernames: [] };
  const initialErrMessageObj: Record<string, string> = {
    "emailFormat": "Email(s) not in the correct format: ",
    "emailAdded": "\nEmail(s) showing up more than once in the CSV file: ",
    "usernameAdded": "\nUsername(s) showing up more than once in the CSV file: ",
    "emailInDb": "\nEmail(s) already in use: ",
    "usernameInDb": "\nUsername(s) already in use: ",
    "schoolInDB": "Please add the school(s) first or double check your spelling. School(s) not found in the system: "
  }
  const errorsArr: BulkError[] = [];

  if (bulkData) {
    for (let i = 0; i < bulkData.length; i++) {
      const email = bulkData[i].email;
      let username;
      if (bulkData[i].username) {
        username = bulkData[i].username
      }
      const schoolName = bulkData[i].school;

      if (alreadyAddedFromCsv.emails.includes(email)) {
        errorsArr.push({ type: 'emailAdded', value: email });
      } else {
        alreadyAddedFromCsv.emails.push(email);
        if (!isEmailValid) errorsArr.push({ type: 'emailFormat', value: email });
        else if (await isEmailInDb(email)) errorsArr.push({ type: 'emailInDb', value: email });
      }
      if (username) {
        if (alreadyAddedFromCsv.usernames.includes(username)) {
          errorsArr.push({ type: 'usernameAdded', value: username });
        } else {
          alreadyAddedFromCsv.usernames.push(username);
          if (await isUsernameInDb(username)) errorsArr.push({ type: 'usernameInDb', value: username });
        }
        alreadyAddedFromCsv.usernames.push(username);
      }
      if (!doesSchoolExist(schoolName, schools)) {
        errorsArr.push({ type: 'schoolInDB', value: schoolName})
      }
   
      alreadyAddedFromCsv.emails.push(email);
    }
  }

  if (errorsArr.length > 0) {
    const message = generateErrorMessage(errorsArr, initialErrMessageObj);
    return message;
  }

  return "";
}

export const validateBulkClasses = async (bulkData?: ClassData[], licenses?: LicenseConsumerResponse[], schools?: SchoolResponse[]): Promise<string> => {
  const initialErrMessageObj: Record<string, string> = {
    "loginInDb": "Please add the teacher(s) first or double check your spelling. Teacher login(s) not found in the system: ",
    "dataFormatStart": "Incorrect start date format(s): ",
    "dataFormatEnd": "Incorrect end date format(s): ",
    "curriculumTitle": "Please refer to the Curriculum Title Reference. Curriculum Title(s) not found in the system: ",
    "schoolInDB": "Please add the school(s) first or double check your spelling. School(s) not found in the system: "
  }
  const errorsArr: BulkError[] = [];

  if (bulkData) {
    for (let i = 0; i < bulkData.length; i++) {
      const login = bulkData[i].teacherLogin;
      const startDate = bulkData[i].startDate;
      const endDate = bulkData[i].endDate;
      const curriculumTitle = bulkData[i].curriculumTitleId
      const schoolName = bulkData[i].school
      if (await checkIfLoginExists(login) === false) {
        errorsArr.push({ type: 'loginInDb', value: login });
      }
      if (!isDateFormatValid(startDate)) {
        errorsArr.push({type: 'dataFormatStart', value: startDate})
      } 
      if (!isDateFormatValid(endDate)) {
        errorsArr.push({type: 'dataFormatEnd', value: endDate})
      } 
      if (!doesCurriculumTitleExist(curriculumTitle, licenses)) {
        errorsArr.push({ type: 'curriculumTitle', value: curriculumTitle})
      }
      if (!doesSchoolExist(schoolName, schools)) {
        errorsArr.push({ type: 'schoolInDB', value: schoolName})
      }
    }
  }
  if (errorsArr.length > 0) {
    const message = generateErrorMessage(errorsArr, initialErrMessageObj);
    return message;
  }
  return "";
}

export const usernameColumnExists = (bulkData: ParseResult<string>[] | undefined): boolean => {
  if (bulkData) {
    const columns = Object.values(bulkData[0].data);
    if (columns.includes("Username")) {
      return true
    }
  }
  return false;
}