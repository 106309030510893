import React, {useState} from "react";
import BaseModalContainer from "../shared/baseModalContainer";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ClassesResponse } from "../../../clientLibrary/class/classInterfaces";
import { UserResponse } from "../../../clientLibrary/user/userInterfaces";
import { getEnrolledStudentsByClassId, transferStudent } from "../../../reduxModule/slices/userSlice";
import { checkForEnoughLicensesByLicenseNames, generateNotEnoughLicensesHrefObj } from "../../../common/LicenseValidation";
import { MailtoLink, MailtoLinkTypes } from "../../helperComponents/mailtoLink";

interface TransferStudentModalProps {
    modalOpen: boolean,
    handleClose: () => void,
    studentData: UserResponse | null,
    classId: number,
}

export default function TransferStudentModal (props: TransferStudentModalProps) { 
    const dispatch = useAppDispatch();
    const teacherClasses = useAppSelector(state => state.class.searchedClasses);
    const [selectedClassId, setSelectedClassId] = useState(0);
    const [selectedClassHasEnoughLicenses, setSelectedClassHasEnoughLicenses] = useState(true);
    const availableLicenses = useAppSelector(state => state.license.licenseConsumers);
    const [licenseLink, setLicenseLink] = useState<JSX.Element | null>();
    const [licenseAlertMessage, setLicenseAlertMessage] = useState<JSX.Element | null>();
    const primaryContact = useAppSelector(state => state.account.customer?.primaryContact);

    const filterOutCurrentClass = (classData: ClassesResponse) => {
       return  classData.id !== props.classId;
    }

    const licenseAvailabilityCheck = async (teacherClass: ClassesResponse) => {
            const licenseObj = await checkForEnoughLicensesByLicenseNames(availableLicenses, teacherClass.licenseUtilization);
            return licenseObj;
    }

    const handleClassSelect = async ( classId: number) => {
        setSelectedClassId(classId);
        const selectedClass = teacherClasses.filter(classObj => {
            return classId === classObj.id
        })[0];
        let checkedLicenseObj = await licenseAvailabilityCheck(selectedClass);
        if (checkedLicenseObj.okToEnroll) {
            setSelectedClassHasEnoughLicenses(true);
            setLicenseAlertMessage(null);
            setLicenseLink(null);
        } else {
            setSelectedClassHasEnoughLicenses(false);
            if (checkedLicenseObj.bookList && primaryContact) {
                let mailtoData = generateNotEnoughLicensesHrefObj(primaryContact, selectedClass.name, checkedLicenseObj.bookList)
                setLicenseAlertMessage(
                    <Grid item style={{color: "red"}}>
                        <p>{checkedLicenseObj.message}</p>
                    </Grid>
                )
                setLicenseLink(
                    <Grid item style={{paddingTop: "16px"}}>
                        <MailtoLink label={"Request More"} type={MailtoLinkTypes.Text} href={mailtoData} />
                    </Grid>
                )
            }
        }
    }

    const handleTransferStudent = async (oldClassId: number, newClassId:number) => {
        props.handleClose();
        if (props.studentData) {
            await dispatch(transferStudent({
                oldClassId,
                newClassId,
                login: props.studentData.login
            }));
        }
        dispatch(getEnrolledStudentsByClassId(newClassId));
        dispatch(getEnrolledStudentsByClassId(oldClassId));
    }

    let body;
    if (props.studentData) {
        body = (
            <>
            <Grid container justify="center" spacing={3}>
                <Grid item container xs={12} justify="center">
                    <InputLabel id="teacher-class-select-label">{`Select New Class for ${props.studentData.firstName}`}</InputLabel>
                </Grid>
                <Grid item xs={12}>
                {teacherClasses?.length > 0 &&
                <FormControl fullWidth>
                    <Select
                        variant="outlined"
                        labelId="teacher-class-select-label"
                        id="teacher-class-select"
                        value={selectedClassId}
                        onChange={(e) => handleClassSelect(e.target.value as number)}
                        fullWidth
                    >
                        <MenuItem disabled value={0}>Select a new class</MenuItem>
                        {teacherClasses.filter(classObj => filterOutCurrentClass(classObj)).map((classObj: ClassesResponse) => {
                            return (                       
                                <MenuItem key={classObj.id} value={classObj.id}>{classObj.name}</MenuItem>
                            )
                        }
                        )}
                    </Select>
                    <Grid item container xs={12} justify="space-around" alignContent="center">
                        {licenseAlertMessage}
                        {licenseLink}
                    </Grid>
                </FormControl>
                } 
                </Grid>
            </Grid>
            </>
        )
    } else {
        body = (
            <>
            </>
        )
    }

    return (
        <BaseModalContainer
            id="transfer-student"
            buttonName="Transfer Student"
            buttonDisabled={selectedClassId === 0 || !selectedClassHasEnoughLicenses}
            modalTitle="Transfer Student"
            handleClose={props.handleClose}
            modalOpen={props.modalOpen}
            handleAction={() => handleTransferStudent(props.classId, selectedClassId)}
        >
            {body}
        </BaseModalContainer>
    )
}