import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { DistrictSerializedLicenses } from "../../../clientLibrary/license/licenseInterfaces";
import { NAVY_TEXT_COLOR } from "./licenseUtilizationRow";
import LicenseUtilizationColumn from "./licenseUtilizationColumn";

export interface UtilizationReportProps {
    activeTab: string;
    licenses: DistrictSerializedLicenses[];
}

export const GRAY_BACKGROUND_COLOR = '#eae8e8';
export const ELS_PRIMARY_BLUE_COLOR = '#1884df';
export const INACTIVE_GRAY_COLOR = '#797979';

const useStyles = makeStyles({
    link: {
        color: ELS_PRIMARY_BLUE_COLOR,
        borderBottom: `1px solid ${ELS_PRIMARY_BLUE_COLOR}`,
        '&:hover': {
            color: ELS_PRIMARY_BLUE_COLOR
        }
    }
})

export default function UtilizationReport(props: UtilizationReportProps) {
    const classes = useStyles();
    const teacherLicenses = props.licenses.filter(license => license.edition.includes('teacher'));
    const studentLicenses = props.licenses.filter(license => license.edition.includes('student'));

    return (
        props.licenses.length === 0 ?
            <Grid container direction='column' alignItems="center" spacing={1} style={{padding: '100px 0px'}}>
                <Grid item>
                    <Typography style={{color: ELS_PRIMARY_BLUE_COLOR, fontWeight: 'bold', textAlign: 'center'}} variant="h5" >You currently don't have any {props.activeTab} licenses.</Typography>
                </Grid>
                <Grid item>
                    <Typography style={{color: NAVY_TEXT_COLOR, textAlign: 'center'}} variant="subtitle1">Visit our <a className={classes.link} href='https://shop.cpm.org/' target='_blank' rel="noopener noreferrer">store</a> to purchase licenses.</Typography>
                </Grid>
            </Grid>
        :
        props.activeTab === "student" ? 
            <LicenseUtilizationColumn licenses={studentLicenses}/>
        :
            <LicenseUtilizationColumn licenses={teacherLicenses}/>
    )
}