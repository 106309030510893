import React, { useState, useEffect, useMemo } from "react";
import '../teacher.scss'
import {
  Grid,
  Button,
  ButtonGroup,
  Typography,
  CircularProgress,
  makeStyles,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import CustomizedSelects from "../../helperComponents/customizedSelects";
import CreateClassModal from "../../modals/shared/createClassModal";
import UpdateClassModal from "../../modals/shared/updateClassInfoModal";
import "../../teacher/teacher.scss";
import {
  createNewClass,
  getAllClasses,
  searchClasses,
  updateClassTeacher
} from "../../../reduxModule/slices/classSlice";
import { ClassesResponse, CreateClassPayload, SearchClassesParams } from "../../../clientLibrary/class/classInterfaces";
import { UserType } from "../../../clientLibrary/Enums";
import { ContentValue } from "../../../clientLibrary/GeneralInterfaces";
import ClassCard from "./classCard";
import { TeacherInfo } from "../home/teacherInfo";
import {  sub } from "date-fns";

export type CurrentPast = "current" | "past";

export const filterValues: ContentValue<CurrentPast>[] = [
  { name: "Current Classes", value: "current" },
  { name: "Past Classes", value: "past" },
];

export enum AllValuesText {
  Classes = "All Classes",
}

export type SelectedClass = ClassesResponse | { id: AllValuesText.Classes }

export interface ClassInfo {
  id: number,
  index: number,
  isClassExpired?: boolean,
}

export interface UpdateClassInfoForComponent {
  id: number,
  name: string,
  startDate: string,
  endDate: string,
  teacher: {
    login: string,
  },
  schoolID: number,
  pin: string
}

export const updateClassInfoObj: UpdateClassInfoForComponent = {
  id: 0,
  name: "",
  startDate: "",
  endDate: "",
  teacher: {
    login: "",
  },
  schoolID: 0,
  pin: ""
}

export interface ModalOpen {
  enrollStudent: boolean,
  manageStudents: boolean,
  createClass: boolean,
  updateClass: boolean,
  upload: boolean,
}

const modalOpenObj: ModalOpen = {
  enrollStudent: false,
  manageStudents: false,
  createClass: false,
  updateClass: false,
  upload: false,
}

const useStyles = makeStyles(theme => ({
  showPhoneOnly: {
    [theme.breakpoints.up("xs")]: {
      display: "none"
    },
    [theme.breakpoints.down("xs")]: {
      display: "block"
    },
  },
  hidePhoneOnly: {
    paddingLeft: 0,
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  mobileMarginRight: {
    [theme.breakpoints.down("md")]: {
      marginRight: 8
    }
  },
  mobileMarginLeft: {
    [theme.breakpoints.down("md")]: {
      marginLeft: 8
    }
  }
}));

function TeacherClasses() {
  // Component initial state
  const [filterVal, setFilterVal] = useState<ContentValue<CurrentPast>>(filterValues[0]);
  const [modalOpen, setModalOpen] = useState(modalOpenObj);
  const [dataProgress, setDataProgress] = useState(false);

  const [updateClassInfo, setUpdateClassInfo] = useState(updateClassInfoObj);

  // Getting data from store
  const teacherClasses = useAppSelector(state => state.class.searchedClasses);
  const login = useAppSelector(state => state.auth.userRole.login);

  const dispatch = useAppDispatch();
  const classes = useStyles();

  const classPagination = useMemo(() => {
    const pagination: SearchClassesParams = {
        expired: filterVal.value === "past",
        per_page: 1000,
    }
    if (filterVal.value === "past") {
        pagination.after = sub(new Date(), { years:  1 }).toISOString().substring(0, 10)
    }
    return pagination
}, [filterVal.value]);

  useEffect(() => {
    const fetchData = (async () => {
      setDataProgress(true);
      await dispatch(searchClasses(classPagination));
      setDataProgress(false);
    });
    fetchData();
  }, [dispatch, classPagination]);

  // Create class action
  const handleCreateClass = async (postData: CreateClassPayload) => {
    const payload: CreateClassPayload = {
      ...postData,
      teacher: { login: login },
    };
    setModalOpen({ ...modalOpen, createClass: false });
    await dispatch(createNewClass(payload));
    await dispatch(getAllClasses());
    dispatch(searchClasses(classPagination));
  };

  //Update Class action
  const handleUpdateClass = async (item: UpdateClassInfoForComponent) => {
    const payload = {
      ...item,
      teacher: { ...item.teacher, login: login, },
    };
    setModalOpen({ ...modalOpen, updateClass: false });
    await dispatch(updateClassTeacher(payload as ClassesResponse));
    dispatch(searchClasses(classPagination));
  };

  const handleUpdateClassClick = (obj: ClassesResponse) => {
    setUpdateClassInfo(obj);
    setModalOpen({ ...modalOpen, updateClass: true });
  };

  const classCards =
    teacherClasses?.map((classData: ClassesResponse) => {
      return (
        <ClassCard
          classData={classData}
          filterValue={filterVal.value}
          handler={handleUpdateClassClick}
          key={classData.pin} />
      )
    })

  const newClassCards =
    teacherClasses?.length > 0 ? (
      classCards
    ) : (
      <section>
        <Typography variant="overline" display="block" gutterBottom>
          -Data is not available-
        </Typography>
      </section>
    )

  // Button group for filter in Class
  const buttonFilter = (
    <ButtonGroup aria-label="outlined primary button group">
      {filterValues.map((item) => (
        <Button
          key={item.value}
          size="large"
          className={filterVal.value === item.value ? "greenBg" : "whiteBg"}
          onClick={() => setFilterVal(item)}
        >
          {item.name}
        </Button>
      ))}
    </ButtonGroup>
  );

  const handleCreateClose = () => {
    setModalOpen({ ...modalOpen, createClass: false });
    setUpdateClassInfo(updateClassInfoObj);
  }

  return (
    <>
      <Grid container item lg={8} md={12} sm={12} xs={12}>
        <Grid item style={{ width: "100%" }}>
          <TeacherInfo />
        </Grid>
        <Grid item container spacing={2} alignItems="center" justify="space-between" style={{ marginBottom: 15 }}>
          <Grid item>
            <div
              className={`${classes.showPhoneOnly} tabs tabs-section-dropdown`}
            >
              <CustomizedSelects
                filterVal={filterVal.value}
                filterValues={filterValues}
                handleFilterSelect={(item) => setFilterVal(item)}
              />
            </div>
            <div className={`${classes.mobileMarginLeft} ${classes.hidePhoneOnly} tabs tabs-section-mobile`}>
              {buttonFilter}
            </div>
          </Grid>
          <Grid item className={classes.mobileMarginRight}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpen({ ...modalOpen, createClass: true })}
              startIcon={<AddIcon />}
            >
              <Typography >
                Create Class
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={10}
          justify="center"
        >
          {
            dataProgress ? (
              <Grid item>
                <CircularProgress />
              </Grid>
            ) :
              newClassCards
          }
        </Grid>
        {/* table section End */}
        <CreateClassModal
          type={UserType.Teacher}
          modalOpen={modalOpen.createClass}
          handleClose={() => handleCreateClose()}
          handleSubmit={(data) => handleCreateClass(data)}
        />
        <UpdateClassModal
          type={UserType.Teacher}
          modalOpen={modalOpen.updateClass}
          handleClose={() => setModalOpen({ ...modalOpen, updateClass: false })}
          handleSubmit={(data: UpdateClassInfoForComponent) => handleUpdateClass(data)}
          classInfo={updateClassInfo}
          setClassInfo={setUpdateClassInfo}
          filterValue={filterVal.value}
        />
      </Grid>
    </>
  );
}

export default TeacherClasses;