import React, { useState, useEffect } from "react";
import '../parent.scss';
import { Grid, useMediaQuery } from "@material-ui/core";
import LicenseCardMobile from "./licenseCardMobile";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { getSerializedLicenses, getAccountContext, getStudents, getAssignedLicenses } from "../../../reduxModule/slices/parentSlice";
import LicenseCard from "./licenseCard";
import ParentInfo from "../home/parentInfo";

function ParentLicenses() {
  const [activeCard, setActiveCard] = useState<number | null>(null);
  const [refreshData, setRefreshData] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const licenses = useAppSelector(state => state.parent.licenses);

  const mobile = useMediaQuery('(max-width: 600px)');

  useEffect(() => {
    if (refreshData) {
      dispatch(getSerializedLicenses());
      dispatch(getAccountContext());
      dispatch(getStudents());
      dispatch(getAssignedLicenses());
      setRefreshData(false);
    }
  }, [dispatch, refreshData]);

  const changeActiveCard = (id: number | null) => {
    if (id) {
      setActiveCard(id);
    } else {
      setActiveCard(null);
    }
  }

  const licenseCards = licenses.map((license, index) => {
    return (
      <LicenseCard
        bookEdition={license.edition}
        cardTitle={license.licenseName}
        cardExpiration={license.expiration}
        cardImage={license.bookImageURL}
        studentFirstName={license.studentFirstName}
        studentLastName={license.studentLastName}
        studentEmail={license.studentEmail}
        licenseId={license.licenseId}
        licenseTypeId={license.licenseTypeId}
        licenseProviderId={license.licenseProviderId}
        key={index + 1}
        id={index + 1}
        activeCard={activeCard}
        changeActiveCard={changeActiveCard}
        setRefreshData={setRefreshData}
      />
    );
  });


  const licenseCardsMobile = licenses.map((license, index) => {
    return (
      <LicenseCardMobile
        bookEdition={license.edition}
        cardTitle={license.licenseName}
        cardExpiration={license.expiration}
        cardImage={license.bookImageURL}
        studentFirstName={license.studentFirstName}
        studentLastName={license.studentLastName}
        studentEmail={license.studentEmail}
        licenseId={license.licenseId}
        licenseTypeId={license.licenseTypeId}
        licenseProviderId={license.licenseProviderId}
        key={index + 1}
        id={index + 1}
        activeCard={activeCard}
        changeActiveCard={changeActiveCard}
        setRefreshData={setRefreshData}
      />
    );
  });

  return (
    <>
      <Grid container spacing={1} justify="space-evenly" style={{ paddingBottom: '40px' }}>
        <ParentInfo />
        {mobile ? licenseCardsMobile : licenseCards}
      </Grid>
    </>
  );
}

export default ParentLicenses;
