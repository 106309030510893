import {
    Button,
    Grid,
    makeStyles,
    Typography
} from "@material-ui/core";
import React, { useState } from "react";
import { useAppSelector } from "../../../hooks";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import { MailtoData, MailtoLink, MailtoLinkTypes } from "../../helperComponents/mailtoLink";
import TutorialVideoModal from "../../modals/teacher/tutorialVideoModal";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

export const useTeacherInfoBarStyles = makeStyles(theme => ({
    teacherInfoContainer: {
        padding: "10px 0px 20px 0px",
        justifyContent: "space-between",
        [theme.breakpoints.down(1280)]: {
            padding: "10px 20px 20px 20px"
        },
        [theme.breakpoints.down('xs')]: {
            padding: "10px 0px 30px 0px",
            justifyContent: "space-around",
            height: "185px"
        }
    },
    teacherInfo: {
        width: "65%",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            justifyContent: "center"
        }
    },
    eWorkSpace: {
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            justifyContent: "center"
        }
    },
    teacherInfoActions: {
        alignContent: "flex-start",
        justifyContent: "flex-end",
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            marginTop: 5,
            width: "100%",
            alignContent: "center",
            justifyContent: "center",
        }
    },
    teacherInfoTutorialActions: {
        alignContent: "flex-start",
        justifyContent: "space-between",
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            alignContent: "center",
            justifyContent: "space-around",
        }
    },
    actionBtn: {
        textTransform: "capitalize",
        color: "black",
        padding: "0px",
        '&:hover': {
            color: "#095da3",
            backgroundColor: "#eae8e8"
        }
    },
}))

export const TeacherInfo = () => {
    const classes = useTeacherInfoBarStyles();
    const firstName = useAppSelector(state => state.auth.userRole.firstName);
    const lastName = useAppSelector(state => state.auth.userRole.lastName);
    const login = useAppSelector(state => state.auth.userRole.login);
    const isEworkspaceEnabled = useAppSelector(state => state.auth.userRole.eWorkspaceEnabled);
    const [tutorialModalOpen, setTutorialModalOpen] = useState(false);

    const supportEmail = "support@cpm.org";
    const greeting = `Greetings! My name is ${firstName} ${lastName} (login: ${login}) and I would like to`

    const feedbackMailtoObj: MailtoData = {
        mailTo: supportEmail,
        subject: "ELS Feedback",
        body: `${greeting} provide some feedback for the ELS teacher interface below:`
    }

    const reportIssueMailtoObj: MailtoData = {
        mailTo: supportEmail,
        subject: "ELS Issue",
        body: `${greeting} report an issue below with: what I was trying to do, what I expected to happen, and what actually happened.`
    }

    const handleTutorialClose = () => {
        setTutorialModalOpen(false);
    }

    return (
        <>
            <Grid container className={classes.teacherInfoContainer}>
                <Grid item sm={5} container className={classes.teacherInfo}>
                    <Grid item>
                        <Typography align="left" variant="h5" style={{ fontWeight: 500 }}>{firstName} {lastName}</Typography>
                    </Grid>
                    <Grid item container className={classes.eWorkSpace}>
                        <Grid item>
                            <Typography variant="h6" style={{ color: "grey" }}>
                                eWorkspace: { isEworkspaceEnabled ? "Enabled" : "Disabled" }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={7} md={4} lg={5} container className={classes.teacherInfoActions}>
                    <Grid item container className={classes.teacherInfoTutorialActions}>
                        <Grid item>
                            <Button  
                                variant="text" 
                                color="inherit" 
                                className={classes.actionBtn} 
                                startIcon={<OndemandVideoIcon />} 
                                onClick={() => setTutorialModalOpen(true)}
                            >
                                <Typography variant="h6">Tutorial</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <MailtoLink
                                type={MailtoLinkTypes.Button}
                                label={"Feedback"}
                                href={feedbackMailtoObj}
                                startIcon={<MailOutlineIcon/>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.teacherInfoTutorialActions}>
                        <Grid item>
                            <Button  
                                variant="text" 
                                color="inherit" 
                                className={classes.actionBtn} 
                                startIcon={<DescriptionOutlinedIcon />} 
                                target="_blank"
                                href="https://teacherhelp.cpm.org/a/1560694-teacher-role-interface-after-5-27-2022-create-manage-student-classes"
                            >
                                <Typography variant="h6">FAQ</Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <MailtoLink
                                type={MailtoLinkTypes.Button}
                                label={"Report Issue"}
                                href={reportIssueMailtoObj}
                                startIcon={<ReportProblemOutlinedIcon/>}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <TutorialVideoModal 
                modalOpen={tutorialModalOpen}
                handleClose={handleTutorialClose}
            />
        </>
    )
}