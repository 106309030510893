import React from "react";
import { useAppSelector } from "../../../hooks";
import {
        Grid,
        Button,
        makeStyles,
        useMediaQuery,
        Typography,
} from "@material-ui/core";
import { MailOutline, ReportProblemOutlined, OpenInNew, DescriptionOutlined, OndemandVideo } from "@material-ui/icons";
import { MailtoData, MailtoLink, MailtoLinkTypes } from "../../helperComponents/mailtoLink";

const useStyles = makeStyles(() => ({
    storeLink: {
        height: '100%',
        fontSize: '16px',
        '&:hover': {
            color: 'black'
        }
    },
    actionBtn: {
        textTransform: "capitalize",
        minWidth: '0',
        color: "black",
        padding: "2px",
        textAlign: 'left',
        '&:hover': {
            color: "#095da3",
            backgroundColor: "#eae8e8"
        }
    },
}));

const ParentInfo = () => {
    const classes = useStyles();
    const firstName = useAppSelector(state => state.auth.userRole.firstName);
    const lastName = useAppSelector(state => state.auth.userRole.lastName);
    const login = useAppSelector(state => state.auth.userRole.login);
    const mobile = useMediaQuery('(max-width: 600px)');


    const supportEmail = "support@cpm.org";
    const greeting = `Greetings! My name is ${firstName} ${lastName} (login: ${login}) and I would like to`

    const feedbackMailtoObj: MailtoData = {
        mailTo: supportEmail,
        subject: "ELS Parent Feedback",
        body: `${greeting} provide some feedback for the ELS parent interface below:`
    }

    const reportIssueMailtoObj: MailtoData = {
        mailTo: supportEmail,
        subject: "ELS Parent Issue",
        body: `${greeting} report an issue below with: what I was trying to do, what I expected to happen, and what actually happened.`
    }

    return (
        <Grid
            item
            wrap={mobile ? 'wrap' : 'nowrap'}
            container
            justify="space-between"
            style={{ width: '100%', padding: '0px 20px' }}
        >
            <Grid
                item
                container
                direction='column'
                alignContent={mobile ? 'center' : 'flex-start'}
                alignItems='flex-start'
                sm={6}
                xs={12}
                style={{order: mobile ? 1 : -1}}
            >
                <Button
                    endIcon={<OpenInNew/>}
                    className={classes.storeLink}
                    href="https://shop.cpm.org/content/parent-landing.asp"
                    target='_blank'
                >
                    <Typography>CPM Curriculum Store</Typography>
                </Button>
            </Grid>
            <Grid
                item
                container
                direction='column'
                alignContent={mobile ? 'flex-start' : 'flex-end'}
                alignItems='flex-start'
                sm={3}
                xs={6}
            >
                <Button  
                    variant="text" 
                    color="inherit"
                    size="small"
                    className={classes.actionBtn}
                    startIcon={<DescriptionOutlined />} 
                    target="_blank"
                    href="https://studenthelp.cpm.org/a/1633036-parent-managing-your-ebook-license"
                >
                    <Typography>FAQ</Typography>
                </Button>
                <Button  
                    variant="text" 
                    color="inherit"
                    size="small"
                    className={classes.actionBtn}
                    startIcon={<OndemandVideo />} 
                    target="_blank"
                    href="https://www.youtube.com/watch?v=4B8guEkxR_Y"
                >
                    <Typography>Tutorial</Typography>
                </Button>
            </Grid>
            <Grid
                item
                container
                direction='column'
                alignContent='flex-end'
                alignItems='flex-end'
                sm={3}
                xs={6}
            >
                <MailtoLink
                    type={MailtoLinkTypes.Button}
                    label={"Feedback"}
                    href={feedbackMailtoObj}
                    startIcon={<MailOutline/>}
                    typographyVariant="inherit"
                    style={{fontSize: '16px'}}
                />
                <MailtoLink
                    type={MailtoLinkTypes.Button}
                    label={"Report Issue"}
                    href={reportIssueMailtoObj}
                    startIcon={<ReportProblemOutlined/>}
                    typographyVariant="inherit"
                    style={{fontSize: '16px'}}
                />
            </Grid>
        </Grid>
    )
}

export default ParentInfo;