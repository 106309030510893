export const isDistrictAdmin = (authRoles: string[]): boolean => {
    if (authRoles.includes('CUSTOMER_ADMINISTRATOR')) {
        return true
    }
    return false;
}

export const isRegionalCoordinator = (authRoles: string[]): boolean => {
    if (authRoles.includes('REGIONAL_COORDINATOR') || authRoles.includes("CPM_REGIONAL_COORDINATOR")) {
        return true;
    }
    return false;
}

export const isTeacherLeader = (authRoles: string[]): boolean => {
    if (authRoles.includes('CPM_TEACHER_LEADER')) {
        return true;
    }
    return false;
}

export const isAccountant = (authRoles: string[]): boolean => {
    if (authRoles.includes('CPM_ACCOUNTANT')) {
        return true;
    }
    return false;
}

export const isEmployee = (authRoles: string[]): boolean => {
    if (authRoles.includes('CPM_EMPLOYEE')) {
        return true;
    }
    return false;
}

export const isTeacher = (authRoles: string[]): boolean => {
    if (authRoles.includes('FACULTY')
        && !isDistrictAdmin(authRoles)
        && !authRoles.includes('SCHOOL_ADMIN')
        && !authRoles.includes('FACULTY_ADMIN')
        && !authRoles.includes('FACULTY_ADMINSTRATOR')
        ) {
        return true;
    }
    return false;
}

export const isParent = (authRoles: string[]): boolean => {
    if (authRoles.includes('PERSONAL')
        && !authRoles.includes('STUDENT')
        ) {
        return true;
    }
    return false;
}

export const isCPMAdmin = (authRoles: string[]): boolean => authRoles.includes('CPM_ADMIN');
