import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
interface SearchBoxProps {
  handleSearch(item: string): void,
  placeHolder: string,
  searchVal: string
}

const useStyles = makeStyles({
  searchButton: {
    '&.Mui-disabled': {
      backgroundColor: '#bcbcbc !important',
      color: 'white !important'
    }
  }
})

export default function SearchBox(props: SearchBoxProps) {
  const classes = useStyles();
  const [searchStr, setSearchStr] = useState(props.searchVal || "");

  const doSearch = (str = searchStr) => {
    props.handleSearch(str);
  };

  const callSearch = (value: string) => {
    setSearchStr(value);
    if (value === "") {
      doSearch("");
    }
  }

  const handleKeyDown = (e: {key: string}, str = searchStr) => {
    if (e.key === "Enter" && str.length > 0) {
      props.handleSearch(str)
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xl={10} lg={10} md={10} sm={8} xs={9}>
        <Paper className="searchbox">
          <InputBase
            className="searchinput"
            placeholder={props.placeHolder}
            value={searchStr}
            onChange={(e) => callSearch(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        </Paper>
      </Grid>
      <Grid item xl={2} lg={2} md={2} sm={4} xs={3}>
        <Button
          disabled={searchStr === ""}
          className={classes.searchButton}
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => doSearch()}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
}
