import React, { useMemo } from 'react';
import UploadContainer from '../../helperComponents/uploadContainer';
import { Button, Typography } from '@material-ui/core';
import { getLicenseNameOnlyFromLicenseTypeDescription, getUniqueLicensesFromLicenseConsumerData, makeCsvArrayConverter } from '../../../common/GeneralFunction';
import { CSVDownloader } from 'react-papaparse';
import { useAppSelector } from '../../../hooks';
import { LicenseConsumerResponse } from '../../../clientLibrary/license/licenseInterfaces';
import { BulkAddModalProps } from '../../../clientLibrary/GeneralInterfaces';
import { BulkClassData } from '../../../reduxModule/slices/classSlice';
import { UploadType } from '../../../clientLibrary/Enums';

interface ClassTableData {
  school: string,
  class: string,
  teacherLogin: string,
  curriculumTitleId: number,
  startDate: string,
  endDate: string,
}

const classTablePropertyMap: Record<string, keyof ClassTableData> = {
  "School": "school",
  "Class": "class",
  "Teacher Login": "teacherLogin",
  "Curriculum Title": "curriculumTitleId",
  "Start Date": "startDate",
  "End Date": "endDate",
}

export const csvArrayToBulkClassData = makeCsvArrayConverter<BulkClassData>(classTablePropertyMap);

const tableHead = Object.keys(classTablePropertyMap);

export default function BulkAddClassModal(props: BulkAddModalProps) {
  const schools = useAppSelector(state => state.school.schools);
  const districtStudentLicenseConsumers = useAppSelector(state => state.license.licenseConsumers.filter(license => {
    return license.associatedGroupAllowance.licenseType.name.toLowerCase().includes('student');
  }));

  const generateEbookReferenceFile = (studentLicenseConsumers: LicenseConsumerResponse[]) => {
    studentLicenseConsumers = getUniqueLicensesFromLicenseConsumerData(studentLicenseConsumers);
    const data = studentLicenseConsumers.map(item => {
      return {
          '': '',
          BOOK: getLicenseNameOnlyFromLicenseTypeDescription(item.associatedGroupAllowance.licenseType.description),
      }
    });
    return data;
  }

  const buttonReferenceGroup = useMemo(() => {
    const eBookReferenceArray = generateEbookReferenceFile(districtStudentLicenseConsumers);
    return (
      <CSVDownloader
        data={eBookReferenceArray}
        filename='Curriculum Title Reference'
        >
        <Button variant='contained'
          size="medium"
          color="primary"
          style={{marginTop: '10px'}}
        >
          Curriculum Title Reference
        </Button>
      </CSVDownloader>
    )
  }, [districtStudentLicenseConsumers]);

  const uploadNote = (
    <Typography variant="caption">
      See list of available curriculum title IDs by clicking "Curriculum Title Reference".
      <br />
      Date must be in the format MM-DD-YYYY or YYYY-MM-DD.
      <br />
      Schools and Teachers should already be added to the system.
    </Typography>
  );

  return (
    <UploadContainer
      id="upload-classes"
      modalTitle="Upload Classes"
      buttonReferenceGroup={buttonReferenceGroup}
      uploadNote={uploadNote}
      handleClose={props.handleClose}
      modalOpen={props.modalOpen}
      handleSubmit={props.handleSubmit}
      tableHead={tableHead}
      type={UploadType.Class}
      schools={schools}
    >
    </UploadContainer>
  );
}

